import { useState, useMemo, useCallback } from "react";
import ProjectItem from "./ProjectItem";
import Pagination from "./Pagination";
import ActionButtons from "./ActionButtons";
import PreviewModal from "./PreviewModal";
import theme from "../../theme"; // Import theme

export default function LibraryContainer({
  heading,
  array,
  countPerRow,
  itemsPerPage,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [data, setData] = useState(array);
  const [previewItem, setPreviewItem] = useState(null);

  const totalPages = useMemo(
    () => Math.ceil(data.length / itemsPerPage),
    [data.length, itemsPerPage]
  );
  const currentItems = useMemo(
    () =>
      data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage),
    [data, currentPage, itemsPerPage]
  );

  const handlePageChange = useCallback(
    (newPage) => {
      if (newPage >= 1 && newPage <= totalPages) {
        setCurrentPage(newPage);
      }
    },
    [totalPages]
  );

  const handleSelect = useCallback(
    (item) => {
      if (selectedItems.includes(item)) {
        setSelectedItems(selectedItems.filter((i) => i !== item));
      } else {
        setSelectedItems([...selectedItems, item]);
      }
    },
    [selectedItems]
  );

  const handleDeleteSelectedItems = useCallback(() => {
    const remainingItems = data.filter((item) => !selectedItems.includes(item));
    setData(remainingItems);
    setSelectedItems([]);
    console.log("Deleted Items:", selectedItems);
  }, [data, selectedItems]);

  const handleMoveSelectedItems = useCallback(() => {
    if (selectedItems.length > 0) {
      console.log("Moved Items:", selectedItems);
      setSelectedItems([]);
    }
  }, [selectedItems]);

  const handlePreview = useCallback((item) => {
    setPreviewItem(item);
  }, []);

  return (
    <section
      className="p-6 rounded-md mt-8"
      style={{
        backgroundColor: theme.colors.cardBackground,
        color: theme.colors.textPrimary,
      }}
    >
      <h3
        className="text-xl font-semibold mb-4"
        style={{ color: theme.colors.textPrimary }}
      >
        {heading}
      </h3>
      <div className={`grid grid-cols-${countPerRow} gap-4`}>
        {currentItems.map((project) => (
          <ProjectItem
            key={project}
            project={project}
            isSelected={selectedItems.includes(project)}
            onSelect={() => handleSelect(project)}
            onPreview={() => handlePreview(project)}
          />
        ))}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      <ActionButtons
        selectedItems={selectedItems}
        onDelete={handleDeleteSelectedItems}
        onMove={handleMoveSelectedItems}
      />
      {previewItem && (
        <PreviewModal item={previewItem} onClose={() => setPreviewItem(null)} />
      )}
    </section>
  );
}
