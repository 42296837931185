import React, { useEffect, useState } from "react";
import JsxParser from "react-jsx-parser";

const DynamicJSXRenderer = ({ jsx, textFields, mediaFiles }) => {
  textFields.forEach((text, i) => {
    if (i === 0) jsx = jsx.replace(`Company Name`, text); // Assign the result back to jsx
    if (i === 1) jsx = jsx.replace(`Product Name`, text); // Assign the result back to jsx
    if (i === 2) jsx = jsx.replace(`Product Details`, text); // Assign the result back to jsx
    if (i === 3) jsx = jsx.replace(`Website`, text); // Assign the result back to jsx
  });
  mediaFiles.forEach((file, i) => {
    jsx = jsx.replace(`image${i + 1}`, URL.createObjectURL(file));
  });

  return (
    <div className="scaled object-center">
      <JsxParser jsx={jsx} />
    </div>
  );
};

export default DynamicJSXRenderer;
