import React, { useState } from "react";
import theme from "../../theme"; // Import theme

export default function Subscription({
  subscriptionPlan,
  setSubscriptionPlan,
}) {
  const [billingInfo, setBillingInfo] = useState({
    cardName: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });

  const handleBillingChange = (e) => {
    const { name, value } = e.target;
    setBillingInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const upgradePlans = ["Basic", "Standard", "Pro", "Enterprise"];

  const updateSubscription = () => {
    console.log("Billing Info:", billingInfo);
    console.log("Selected Plan:", subscriptionPlan);
    alert(`Subscription updated to ${subscriptionPlan}`);
  };

  return (
    <div className="rounded-lg shadow-lg flex space-x-6">
      {/* Current Plan Section */}
      <div
        className="w-1/3 p-6 rounded-md flex flex-col justify-center items-center"
        style={{
          backgroundColor: theme.colors.background,
          color: theme.colors.textPrimary,
        }}
      >
        <h2 className="text-2xl mb-4">Current Plan</h2>
        <div
          className="p-4 rounded text-black mb-4"
          style={{
            backgroundColor: theme.colors.secondary,
            color: theme.colors.textPrimary,
          }}
        >
          {subscriptionPlan}
        </div>
        <h2 className="text-2xl mb-4">Updated Plan</h2>
        <div
          className="p-4 rounded text-black"
          style={{
            backgroundColor: theme.colors.secondary,
            color: theme.colors.textPrimary,
          }}
        >
          {subscriptionPlan}
        </div>
      </div>

      {/* Billing Information Section */}
      <div
        className="flex-1 p-6 rounded-md"
        style={{
          backgroundColor: theme.colors.background,
          color: theme.colors.textPrimary,
        }}
      >
        <h2 className="text-2xl font-bold mb-6">Billing Information</h2>

        <div className="grid grid-cols-2 gap-4 mb-6">
          <div>
            <label className="block text-sm mb-2">Name on Card</label>
            <input
              type="text"
              name="cardName"
              value={billingInfo.cardName}
              onChange={handleBillingChange}
              className="w-full p-2 rounded"
              style={{
                backgroundColor: theme.colors.textPrimary,
                color: theme.colors.textSecondary,
              }}
              placeholder="Placeholder"
            />
          </div>
          <div>
            <label className="block text-sm mb-2">Card Number</label>
            <input
              type="text"
              name="cardNumber"
              value={billingInfo.cardNumber}
              onChange={handleBillingChange}
              className="w-full p-2 rounded"
              style={{
                backgroundColor: theme.colors.textPrimary,
                color: theme.colors.textSecondary,
              }}
              placeholder="Placeholder"
            />
          </div>
          <div>
            <label className="block text-sm mb-2">Expiry Date</label>
            <input
              type="text"
              name="expiryDate"
              value={billingInfo.expiryDate}
              onChange={handleBillingChange}
              className="w-full p-2 rounded"
              style={{
                backgroundColor: theme.colors.textPrimary,
                color: theme.colors.textSecondary,
              }}
              placeholder="MM/YY"
            />
          </div>
          <div>
            <label className="block text-sm mb-2">CVV</label>
            <input
              type="text"
              name="cvv"
              value={billingInfo.cvv}
              onChange={handleBillingChange}
              className="w-full p-2 rounded"
              style={{
                backgroundColor: theme.colors.textPrimary,
                color: theme.colors.textSecondary,
              }}
              placeholder="123"
            />
          </div>
        </div>

        {/* Update Button */}
        <button
          className="px-6 py-3 rounded shadow transition w-full"
          onClick={updateSubscription}
          style={{
            backgroundColor: theme.colors.primary,
            color: theme.colors.textPrimary,
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
          onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
        >
          Update Subscription
        </button>
      </div>

      {/* Upgrade Plans Section */}
      <div
        className="flex-1 p-6 rounded-md"
        style={{
          backgroundColor: theme.colors.background,
          color: theme.colors.textPrimary,
        }}
      >
        <h2 className="text-xl font-bold mb-4">Upgrade Plans</h2>
        <div className="grid grid-cols-2 gap-4">
          {upgradePlans.map((plan, index) => (
            <div
              key={index}
              className="p-4 rounded-md flex justify-center items-center cursor-pointer transition"
              style={{
                backgroundColor: theme.colors.primary,
                color: theme.colors.textPrimary,
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
              onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
              onClick={() => setSubscriptionPlan(plan)}
            >
              {plan}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
