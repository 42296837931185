import React from "react";
import JsxParser from "react-jsx-parser";

const JSXRenderForMyTemplates = ({ jsx, textFields, mediaFiles }) => {
  // Replace text placeholders
  let processedJsx = jsx;
  textFields.forEach((text, i) => {
    processedJsx = processedJsx.replace(new RegExp(`\\b${[`Company Name`, `Product Name`, `Product Details`, `Website`][i]}\\b`, 'g'), text);
  });

  // Replace image placeholders
  mediaFiles.forEach((file, i) => {
    const base64String = file.split(",")[1];
    processedJsx = processedJsx.replace(`image${i + 1}`, `data:image/jpeg;base64,${base64String}`);
  });

  return (
    <div className="flex justify-center scaledLib object-center">
      <JsxParser jsx={processedJsx} />
    </div>
  );
};

export default JSXRenderForMyTemplates;
