import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import theme from "../theme";
import ApiService from "./ApiService";
export default function ForgotPassword() {
  const service = new ApiService();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize navigate

  // Handle form submission
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      const response = await service.post("/authentication/forgot-password", { email });
      setMessage(response.data.message);

      // Navigate to ResetPassword with email as state
      navigate("/reset-password", { state: { email } });
    } catch (err) {
      console.error("Forgot Password Error:", err);
      setError(err.response?.data?.message || "Something went wrong. Please try again.");
    }
  };

  return (
    <div
      style={{
        fontFamily: theme.typography.fontFamily,
        color: theme.colors.textPrimary,
        backgroundColor: theme.colors.background,
      }}
      className="min-h-screen flex items-center justify-center p-4 relative overflow-hidden"
    >
      <div
        className="max-w-lg w-full overflow-hidden transform transition duration-500 z-10 card-shimmer"
        style={{
          backgroundColor: theme.colors.cardBackground,
          borderRadius: theme.borders.borderRadius,
          boxShadow: theme.shadows.card,
          transform: "scale(1)",
          transition: "transform 0.5s",
        }}
        onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
        onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
      >
        <div style={{ padding: theme.spacing(2) }}>
          <div className="w-full flex justify-center">
            <img src="/logo1.png" alt="Company logo" className="w-40" />
          </div>
          <div style={{ marginTop: theme.spacing(2) }}>
            <h2 style={{ textAlign: "center", marginBottom: theme.spacing(2), color: theme.colors.textPrimary }}>
              Forgot Password
            </h2>
            <form onSubmit={handleForgotPassword}>
              <div style={{ marginBottom: theme.spacing(1) }}>
                <label
                  className="block"
                  style={{ color: theme.colors.textSecondary, marginBottom: theme.spacing(0.5) }}
                >
                  Email Address
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    width: "100%",
                    padding: theme.spacing(1),
                    border: `1px solid ${theme.colors.borderColor}`,
                    borderRadius: theme.borders.borderRadius,
                    backgroundColor: theme.components.input.background,
                    color: theme.components.input.color,
                  }}
                  required
                  placeholder="Enter your registered email"
                />
                {error && (
                  <p style={{ color: theme.colors.secondary, fontSize: theme.typography.fontSize.small }}>
                    {error}
                  </p>
                )}
              </div>
              <button
                type="submit"
                style={{
                  width: "100%",
                  padding: theme.spacing(1),
                  marginTop: theme.spacing(1),
                  borderRadius: theme.borders.borderRadius,
                  border: `1px solid ${theme.colors.primary}`,
                  backgroundColor: theme.colors.secondary,
                  color: theme.colors.textPrimary,
                  transition: "background-color 0.3s",
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
                onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
              >
                Request OTP
              </button>
            </form>
            {message && (
              <p className="success-message" style={{ color: theme.colors.success, marginTop: theme.spacing(2) }}>
                {message}
              </p>
            )}
            <p style={{ marginTop: theme.spacing(2), textAlign: "center" }}>
              Remembered your password?{" "}
              <Link to="/signin" style={{ color: theme.colors.primary, textDecoration: "underline" }}>
                Sign In
              </Link>
            </p>
          </div>
        </div>
        <div
          style={{
            backgroundColor: theme.colors.cardBackground,
            padding: theme.spacing(1),
            textAlign: "center",
            borderTop: `1px solid ${theme.colors.borderColor}`,
            borderBottomLeftRadius: theme.borders.borderRadius,
            borderBottomRightRadius: theme.borders.borderRadius,
          }}
        >
          <p style={{ fontSize: theme.typography.fontSize.small, color: theme.colors.textSecondary }}>
            By requesting an OTP, you agree to our{" "}
            <a href="#" style={{ color: theme.colors.primary, textDecoration: "underline" }}>
              Terms
            </a>{" "}
            and{" "}
            <a href="#" style={{ color: theme.colors.primary, textDecoration: "underline" }}>
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
