import React, { useEffect, useState } from "react";
import { FaChevronRight, FaChevronLeft, FaTrashAlt, FaPlus } from "react-icons/fa";
import introJs from "intro.js";
import "intro.js/introjs.css";
import theme from "../../theme";

const UploadMedia = ({
  mediaFiles,
  handleFileChange,
  handleNext,
  handlePrev,
  removeFile,
  textFields,
  handleTextChange,
  addTextField,
  removeTextField,
  rememberMe,
}) => {
  const [tourStarted, setTourStarted] = useState(false);
  const textFieldLabels = ["Company Name", "Product Name", "Product Details", "Website"];

  useEffect(() => {
    // Start the tour only if it hasn't started yet and textFields are rendered
    if (!tourStarted && textFields.length > 0) {
      const intro = introJs();
      const steps = [
        {
          intro: "Welcome to Step 2: Upload Text and Media.",
        },
      ];


      // Add a step for each text field
      textFields.forEach((text, index) => {
        steps.push({
          element: `#text-fields-section-${index + 1}`,
          intro: `This is the "${textFieldLabels[index]}" field. Please enter ${textFieldLabels[index]}.`,
        });
      });

      // Add the step for file upload section
      steps.push({
        element: "#file-upload-section",
        intro: "Upload your media files here.",
      });

      // Add the step for the next button
      steps.push({
        element: "#next-button",
        intro: "Proceed to the next step when you're ready.",
      });

      intro.setOptions({
        steps: steps,
        showSkipButton: true,
      });
      intro.start();

      setTourStarted(true); // Set the tour as started
    }
  }, [textFields, tourStarted, textFieldLabels]);

  return (
    <div style={{ color: theme.colors.textPrimary, fontFamily: theme.typography.fontFamily }}>
      <h2 className="text-2xl mb-6" style={{ color: theme.colors.textPrimary }}>
        Step 2: Upload Text and Media
      </h2>

      {/* Text Fields Section */}
      <div className="mb-6">
        {textFields.length > 0 ? (
          textFields.map((text, index) => (
            <div id={`text-fields-section-${index + 1}`} key={index} className="relative mb-4">
              <label
                className="block mb-1 font-medium"
                style={{ color: theme.colors.textSecondary }}
              >
                {textFieldLabels[index]}
              </label>
              <input
                type="text"
                value={text}
                onChange={(e) => handleTextChange(index, e.target.value)}
                className="block w-full p-2 rounded shadow-sm focus:outline-none"
                placeholder={`Enter ${textFieldLabels[index]}`}
                style={{
                  backgroundColor: theme.colors.cardBackground,
                  color: theme.colors.textPrimary,
                  border: `1px solid ${theme.colors.secondary}`,
                }}
                onFocus={(e) => (e.target.style.borderColor = theme.colors.primary)}
                onBlur={(e) => (e.target.style.borderColor = theme.colors.secondary)}
              />
              <button
                className="absolute top-1 right-1 p-1 rounded-full"
                onClick={() => removeTextField(index)}
                style={{
                  backgroundColor: theme.colors.secondary,
                  color: theme.colors.textPrimary,
                }}
              >
                <FaTrashAlt />
              </button>
            </div>
          ))
        ) : (
          <p className="text-red-600">No text fields added.</p>
        )}
      </div>

      {/* Add Text Field Button */}
      <div className="flex justify-between mb-6">
        <button
          id="add-text-field-button"
          className="px-6 py-3 rounded shadow transition"
          onClick={addTextField}
          disabled={textFields.length >= textFieldLabels.length} // Limit to the number of labels
          style={{
            backgroundColor: theme.colors.primary,
            color: theme.colors.textPrimary,
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
          onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
        >
          <FaPlus />
        </button>
      </div>

      {/* File Upload Section */}
      <div
        id="file-upload-section"
        className="p-8 border-2 border-dashed rounded text-center mb-6"
        style={{
          backgroundColor: theme.colors.background,
          borderColor: theme.colors.textSecondary,
          color: theme.colors.textSecondary,
        }}
      >
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="block w-full mb-4"
          style={{
            color: theme.colors.textPrimary,
          }}
        />
      </div>
      {/* Display Selected Media Files */}
      <div className="flex justify-start mb-6 flex-wrap">
        {mediaFiles.length > 0 ? (
          mediaFiles.map((file, index) => (
            <div
              key={index}
              className="relative h-40 w-40 border mx-2 rounded-md overflow-hidden"
              style={{
                borderColor: theme.colors.textSecondary,
              }}
            >
              <img
                src={URL.createObjectURL(file)}
                alt={`Media File ${index + 1}`}
                className="h-full w-full object-cover"
              />
              <button
                className="absolute top-1 right-1 p-1 rounded-full"
                onClick={() => removeFile(index)}
                style={{
                  backgroundColor: theme.colors.secondary,
                  color: theme.colors.textPrimary,
                }}
              >
                <FaTrashAlt />
              </button>
            </div>
          ))
        ) : (
          <p style={{ color: theme.colors.secondary }}>No media files selected.</p>
        )}
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-between mt-6">
        <button
          className="px-6 py-3 rounded shadow transition"
          onClick={handlePrev}
          style={{
            backgroundColor: theme.colors.primary,
            color: theme.colors.textPrimary,
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
          onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
        >
          <FaChevronLeft /> Previous
        </button>
        <button
          id="next-button"
          className="px-6 py-3 rounded shadow transition"
          onClick={handleNext}
          style={{
            backgroundColor: theme.colors.primary,
            color: theme.colors.textPrimary,
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
          onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
        >
          Next <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default UploadMedia;
