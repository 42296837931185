import React, { useEffect } from "react";
import introJs from "intro.js";
import "intro.js/introjs.css";
import theme from "../../theme";

const ProjectDetails = ({
  projectName,
  setProjectName,
  adPlatform,
  setAdPlatform,
  handleNext,
  rememberMe
}) => {
  useEffect(() => {
    const intro = introJs();
    intro.setOptions({
      steps: [
        {
          intro: "Welcome to Step 1: Project Details. Let's get started!",
        },
        {
          element: "#project-name-input",
          intro: "Enter the name of your project here.",
        },
        {
          element: "#ad-platform-select",
          intro: "Select the ad platform for your project.",
        },
        {
          element: "#next-button",
          intro: "Click here to proceed to the next step.",
        },
      ],
      showSkipButton: true,
    });
    intro.start();
  }, []);

  return (
    <div
      style={{
        backgroundColor: theme.colors.background,
        padding: "2rem",
        borderRadius: "8px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
        fontFamily: theme.typography.fontFamily,
      }}
    >
      <h2
        className="text-2xl mb-6"
        style={{ color: theme.colors.textPrimary }}
      >
        Step 1: Project Details
      </h2>

      {/* Project Name Input */}
      <input
        id="project-name-input"
        type="text"
        placeholder="Enter your project name"
        value={projectName}
        onChange={(e) => setProjectName(e.target.value)}
        className="block w-full p-3 mb-6 rounded shadow-sm focus:outline-none"
        style={{
          backgroundColor: theme.colors.cardBackground,
          color: theme.colors.textPrimary,
          border: `1px solid ${theme.colors.primary}`,
          fontFamily: theme.typography.fontFamily,
        }}
      />

      {/* Ad Platform Select */}
      <select
        id="ad-platform-select"
        value={adPlatform}
        onChange={(e) => setAdPlatform(e.target.value)}
        className="block w-full p-3 mb-6 rounded shadow-sm focus:outline-none"
        style={{
          backgroundColor: theme.colors.cardBackground,
          color: theme.colors.textPrimary,
          border: `1px solid ${theme.colors.primary}`,
          fontFamily: theme.typography.fontFamily,
        }}
        onFocus={(e) => (e.target.style.borderColor = theme.colors.secondary)}
        onBlur={(e) => (e.target.style.borderColor = theme.colors.primary)}
      >
        <option value="" style={{ color: theme.colors.textSecondary }}>
          Select Ad Platform
        </option>
        <option value="Facebook" style={{ color: theme.colors.textPrimary }}>
          Facebook
        </option>
        <option value="Instagram" style={{ color: theme.colors.textPrimary }}>
          Instagram
        </option>
      </select>

      {/* Action Buttons */}
      <div className="flex justify-end space-x-4">
        <button
          id="next-button"
          onClick={handleNext}
          className="px-6 py-3 rounded shadow transition"
          style={{
            backgroundColor: theme.colors.secondary,
            color: theme.colors.textPrimary,
            fontFamily: theme.typography.fontFamily,
            fontSize: "1rem",
            fontWeight: theme.typography.fontWeight.medium,
            cursor: "pointer",
            border: "none",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
          onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ProjectDetails;
