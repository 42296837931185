import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import theme from "../theme"; // Import the theme

export default function UserDashboard() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const sessionUserName = sessionStorage.getItem('userName');
    const localUserName = localStorage.getItem('userName');
    if (sessionUserName) {
      setUserName(sessionUserName);
    } else if (localUserName) {
      setUserName(localUserName);
    }
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div
      className="min-h-screen flex relative"
      style={{
        fontFamily: theme.typography.fontFamily,
        backgroundColor: theme.colors.background,
        color: theme.colors.textSecondary,
      }}
    >
      <main className="flex-1 absolute w-4/5 right-0 p-10">
        {/* Header */}
        <header className="flex justify-between items-center mb-8">
          <h2
            className="text-3xl font-bold"
            style={{ color: theme.colors.textPrimary }}
          >
            Welcome back {userName ? userName.toUpperCase() : ""}
          </h2>
          <div className="space-x-2">
            <button
              onClick={() => handleNavigation("/projects")}
              className="px-4 py-2 rounded-md transition-colors"
              style={{
                backgroundColor: theme.colors.primary,
                color: theme.colors.textPrimary,
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
              onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
            >
              Create New Project
            </button>
            <button
              onClick={() => handleNavigation("/media")}
              className="px-4 py-2 rounded-md transition-colors"
              style={{
                backgroundColor: theme.colors.primary,
                color: theme.colors.textPrimary,
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
              onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
            >
              Upload Files
            </button>
          </div>
        </header>

        {/* Recent Projects Section */}
        <section
          className="p-6 rounded-md"
          style={{ backgroundColor: theme.colors.cardBackground }}
        >
          <h3
            className="text-xl font-semibold mb-4"
            style={{ color: theme.colors.textPrimary }}
          >
            Recent Projects
          </h3>
          <div className="grid grid-cols-2 gap-4">
            {[1, 2, 3].map((project) => (
              <div
                key={project}
                className="p-4 rounded-md shadow-sm transition-transform transform hover:scale-105"
                style={{ backgroundColor: theme.colors.backgroundSecondary }}
              >
                <h4 className="font-bold" style={{ color: theme.colors.textSecondary }}>
                  Project Title
                </h4>
                <p className="text-sm" style={{ color: theme.colors.notificationText }}>
                  Status: {"In Progress"}
                </p>
              </div>
            ))}
          </div>
        </section>

        {/* Notification Panel */}
        <section
          className="mt-8 p-6 rounded-md"
          style={{ backgroundColor: theme.colors.cardBackground }}
        >
          <h3
            className="text-xl font-semibold"
            style={{ color: theme.colors.textPrimary }}
          >
            Notification Panel
          </h3>
          <p className="text-sm" style={{ color: theme.colors.notificationText }}>
            No new notifications
          </p>
        </section>
      </main>
    </div>
  );
}
