import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import store from "./store";
// import { Provider } from "react-redux";

// Use environment variable for client ID
const googleClientId =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  "317953110841-7tchs7fu79bdncesse7tcb24jvda7753.apps.googleusercontent.com";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={googleClientId}>
    {/* <Provider store={store}> */}
    <App />
    {/* </Provider> */}
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// Measure performance in development
