import React, { useState } from "react";
import axios from "axios";
import theme from "../../theme"; // Import theme
import ApiService from "../ApiService";
export default function Brand({ rememberMe }) {
  const [brandData, setBrandData] = useState({
    brandName: "",
    logo: "",
    primaryColor: "",
    secondaryColor: "",
    font: "",
  });
  const service = new ApiService();
  const [selectedFont, setSelectedFont] = useState("");

  // Convert file to Base64
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setBrandData({ ...brandData, logo: reader.result });
    };
    reader.readAsDataURL(file);
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setBrandData({ ...brandData, [field]: value });
  };

  const updateBrandSettings = async () => {
    try {
      const id = rememberMe ? localStorage.getItem("id") : sessionStorage.getItem("id");
      const response = await service.put(`/brand/update/${id}`, {
        brand_name: brandData.brandName,
        logo: brandData.logo,
        primary_color: brandData.primaryColor,
        secondary_color: brandData.secondaryColor,
        font: selectedFont,
      });
      alert(response.data.message);
      console.log(response.data);
    } catch (error) {
      console.error("Error updating or creating brand:", error);
      alert("Failed to update brand settings.");
    }
  };

  return (
    <div
      className="flex-1 p-6 rounded-md"
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
      }}
    >
      <h2 className="text-3xl font-bold mb-8">Brand Settings</h2>

      {/* Brand Name Input */}
      <div className="mb-6 flex items-center">
        <input
          type="text"
          value={brandData.brandName}
          onChange={(e) => handleInputChange(e, "brandName")}
          className="w-full p-2 rounded-md"
          style={{
            backgroundColor: theme.colors.textPrimary,
            color: theme.colors.textSecondary,
          }}
          placeholder="Brand Name"
        />
      </div>

      {/* Upload Brand Logo */}
      <div className="mb-6 flex items-center">
        <input
          type="file"
          onChange={handleFileUpload}
          className="w-3/4 p-2 rounded-md"
          style={{
            backgroundColor: theme.colors.textPrimary,
            color: theme.colors.textSecondary,
          }}
        />
      </div>

      {/* Primary Brand Color */}
      <div className="mb-6 flex items-center">
        <input
          type="text"
          value={brandData.primaryColor}
          onChange={(e) => handleInputChange(e, "primaryColor")}
          className="w-3/4 p-2 rounded-md"
          style={{
            backgroundColor: theme.colors.textPrimary,
            color: theme.colors.textSecondary,
          }}
          placeholder="Primary Color"
        />
      </div>

      {/* Secondary Brand Color */}
      <div className="mb-6 flex items-center">
        <input
          type="text"
          value={brandData.secondaryColor}
          onChange={(e) => handleInputChange(e, "secondaryColor")}
          className="w-3/4 p-2 rounded-md"
          style={{
            backgroundColor: theme.colors.textPrimary,
            color: theme.colors.textSecondary,
          }}
          placeholder="Secondary Color"
        />
      </div>

      {/* Font Selection */}
      <div className="mb-6 flex items-center">
        <select
          value={selectedFont}
          onChange={(e) => setSelectedFont(e.target.value)}
          className="w-3/4 p-2 rounded-md"
          style={{
            backgroundColor: theme.colors.textPrimary,
            color: theme.colors.textSecondary,
          }}
        >
          <option value="" disabled>
            Please Pick Something
          </option>
          <option value="Arial">Arial</option>
          <option value="Roboto">Roboto</option>
          <option value="Georgia">Georgia</option>
          <option value="Times New Roman">Times New Roman</option>
          <option value="Helvetica">Helvetica</option>
        </select>
      </div>

      {/* Update Button */}
      <button
        className="px-6 py-3 rounded shadow transition"
        style={{
          backgroundColor: theme.colors.primary,
          color: theme.colors.textPrimary,
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
        onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
        onClick={updateBrandSettings}
      >
        Update Brand Settings
      </button>
    </div>
  );
}