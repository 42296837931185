import theme from "../../theme"; // Import theme

export default function ActionButtons({ selectedItems, onDelete, onMove }) {
  const handleDelete = () => {
    if (selectedItems.length > 0) {
      onDelete();
    }
  };

  const handleMove = () => {
    if (selectedItems.length > 0) {
      onMove();
    }
  };

  return (
    <div className="mt-8 flex justify-center space-x-10">
      <button
        className="px-4 py-2 rounded-md transition-colors"
        onClick={handleDelete}
        disabled={selectedItems.length === 0}
        style={{
          backgroundColor: theme.colors.cardBackground,
          color: theme.colors.textPrimary,
          opacity: selectedItems.length === 0 ? 0.5 : 1, // Adjust opacity when disabled
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
        onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.cardBackground)}
      >
        Delete
      </button>
      <button
        className="px-4 py-2 rounded-md transition-colors"
        onClick={handleMove}
        disabled={selectedItems.length === 0}
        style={{
          backgroundColor: theme.colors.cardBackground,
          color: theme.colors.textPrimary,
          opacity: selectedItems.length === 0 ? 0.5 : 1, // Adjust opacity when disabled
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
        onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.cardBackground)}
      >
        Move
      </button>
    </div>
  );
}
