// // Pagination.js
// export default function Pagination({ currentPage, totalPages, onPageChange }) {
//   return (
//     <div className="mt-8 flex justify-center items-center space-x-4">
//       <button
//         onClick={() => onPageChange(currentPage - 1)}
//         disabled={currentPage === 1}
//         className="px-1 py-1 bg-white text-black rounded disabled:opacity-50"
//       >
//         Previous
//       </button>
//       <span className="text-[#ECDFCC]">
//         Page {currentPage} of {totalPages}
//       </span>
//       <button
//         onClick={() => onPageChange(currentPage + 1)}
//         disabled={currentPage === totalPages}
//         className="px-1 py-1 bg-white text-black rounded disabled:opacity-50"
//       >
//         Next
//       </button>
//     </div>
//   );
// }


import theme from "../../theme"; // Import theme

export default function Pagination({ currentPage, totalPages, onPageChange }) {
  return (
    <div className="mt-8 flex justify-center items-center space-x-4">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-1 py-1 rounded disabled:opacity-50 transition-colors"
        style={{
          backgroundColor: theme.colors.primary,
          color: theme.colors.textPrimary,
        }}
      >
        Previous
      </button>
      <span style={{ color: theme.colors.textPrimary }}>
        Page {currentPage} of {totalPages}
      </span>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-1 py-1 rounded disabled:opacity-50 transition-colors"
        style={{
          backgroundColor: theme.colors.primary,
          color: theme.colors.textPrimary,
        }}
      >
        Next
      </button>
    </div>
  );
}
