import React, { useState } from "react";
import axios from "axios";
import theme from "../../theme"; // Import theme
import ApiService from "../ApiService";
export default function Security({ securitySettings, setSecuritySettings, rememberMe }) {
  const service = new ApiService();
  const [isLoading, setIsLoading] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  // Handle input changes
  const handleChange = (e) => {
    setSecuritySettings({
      ...securitySettings,
      [e.target.name]: e.target.value,
    });
  };

  // Function to validate the new password
  const validateNewPassword = () => {
    const { oldPassword, newPassword } = securitySettings;

    if (newPassword.length < 8) {
      setPasswordError("New password must be at least 8 characters long.");
      return false;
    }

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (!specialCharRegex.test(newPassword)) {
      setPasswordError("New password must contain at least one special character.");
      return false;
    }

    if (newPassword.includes(oldPassword)) {
      setPasswordError("New password should not contain any part of the old password.");
      return false;
    }

    setPasswordError("");
    return true;
  };

  const updatePassword = async () => {
    setIsLoading(true);
    setVerificationError("");

    if (!validateNewPassword()) {
      setIsLoading(false);
      return;
    }

    try {
      const id = rememberMe ? localStorage.getItem("id") : sessionStorage.getItem("id");

      const response = await service.put(`/auth/updatepassword/${id}`, {
        oldPassword: securitySettings.oldPassword,
        newPassword: securitySettings.newPassword,
      });

      if (response.status === 200) {
        alert("Password updated successfully!");
        setSecuritySettings({ oldPassword: "", newPassword: "" });
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setVerificationError("User not found or endpoint does not exist.");
      } else if (error.response && error.response.status === 400) {
        setVerificationError("Incorrect old password.");
      } else {
        setVerificationError("Failed to update password. Please try again later.");
      }
      console.error("Error updating password:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="flex-1 p-6 rounded-md"
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
      }}
    >
      <h2 className="text-2xl font-semibold mb-6">Security Settings</h2>

      {/* Old Password Field */}
      <div className="mb-4">
        <label className="block text-lg mb-2">Old Password</label>
        <input
          type="password"
          name="oldPassword"
          value={securitySettings.oldPassword}
          onChange={handleChange}
          className="w-full p-3 border rounded-md"
          style={{
            backgroundColor: theme.colors.textPrimary,
            color: theme.colors.textSecondary,
            borderColor: theme.colors.borderColor,
          }}
          required
        />
      </div>

      {/* New Password Field */}
      <div className="mb-4">
        <label className="block text-lg mb-2">New Password</label>
        <input
          type="password"
          name="newPassword"
          value={securitySettings.newPassword}
          onChange={handleChange}
          className="w-full p-3 border rounded-md"
          style={{
            backgroundColor: theme.colors.textPrimary,
            color: theme.colors.textSecondary,
            borderColor: theme.colors.borderColor,
          }}
          required
        />
        {passwordError && (
          <p style={{ color: theme.colors.secondary }} className="text-xs">
            {passwordError}
          </p>
        )}
      </div>

      {/* Display any errors from password verification */}
      {verificationError && (
        <p style={{ color: theme.colors.secondary }} className="text-xs">
          {verificationError}
        </p>
      )}

      {/* Update Button */}
      <button
        className="mt-4 px-6 py-3 rounded shadow transition"
        style={{
          backgroundColor: theme.colors.primary,
          color: theme.colors.textPrimary,
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
        onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
        onClick={updatePassword}
        disabled={isLoading}
      >
        {isLoading ? "Updating..." : "Update Security Settings"}
      </button>
    </div>
  );
}
