import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  SettingsOutlined,
  ChevronRightOutlined,
  HomeOutlined,
  WorkOutlineOutlined,       // For "Projects"
  CollectionsOutlined,       // For "Media"
  BarChartOutlined,          // For "Analytics"
  ExtensionOutlined,         // For "Integrations"
  SupportAgentOutlined,      // For "Support"
  AccountCircleOutlined,     // For Profile icon
} from "@mui/icons-material";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import theme from "../theme"; // Import the theme

const navItems = [
  {
    text: "Dashboard",
    icon: <HomeOutlined />,
  },
  {
    text: "Projects",
    icon: <WorkOutlineOutlined />,
  },
  {
    text: "Marketing",
    icon: <ExtensionOutlined />,
  },
  {
    text: "Templates",
    icon: <AccountCircleOutlined />,
  },
  {
    text: "Media",
    icon: <CollectionsOutlined />,
  },
  {
    text: "Analytics",
    icon: <BarChartOutlined />,
  },
  {
    text: "Integrations",
    icon: <ExtensionOutlined />,
  },
  {
    text: "Settings",
    icon: <SettingsOutlined />,
  },
  {
    text: "Support",
    icon: <SupportAgentOutlined />,
  }
];

const SideBar = ({ drawerWidth = "300px", rememberMe, setProfileUrl, profileUrl }) => {
  const [userName, setUserName] = useState("");
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [logOutDiv, setLogOutDiv] = useState(false);

  useEffect(() => {
    const currentPath = location.pathname.substring(1);
    setActive(currentPath);
  }, [location.pathname]);

  const handleLogOut = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/signin");
  };

  useEffect(() => {
    const sessionUserName = sessionStorage.getItem("userName");
    const localUserName = localStorage.getItem("userName");
    if (sessionUserName) {
      setUserName(sessionUserName);
    } else if (localUserName) {
      setUserName(localUserName);
    }
  }, []);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const id = rememberMe
          ? localStorage.getItem("id")
          : sessionStorage.getItem("id");

        const response = await axios.get(
          `http://localhost:5000/auth/getlogo/${id}`
        );
        if (response.data) {
          // setProfileImage(response.data);
          setProfileUrl(`data:image/png;base64,${response.data}`);

        }
      } catch (error) {
        console.error("Error fetching logo:", error.response?.data || error.message);
        // Optionally, handle the error in the UI
      }
    };

    fetchLogo();
  }, [rememberMe]);


  return (
    <Box component="nav" sx={{ fontFamily: theme.typography.fontFamily }}>
      <Drawer
        variant="persistent"
        anchor="left"
        open={true}
        sx={{
          width: drawerWidth,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: theme.colors.cardBackground,
            margin: "1rem",
            borderRadius: "20px",
            height: "95%",
            fontFamily: theme.typography.fontFamily, // Apply font family to the drawer
          },
        }}
      >
        <Box width="100%">
          {/* Header Section */}
          <Box m="1.5rem 2rem 2rem 2rem">
            <FlexBetween>
              <Box display="flex" alignItems="center" gap="0.5rem">
                <img src="/logo1.png" />
              </Box>
            </FlexBetween>
          </Box>

          {/* Navigation Items */}
          <List>
            {navItems.map(({ text, icon }) => {
              const lcText = text.toLowerCase();
              return (
                <ListItem key={text} disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(`/${lcText}`);
                      setActive(lcText);
                    }}
                    sx={{
                      backgroundColor: active === lcText ? theme.colors.primary : "transparent",
                      color: theme.colors.textPrimary,
                      "&:hover": {
                        backgroundColor: theme.colors.secondary,
                      },
                      fontFamily: theme.typography.fontFamily,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        ml: "2rem",
                        color: active === lcText ? theme.colors.textPrimary : theme.colors.iconColor,
                      }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                    {active === lcText && (
                      <ChevronRightOutlined sx={{ ml: "auto", color: theme.colors.textPrimary }} />
                    )}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Box>

        {/* Profile and Log Out Section */}
        <Box position="absolute" bottom="2rem" width="100%">
          {logOutDiv && (
            <Box display="flex" justifyContent="center" mb="1rem">
              <button
                onClick={handleLogOut}
                style={{
                  backgroundColor: theme.colors.cardBackground,
                  color: theme.colors.textPrimary,
                  border: `1px solid ${theme.colors.iconColor}`,
                  borderRadius: "8px",
                  padding: "8px 16px",
                  cursor: "pointer",
                  fontFamily: theme.typography.fontFamily,
                  transition: "background-color 0.3s ease",
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
                onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.cardBackground)}
              >
                Log Out
              </button>
            </Box>
          )}
          <Divider />
          <FlexBetween
            textTransform="none"
            gap="1.5rem"
            m="1.5rem 2rem 0 3rem"
            sx={{ fontFamily: theme.typography.fontFamily }}
          >
            {/* Profile Image */}
            <Box
              component="img"
              alt="profile"
              src={profileUrl}
              height="50px"
              width="50px"
              borderRadius="50%"
              sx={{
                objectFit: "cover",
                border: `2px solid ${theme.colors.iconColor}`,
                padding: "0.2rem",
              }}
            />
            {/* User Info */}
            <Box textAlign="left">
              <Typography
                fontWeight="bold"
                fontSize="1rem"
                sx={{ color: theme.colors.textPrimary, fontFamily: theme.typography.fontFamily }}
              >
                {userName.toUpperCase()}
              </Typography>
              <Typography fontSize="0.85rem" sx={{ color: theme.colors.textSecondary, fontFamily: theme.typography.fontFamily }}>
                150 Credits
              </Typography>
            </Box>
            {/* Settings Icon */}
            <SettingsOutlined
              onClick={() => setLogOutDiv((state) => !state)}
              sx={{
                fontSize: "30px",
                cursor: "pointer",
                color: theme.colors.iconColor,
                "&:hover": {
                  color: theme.colors.secondary,
                  transform: "scale(1.1)",
                  transition: "0.3s ease-in-out",
                },
              }}
            />
          </FlexBetween>
        </Box>
      </Drawer>
      <Outlet />
    </Box>
  );
};

export default SideBar;
