import theme from "../../theme"; // Import theme

export default function ProjectItem({
  project,
  isSelected,
  onSelect,
  onPreview,
}) {
  return (
    <div
      className={`p-4 rounded-md shadow-sm transition-transform transform hover:scale-105 ${
        isSelected ? `border-2` : ""
      }`}
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
        borderColor: isSelected ? theme.colors.primary : "transparent",
      }}
    >
      <input
        type="checkbox"
        checked={isSelected}
        onChange={onSelect}
        className="mb-2"
      />
      <img src="/logo.jpg" alt="Project" className="mb-2" />
      <h4 className="font-bold">Project Title: {project}</h4>
      <p style={{ color: theme.colors.textSecondary }} className="text-sm">
        0.00 credits
      </p>
      <div className="flex justify-center mt-2">
        <button
          className="w-1/2 m-1 rounded transition-colors"
          style={{
            backgroundColor: theme.colors.primary,
            color: theme.colors.textPrimary,
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
          onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
          onClick={onPreview}
        >
          Preview
        </button>
      </div>
    </div>
  );
}
