import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaGoogle } from "react-icons/fa";
import theme from "../theme";
import ApiService from "./ApiService"
function SignIn({ setIsAuthenticated, rememberMe, setRememberMe }) {
  const service = new ApiService();
  const navigate = useNavigate();

  // State to capture email and password input
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // State to store any error messages

  // Traditional email/password login handler
  const handleLogin = async (e) => {
    e.preventDefault();
    setError(""); // Reset any previous errors

    try {
      const response = await service.post("/auth/login", {
        email,
        password,
      });

      // Destructure the token and user data from the response
      const { token, user } = response.data;
      const { name, email: userEmail, id } = user;

      // Store token, name, and email in either localStorage or sessionStorage based on "Remember me"
      const storage = rememberMe ? localStorage : sessionStorage;
      storage.setItem("token", JSON.stringify(token));
      storage.setItem("userName", name);
      storage.setItem("userEmail", userEmail);
      storage.setItem("id", id);

      setIsAuthenticated(true);
      // Navigate to the dashboard on successful login
      navigate("/dashboard");
    } catch (error) {
      console.error("Login Failed:", error);
      alert("Login Failed. Please try again.");
    }
  };

  // Google login logic
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const { access_token } = tokenResponse;
        const userInfoResponse = await axios.get(
          "https://www.googleapis.com/oauth2/v2/userinfo",
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        const { name, email, picture } = userInfoResponse.data;
        // console.log(userInfoResponse.data.JSONStringify());
        console.log(userInfoResponse.data.picture);
        // Send user info to your backend for Google login/register
        const loginResponse = await service.post(
          "/auth/google-signup",
          {
            name,
            email: email.toLowerCase().trim(),
            picture,
          }
        );
        // Destructure the token and user data from the backend response
        const { token, user } = loginResponse.data;
        // console.log(loginResponse.data)
        // Store the JWT token and user info in either localStorage or sessionStorage based on "Remember me"
        const storage = rememberMe ? localStorage : sessionStorage;
        storage.setItem("token", JSON.stringify(token));
        storage.setItem("userName", user.name);
        storage.setItem("userEmail", user.email);
        storage.setItem("profilePic", user.picture);
        // Navigate to the dashboard
        // console.log("before navigate")
        navigate("/dashboard");
      } catch (error) {
        console.error("Login Failed:", error);
      }
    },
    onError: (error) => {
      console.error("Login Failed:", error);
    },
  });

  return (
    <div
      style={{
        fontFamily: theme.typography.fontFamily,
        color: theme.colors.textPrimary,
        backgroundColor: theme.colors.background,
      }}
      className="min-h-screen flex items-center justify-center p-4 relative overflow-hidden"
    >
      <div
        className="max-w-lg w-full overflow-hidden transform transition duration-500 z-10"
        style={{
          backgroundColor: theme.colors.cardBackground,
          borderRadius: theme.borders.borderRadius,
          boxShadow: theme.shadows.card,
          transform: "scale(1)",
          transition: "transform 0.5s",
        }}
        onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
        onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
      >
        <div style={{ padding: theme.spacing(2) }}>
          <div className="flex justify-center mb-8"></div>
          <div className="flex justify-center">
            <img src="/logo1.png" alt="Company logo" className="h-20 w-15 " />
          </div>
          <div style={{ marginTop: theme.spacing(2) }}>
            <form onSubmit={handleLogin}>
              <div style={{ marginBottom: theme.spacing(1) }}>
                <label className="block" style={{ color: theme.colors.textSecondary }}>
                  Email address
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    width: "100%",
                    padding: theme.spacing(1),
                    border: `1px solid ${theme.colors.borderColor}`,
                    borderRadius: theme.borders.borderRadius,
                    backgroundColor: theme.components.input.background,
                    color: theme.components.input.color,
                  }}
                  required
                />
              </div>
              <div style={{ marginBottom: theme.spacing(1) }}>
                <label className="block" style={{ color: theme.colors.textSecondary }}>
                  Password
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    width: "100%",
                    padding: theme.spacing(1),
                    border: `1px solid ${theme.colors.borderColor}`,
                    borderRadius: theme.borders.borderRadius,
                    backgroundColor: theme.components.input.background,
                    color: theme.components.input.color,
                  }}
                  required
                  onFocus={(e) => (e.target.style.borderColor = theme.colors.primary)}
                  onBlur={(e) => (e.target.style.borderColor = theme.colors.borderColor)}
                />
              </div>
              <div
                className="flex items-center justify-between"
                style={{ marginBottom: theme.spacing(1) }}
              >
                <label className="flex items-center" style={{ color: theme.colors.textSecondary }}>
                  <input
                    type="checkbox"
                    className="form-checkbox"
                    checked={rememberMe}
                    onChange={() => setRememberMe((prevState) => !prevState)} // Toggle "Remember me" state
                    style={{ accentColor: theme.colors.primary }}
                  />
                  <span style={{ marginLeft: theme.spacing(0.5) }}>Remember me</span>
                </label>
                <a
                  href="/forgot-password"
                  style={{
                    fontSize: theme.typography.fontSize.small,
                    color: theme.colors.primary,
                    textDecoration: "underline",
                  }}
                >
                  Forgot password?
                </a>
              </div>
              {error && <p style={{ color: theme.colors.secondary }}>{error}</p>}
              <button
                type="submit"
                style={{
                  width: "100%",
                  padding: theme.spacing(1),
                  marginTop: theme.spacing(1),
                  borderRadius: theme.borders.borderRadius,
                  border: `1px solid ${theme.colors.primary}`,
                  backgroundColor: "#fff",
                  color: "#000",
                  transition: "background-color 0.3s",
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
                onMouseOut={(e) => (e.target.style.backgroundColor = "#fff")}
              >
                Login
              </button>
              <button
                type="button"
                style={{
                  width: "100%",
                  padding: theme.spacing(1),
                  marginTop: theme.spacing(1),
                  borderRadius: theme.borders.borderRadius,
                  backgroundColor: theme.colors.primary,
                  color: theme.colors.textPrimary,
                  transition: "background-color 0.3s",
                }}
                onClick={() => {
                  navigate("/signup");
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
                onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
              >
                Sign up
              </button>
            </form>
            <button
              onClick={() => login()}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: theme.spacing(1),
                padding: theme.spacing(1),
                backgroundColor: "#DB4437", // Google's brand color
                color: "#FFFFFF",
                fontWeight: theme.typography.fontWeight.bold,
                borderRadius: theme.borders.borderRadius,
                transition: "background-color 0.3s, transform 0.3s",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#C33D2E";
                e.currentTarget.style.transform = "translateY(-2px)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#DB4437";
                e.currentTarget.style.transform = "translateY(0)";
              }}
            >
              <FaGoogle style={{ marginRight: theme.spacing(0.5), fontSize: theme.typography.fontSize.large }} />
              Sign in with Google
            </button>
          </div>
        </div>
        <div
          style={{
            backgroundColor: theme.colors.cardBackground,
            padding: theme.spacing(1),
            textAlign: "center",
            borderTop: `1px solid ${theme.colors.borderColor}`,
            borderBottomLeftRadius: theme.borders.borderRadius,
            borderBottomRightRadius: theme.borders.borderRadius,
          }}
        >
          <p style={{ fontSize: theme.typography.fontSize.small, color: theme.colors.textSecondary }}>
            By signing in, you agree to our{" "}
            <a href="#" style={{ color: theme.colors.primary, textDecoration: "underline" }}>
              Terms
            </a>{" "}
            and{" "}
            <a href="#" style={{ color: theme.colors.primary, textDecoration: "underline" }}>
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
