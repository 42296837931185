// export const temp = new Map([
//   [
//     "650ff9a1f443f9056dbd45b3",
//     {
//       _id: "650ff9a1f443f9056dbd45b3",
//       numberofimages: 1,
//       numberoftextfields: 2,
//       category: "portrait",
//       type: "Facebook",
//       code: `<div className="w-[1080px] h-[1920px] relative bg-[#4a90e2]">
//         <div className="w-[900px] h-[1798px] left-[90px] top-[61px] absolute bg-[#d9d9d9] rounded-[100px]" />
//         <div className="w-[261.20px] h-[0px] left-[110px] top-[104px] absolute origin-top-left rotate-[58.62deg] border-4 border-[#d4af37]"></div>
//         <div className="w-[572.32px] h-[0px] left-[110px] top-[104px] absolute origin-top-left rotate-[58.62deg] border-4 border-[#d4af37]"></div>
//         <div className="w-[1240.47px] h-[0px] left-[396px] top-[572px] absolute origin-top-left rotate-[61.39deg] border-4 border-[#d4af37]"></div>
//         <img className="w-[572.53px] object-cover h-[500px] left-[246px] top-[348px] absolute rounded-[100px]" src="image1" />
//         <div className="w-[418px] h-[976px] left-[114px] top-[851px] absolute text-black text-8xl font-normal font-['Inter']">Company Name</div>
//         <div className="w-[483px] h-[255px] left-[276px] top-[93px] absolute text-center text-black text-8xl font-normal font-['Inter']">Product Name</div>
//     </div>`,
//     },
//   ],
//   [
//     "650ff9a1f443f9056dbd45b1",
//     {
//       _id: "650ff9a1f443f9056dbd45b1",
//       numberofimages: 1,
//       numberoftextfields: 2,
//       category: "landscape",
//       type: "Instagram",
//       code: `<div className="w-[1920px] h-[1080px] relative bg-[#1f1d1d]">
//     <div className="w-[1920px] h-36 left-0 top-[606px] absolute text-center text-[#dddddd]/90 text-[64px] font-normal font-['Inter']">Company Name</div>
//     <div className="w-[1920px] h-[267px] left-0 top-[823px] absolute text-center text-[#b58d48]/90 text-[35px] font-normal font-['Inter']">Product Name</div>
//     <div className="w-[1277px] h-1 left-[308px] top-[560px] absolute justify-center items-center inline-flex">
//     <div className="w-[1277.01px] h-[0px] border-2 border-[#b58d48]"></div>
//     </div>
//     <img className="w-[497px] h-[484px] object-cover left-[698px] top-[23px] absolute rounded-[76px]" src="image1" />
// </div>`,

//     },
//   ],
//   [
//     "f7a3c1d9b56e8a4073fc9b5d",
//     {
//       _id: "f7a3c1d9b56e8a4073fc9b5d",
//       numberofimages: 1,
//       numberoftextfields: 2,
//       category: "landscape",
//       type: "Instagram",
//       code: `<div className="w-[1920px] h-[1080px] relative bg-[#38374d] shadow">
//     <img className="w-[443.05px] h-[450px] object-cover left-[4px] top-[11px] absolute rounded-[174.50px]" src="image1" />
//     <div className="w-[1357px] h-[625px] left-[563px] top-[55px] absolute text-[#f0be72] text-8xl font-normal font-['Inter']">Company Name</div>
//     <div className="w-[1875px] h-[133px] left-[26px] top-[925px] absolute text-[#f0be72] text-2xl font-normal font-['Inter']">Product Name</div>
// </div>`,

//     },
//   ],
//   [
//     "b5d67c8e1a3b9d2f50f4c7a6",
//     {
//       _id: "b5d67c8e1a3b9d2f50f4c7a6",
//       numberofimages: 2,
//       numberoftextfields: 2,
//       category: "landscape",
//       type: "Instagram",
//       code: `<div className="w-[1920px] h-[1080px] relative bg-[#80848b]">
//     <div className="w-[86px] h-[93px] left-[108px] top-0 absolute text-white text-xl font-normal font-['Kalam']">YOUR<br/>LOGO</div>
//     <div className="w-[1127px] h-[94px] left-0 top-[126px] absolute text-[#ffeda0] text-[40px] font-normal font-['Kalam']">NEW ARRIVAL</div>
//     <div className="w-[1127px] h-[142px] left-0 top-[243px] absolute text-white text-6xl font-normal font-['Kalam']">Company Name</div>
//     <div className="w-[364px] h-[110px] p-3 left-0 top-[621px] absolute bg-gradient-to-b from-[#ffeda0] to-[#998e60] rounded-tl-[10px] rounded-tr-[15px] rounded-bl-[17px] rounded-br-[10px] justify-center items-center gap-2 inline-flex">
//         <div className="text-[#1e1e1e] text-base font-normal font-['Inter'] leading-none">Button</div>
//     </div>
//     <div className="w-[65px] h-[11px] left-0 top-[400px] absolute bg-[#ffeda0]" />
//     <div className="w-[1111px] h-40 left-0 top-[450px] absolute text-white text-[35px] font-normal font-['Kalam']">Product Name</div>
//     <div className="w-[1111px] h-[273px] left-0 top-[796px] absolute text-white text-xl font-normal font-['Kalam']">Website link</div>
//     <img className="w-[88px] h-[93px] object-cover left-0 top-0 absolute" src="image1" />
//     <img className="w-[729px] h-[700px] object-cover left-[1191px] top-[380px] absolute shadow-inner" src="image2" />
// </div>`,

//     },
//   ],
//   [
//     "d9a84f3c27e6b5084dbf61a9",
//     {
//       _id: "d9a84f3c27e6b5084dbf61a9",
//       numberofimages: 1,
//       numberoftextfields: 2,
//       category: "portrait",
//       type: "Instagram",
//       code: `<div className="w-[1080px] h-[1920px] relative bg-[#e9e5d6]">
//     <div className="w-[792px] h-[1527px] left-[144px] top-[159px] absolute bg-[#464e2e] rounded-tr-[200px] rounded-bl-[200px]" />
//     <div className="w-[696px] h-[167px] left-[168px] top-[345px] absolute text-[#e9e5d6] text-[64px] font-normal font-['Inter']">Company Name</div>
//     <div className="w-[372px] h-[332px] left-[168px] top-[540px] absolute text-[#e9e5d6] text-[40px] font-normal font-['Inter']">Product Name</div>
//     <div className="w-[500px] h-[500px] left-[62px] top-[900px] absolute bg-[#d9d9d9]" />
//     <img className="w-[500px] h-[551.22px] object-cover left-[62px] top-[900px] absolute" src="image1" />
//     <div className="w-[713px] h-[69px] left-[168px] top-[1479px] absolute text-white text-5xl font-normal font-['Inter']">Website link</div>
// </div>`,

//     },
//   ],
//   [
//     "4e7d8a9f3b5c1d20a6f94c5b",
//     {
//       _id: "4e7d8a9f3b5c1d20a6f94c5b",
//       numberofimages: 2,
//       numberoftextfields: 1,
//       category: "landscape",
//       type: "Facebook",
//       code: `<div className="w-[1920px] h-[1080px] relative bg-[#7e765b]">
//     <div className="w-[933px] h-[1080px] left-0 top-0 absolute bg-[#d9d9d9]" />
//     <img className="w-[933px] h-[1080px] object-cover left-0 top-0 absolute" src="image1" />
//     <div className="w-[987px] h-[202px] left-[933px] top-[192px] absolute text-center text-white text-[40px] font-normal font-['Inter']">Company Name</div>
//     <div className="w-[987px] h-[564px] left-[933px] top-[476px] absolute text-center text-white text-2xl font-normal font-['Inter']">Product Name</div>
// </div>`,

//     },
//   ],
//   [
//     "2f3d6a7b8c9e1f0a45b7d8c6",
//     {
//       _id: "2f3d6a7b8c9e1f0a45b7d8c6",
//       numberofimages: 2,
//       numberoftextfields: 3,
//       category: "square",
//       type: "Facebook",
//       code: `<div className="w-[1080px] h-[1080px] relative bg-[#a6a6a6]">
//     <div className="w-20 h-20 left-[108px] top-[108px] absolute bg-white" />
//     <div className="w-[188px] h-[122px] left-[108px] top-[235px] absolute text-[#2d2d2d] text-[40px] font-normal font-['Inter']">Company Name</div>
//     <div className="w-[510px] left-[472px] top-[865px] absolute text-[#2d2d2d] text-[40px] font-normal font-['Inter']">Product Name</div>
//     <div className="w-[274px] h-6 left-[590px] top-[951px] absolute bg-white" />
//     <div className="w-[510px] h-[200px] left-[472px] top-[603px] absolute text-center text-[#2d2d2d] text-[40px] font-normal font-['Inter']">Product Details</div>
//     <img className="w-[302px] h-[568px] object-cover left-[108px] top-[407px] absolute" src="image1" />
//     <img className="w-[524px] h-[403px] object-cover left-[465px] top-[94px] absolute" src="image2" />
// </div>`,

//     },
//   ],
//   [
//     "a1b7d3f9056c4e8a9f3c72d9",
//     {
//       _id: "a1b7d3f9056c4e8a9f3c72d9",
//       numberofimages: 1,
//       numberoftextfields: 4,
//       category: "portrait",
//       type: "Instagram",
//       code: `<div className="w-[1080px] h-[1920px] relative bg-[#004aad] shadow">
//     <div className="w-[777.01px] h-[0px] left-[302.99px] top-[263px] absolute border-2 border-[#e9f3f7]"></div>
//     <div className="w-[167px] h-[46px] left-[303px] top-[310px] absolute text-white text-[40px] font-black font-['Inter']">Company Name</div>
//     <div className="w-[167px] h-[46px] left-[540px] top-[310px] absolute text-white text-[40px] font-black font-['Inter']">Product Name</div>
//     <div className="w-[167px] h-[46px] left-[818px] top-[310px] absolute text-white text-[40px] font-black font-['Inter']">Product Details</div>
//     <div className="w-[790px] h-[380px] left-[112px] top-[538px] absolute text-white text-9xl font-normal font-['Alfa Slab One']">Hey <br/>Everyone!</div>
//     <img className="w-[873px] h-[551px] object-cover left-[103px] top-[918px] absolute rounded-[78px]" src="image1" />
//     <div className="left-[161px] top-[1623px] absolute text-white text-9xl font-normal font-['Alfa Slab One']">Website</div>
// </div>`,

//     },
//   ],
//   [
//     "f056a8c7b94e5d3f2a6b1c8d",
//     {
//       _id: "f056a8c7b94e5d3f2a6b1c8d",
//       numberofimages: 1,
//       numberoftextfields: 2,
//       category: "landscape",
//       type: "Facebook",
//       code: `<div className="w-[1920px] h-[1080px] relative bg-black/5">
//     <img className="w-[1270.55px] h-[524.69px] object-cover left-[326.96px] top-[109px] absolute rounded-[18px]" src="image1" />
//     <div className="w-[908.99px] h-[98.49px] left-[499.85px] top-[673.03px] absolute mix-blend-exclusion text-neutral-100 text-5xl font-bold font-['Inter']">Company Name</div>
//     <div className="w-[846px] h-[165px] left-[531.51px] top-[805.18px] absolute mix-blend-exclusion text-neutral-100 text-5xl font-bold font-['Inter']">Product Name</div>
// </div>`,

//     },
//   ],
//   [
//     "3c2a8e9d4b5f06a7f9d5b10c",
//     {
//       _id: "3c2a8e9d4b5f06a7f9d5b10c",
//       numberofimages: 1,
//       numberoftextfields: 4,
//       category: "portrait",
//       type: "Instagram",
//       code: `<div className="w-[1080px] h-[1920px] relative bg-gradient-to-l from-black to-black">
//     <img className="w-[1080px] h-[1920px] object-cover left-0 top-0 absolute rounded-[143px]" src="image1" />
//     <div className="w-[637px] h-[95px] left-[235px] top-[505px] absolute text-center text-white text-5xl font-normal font-['Inter']">Company Name</div>
//     <div className="w-[420px] h-[863px] left-[71px] top-[715px] absolute mix-blend-color-burn bg-[#d9d9d9] rounded-[52px]" />
//     <div className="w-[308px] h-[589px] left-[130px] top-[876px] absolute text-center text-white text-5xl font-normal font-['Inter']">Product Name</div>
//     <div className="w-[420px] h-[863px] left-[600px] top-[715px] absolute mix-blend-color-burn bg-[#d9d9d9] rounded-[52px]" />
//     <div className="w-[308px] h-[589px] left-[656px] top-[876px] absolute text-center text-white text-5xl font-normal font-['Inter']">Product Details</div>
//     <div className="w-[949px] h-[137px] left-[71px] top-[1742px] absolute text-center text-white text-[64px] font-normal font-['Inter']">Website</div>
// </div>`,

//     },
//   ],
//   [
//     "e7d4c6a3f9b5d8c2a1f504b9",
//     {
//       _id: "e7d4c6a3f9b5d8c2a1f504b9",
//       numberofimages: 1,
//       numberoftextfields: 2,
//       category: "portrait",
//       type: "Instagram",
//       code: `<div className="w-[1080px] h-[1920px] relative bg-white">
//     <div className="w-[730px] h-[407px] left-[154px] top-[1020px] absolute text-center text-black text-5xl font-normal font-['Inter']">Company Name</div>
//     <img className="w-[888px] h-[702px] object-cover left-[96px] top-[204px] absolute rounded-[86px]" src="image1" />
//     <div className="w-[730px] h-[313px] left-[154px] top-[1467px] absolute text-center text-black text-5xl font-normal font-['Inter']">Product Name</div>
// </div>`,

//     },
//   ],
//   [
//     "8f4d7c2a5b9e3f6a0d1c7b50",
//     {
//       _id: "8f4d7c2a5b9e3f6a0d1c7b50",
//       numberofimages: 1,
//       numberoftextfields: 2,
//       category: "landscape",
//       type: "Facebook",
//       code: `<div className="w-[1920px] h-[1080px] relative bg-[#7e765b]">
//     <div className="w-[933px] h-[1080px] left-0 top-0 absolute bg-[#d9d9d9]" />
//     <img className="w-[933px] h-[1080px] object-cover left-0 top-0 absolute" src="image1" />
//     <div className="w-[987px] h-[202px] left-[933px] top-[192px] absolute text-center text-white text-[40px] font-normal font-['Inter']">Company Name</div>
//     <div className="w-[987px] h-[564px] left-[933px] top-[476px] absolute text-center text-white text-2xl font-normal font-['Inter']">Product Name</div>
// </div>`,

//     },
//   ],
//   [
//     "f7a6b9c0d8e5f3c4b7a1d906",
//     {
//       _id: "f7a6b9c0d8e5f3c4b7a1d906",
//       numberofimages: 1,
//       numberoftextfields: 1,
//       category: "portrait",
//       type: "Instagram",
//       code: `<div className="w-[1080px] h-[1920px] pl-[133px] pr-[101px] pt-[236px] pb-[351px] bg-[#4d3f3f] shadow flex-col justify-start items-start gap-44 inline-flex">
//     <img className="w-[814px] h-[823px] object-cover" src="image1" />
//     <div className="w-[846px] h-[334px] text-center text-white text-[64px] font-black font-['Inter']">Company Name</div>
// </div>`,

//     },
//   ],
//   [
//     "c9f4a1b5e3d6c7f2a8b10d5e",
//     {
//       _id: "c9f4a1b5e3d6c7f2a8b10d5e",
//       numberofimages: 2,
//       numberoftextfields: 3,
//       category: "portrait",
//       type: "Instagram",
//       code: `<div className="w-[1080px] h-[1920px] relative bg-gradient-to-b from-[#9400ad] to-[#00470f] shadow">
//     <div className="w-[990px] h-[765px] left-[30px] top-[547px] absolute text-[#e4e2dd] text-[320px] font-normal font-['Inter'] leading-[200px]">about<br/>me.</div>
//     <img className="w-[562px] h-[369px] object-cover left-[518px] top-[963px] absolute rounded-[119px]" src="image1" />
//     <img className="w-[441px] h-[296px] object-cover left-[30px] top-[14px] absolute rounded-[119px]" src="image2" />
//     <div className="w-[890px] h-[107px] left-[34px] top-[1419px] absolute text-[#e4e2dd] text-[40px] font-normal font-['Inter']">Company Name</div>
//     <div className="w-[875px] h-[347px] left-[34px] top-[1526px] absolute text-[#e4e2dd] text-[40px] font-normal font-['Inter']">Product Name</div>
//     <div className="w-[419px] h-[184px] left-[628px] top-[162px] absolute text-[#e4e2dd] text-[40px] font-normal font-['Inter']">Product Details</div>
// </div>`,

//     },
//   ],
//   [
//     "2a9f5b7d6e4c8f0a3d1b6079",
//     {
//       _id: "2a9f5b7d6e4c8f0a3d1b6079",
//       numberofimages: 1,
//       numberoftextfields: 3,
//       category: "portrait",
//       type: "Instagram",
//       code: `<div className="w-[1080px] h-[1920px] relative bg-gradient-to-b from-[#732f24] to-[#a85b42]">
//     <div className="w-[1000px] h-[1000px] left-[40px] top-[880px] absolute bg-[#cbc6c2]" />
//     <div className="w-[888px] h-[834px] left-[96px] top-[929px] absolute bg-[#d9d9d9]" />
//     <img className="w-[888px] h-[834px] object-cover left-[96px] top-[929px] absolute" src="image1" />
//     <div className="w-[888px] left-[96px] top-[232px] absolute text-center text-[#cbc6c2] text-[64px] font-normal font-['Inter']">Company Name</div>
//     <div className="w-[888px] left-[96px] top-[1783px] absolute text-center text-[#150905] text-4xl font-normal font-['Inter']">Product Name</div>
//     <div className="w-[888px] h-[331px] left-[96px] top-[429px] absolute text-center text-white text-4xl font-normal font-['Inter']">Product Details</div>
// </div>`,

//     },
//   ],
//   [
//     "d3b7f4e2a1c9f8a06d5b40e7",
//     {
//       _id: "d3b7f4e2a1c9f8a06d5b40e7",
//       numberofimages: 1,
//       numberoftextfields: 4,
//       category: "square",
//       type: "Facebook",
//       code: `<div className="w-[1080px] h-[1080px] relative bg-white shadow">
//     <img className="w-[1080px] h-[1080px] object-cover left-0 top-0 absolute" src="image1" />
//     <div className="w-[534px] h-[270px] left-[273px] top-[405px] absolute text-center text-[#e4c053] text-[64px] font-normal font-['Inter']">Company Name</div>
//     <div className="w-[534px] left-[273px] top-[77px] absolute text-center text-[#e4c053] text-[32px] font-normal font-['Inter']">Product Name</div>
//     <div className="w-[66px] h-[151px] left-[61px] top-[464px] absolute text-center text-[#e4c053] text-[32px] font-normal font-['Inter']">Product Details</div>
//     <div className="w-[66px] h-[151px] left-[972px] top-[464px] absolute text-center text-[#e4c053] text-[32px] font-normal font-['Inter']">Website</div>
//     <div className="w-[534px] h-[195px] left-[273px] top-[753px] absolute text-center text-[#e4c053] text-[32px] font-normal font-['Inter']">uefw</div>
// </div>`,

//     },
//   ],
//   [
//     "4f1a9e3b7c5d8a0f6b20c8e3",
//     {
//       _id: "4f1a9e3b7c5d8a0f6b20c8e3",
//       numberofimages: 1,
//       numberoftextfields: 4,
//       category: "portrait",
//       type: "Instagram",
//       code: `<div className="w-[1024px] h-[1366px] relative bg-[#faefe0]">
//     <img className="w-[1024px] h-[799px] object-cover left-0 top-0 absolute" src="image1" />
//     <div className="w-[465px] left-[38px] top-[148px] absolute text-white text-4xl font-normal font-['Inter']">Website</div>
//     <div className="w-[1024px] h-[129px] left-0 top-[799px] absolute bg-[#c49963] border-2 border-black" />
//     <div className="w-[438.01px] h-[0px] left-[589.50px] top-[927.99px] absolute origin-top-left rotate-90 border-2 border-black"></div>
//     <div className="w-[943px] left-[38px] top-[835px] absolute text-white text-5xl font-normal font-['Inter']">Product Name</div>
//     <div className="w-[508px] h-[359px] left-[38px] top-[964px] absolute text-black text-8xl font-normal font-['Inter']">Company Name </div>
//     <div className="w-[345px] left-[636px] top-[1057px] absolute text-center text-black text-4xl font-normal font-['Inter']">Product Details</div>
//     <div className="w-[345px] left-[636px] top-[1206px] absolute text-center text-black text-4xl font-normal font-['Inter']">Website</div>
// </div>`,

//     },
//   ],
//   [
//     "b8d7f5e0c2a1f9d3a4b5c69e",
//     {
//       _id: "b8d7f5e0c2a1f9d3a4b5c69e",
//       numberofimages: 1,
//       numberoftextfields: 3,
//       category: "portrait",
//       type: "Instagram",
//       code: `<div className="w-[1024px] h-[1366px] relative bg-[#292929]">
//     <div className="w-[823px] h-[478px] left-[101px] top-[444px] absolute text-[#e4e2dd] text-[320px] font-normal font-['Inter'] leading-[200px]">about<br/>me.</div>
//     <img className="w-[359px] h-[214px] object-cover left-[631px] top-[683px] absolute rounded-[75px]" src="image1" />
//     <div className="w-[744px] left-[101px] top-[1011px] absolute text-[#e4e2dd] text-[40px] font-normal font-['Inter']">Product Details</div>
//     <div className="w-[744px] h-[250px] left-[101px] top-[1088px] absolute text-[#e4e2dd] text-[40px] font-normal font-['Inter']">Company Name</div>
//     <div className="w-[372px] h-[49px] left-[101px] top-[28px] absolute text-[#e4e2dd] text-[40px] font-normal font-['Inter']">Product Name</div>
// </div>`,

//     },
//   ],
//   [
//     "d9f3a5b1e7c8d2f0b6a4c70d",
//     {
//       _id: "d9f3a5b1e7c8d2f0b6a4c70d",
//       numberofimages: 1,
//       numberoftextfields: 3,
//       category: "square",
//       type: "Facebook",
//       code: `<div className="w-[1080px] h-[1080px] relative bg-[#384d3b]">
//     <img className="w-[540px] h-[1080px] object-cover left-0 top-0 absolute" src="image1" />
//     <div className="w-[810px] h-[630px] left-[270px] top-[334px] absolute bg-[#f6f3ec]" />
//     <div className="w-[536px] h-[50px] left-[367px] top-[874px] absolute text-black text-[32px] font-normal font-['Inter']">Company Name</div>
//     <div className="w-[50px] h-[0px] left-[341.88px] top-[820.51px] absolute border-2 border-[#373636]"></div>
//     <div className="w-[100px] h-[0px] left-[367px] top-[425px] absolute border-2 border-[#373636]"></div>
//     <div className="left-[525px] top-[352px] absolute text-[#2b2a2a] text-9xl font-normal font-['Island Moments']">NEW</div>
//     <div className="left-[367px] top-[466px] absolute text-[#2b2a2a] text-9xl font-normal font-['Island Moments']">ARRIVAL</div>
//     <div className="w-[629px] h-[169px] left-[367px] top-[629px] absolute text-black text-[32px] font-normal font-['Inter']">Product Name</div>
//     <div className="w-[333px] h-[135px] left-[717px] top-[87px] absolute text-right text-white text-[32px] font-normal font-['Inter']">Product Details</div>
// </div>`,

//     },
//   ],
//   [
//     "7a8b4f2e5d1c0f9a6b3d8c51",
//     {
//       _id: "7a8b4f2e5d1c0f9a6b3d8c51",
//       numberofimages: 1,
//       numberoftextfields: 3,
//       category: "square",
//       type: "Facebook",
//       code: `<div className="w-[1080px] h-[1080px] relative bg-[#dbddda]">
//     <img className="w-[972px] h-[514px] object-cover left-[108px] top-[180px] absolute" src="image1" />
//     <div className="w-[944px] h-[326px] left-[108px] top-[720px] absolute text-[#062424] text-8xl font-normal font-['Inter']">Company Name</div>
//     <div className="w-[302px] h-[108px] left-[108px] top-[60px] absolute text-[#062424] text-[32px] font-normal font-['Inter']">Product Name</div>
//     <div className="w-[548px] h-[108px] left-[504px] top-[60px] absolute text-[#062424] text-[32px] font-normal font-['Inter']">Product Details</div>
// </div>`,

//     },
//   ],
//   [
//     "5d6e9a2f3c8b0a7f1d4c5b6e",
//     {
//       _id: "5d6e9a2f3c8b0a7f1d4c5b6e",
//       numberofimages: 1,
//       numberoftextfields: 2,
//       category: "square",
//       type: "Facebook",
//       code: `<div className="w-[1080px] h-[1080px] relative bg-[#f9f9f9]">
//     <img className="w-[632px] object-cover h-[870px] left-[448px] top-0 absolute" src="image1" />
//     <div className="w-[894px] h-[0px] left-[422px] top-0 absolute origin-top-left rotate-90 border-2 border-[#9c9181]"></div>
//     <div className="w-[658px] h-[0px] left-[422px] top-[894px] absolute border-2 border-[#9c9181]"></div>
//     <div className="left-[66px] top-[268px] absolute text-black text-8xl font-normal font-['Instrument Serif']">NEW</div>
//     <div className="left-[100px] top-[393px] absolute text-black text-8xl font-normal font-['Mrs Saint Delafield']">RELEASE</div>
//     <div className="w-[318px] h-[146px] left-[65px] top-[652px] absolute bg-black" />
//     <div className="w-[232px] h-[94px] left-[104px] top-[680px] absolute text-white text-[32px] font-normal font-['Inter']">Company Name</div>
//     <div className="w-[232px] h-[63px] left-[108px] top-[817px] absolute text-black text-[32px] font-normal font-['Inter']">Product Name</div>
// </div>`,

//     },
//   ],
//   [
//     "5d6e9a2f3c8b0a7f1d4c5b23",
//     {
//       _id: "5d6e9a2f3c8b0a7f1d4c5b23",
//       numberofimages: 1,
//       numberoftextfields: 3,
//       category: "landscape",
//       type: "Facebook",
//       code: `<div className="w-[1920px] h-[1080px] relative bg-[#2a2e32]">
//     <img className="w-[856px] h-[1080px] left-[1064px] top-0 absolute object-cover" src="image1" />
//     <div className="w-[230px] h-[0px] left-[520px] top-0 absolute origin-top-left rotate-90 border border-white"></div>
//     <div className="w-[230px] h-[0px] left-[520px] top-[850px] absolute origin-top-left rotate-90 border border-white"></div>
//     <div className="w-[752px] h-[180px] left-[142px] top-[290px] absolute text-center text-white text-[64px] font-normal font-['Inter']">Company Name</div>
//     <div className="w-[752px] h-[76px] left-[142px] top-[512px] absolute text-center text-[#f46956] text-[64px] font-normal font-['Inspiration']">Product Name</div>
//     <div className="w-[752px] h-[70px] left-[142px] top-[730px] absolute bg-[#f46956]" />
//     <div className="w-[752px] left-[142px] top-[741px] absolute text-center text-white text-[40px] font-normal font-['Inter']">Product Info</div>
// </div>`,

//     },
//   ],
//   [
//     "5d6e9a2f3c8b0a7f1d4c5b24",
//     {
//       _id: "5d6e9a2f3c8b0a7f1d4c5b24",
//       numberofimages: 3,
//       numberoftextfields: 5,
//       category: "landscape",
//       type: "Facebook",
//       code: `<div className="w-[1920px] h-[1080px] relative bg-[#eeeee2]">
//     <div className="w-[1080px] h-[0px] left-[1800px] top-0 absolute origin-top-left rotate-90 border border-black"></div>
//     <div className="w-[1680px] h-[0px] left-[120px] top-[169px] absolute border border-black"></div>
//     <div className="w-[1680px] h-[0px] left-[120px] top-[958px] absolute border border-black"></div>
//     <div className="w-[1080px] h-[0px] left-[120px] top-0 absolute origin-top-left rotate-90 border border-black"></div>
//     <div className="w-[788px] h-[0px] left-[876px] top-[170px] absolute origin-top-left rotate-90 border border-black"></div>
//     <div className="w-[756px] h-[0px] left-[119.99px] top-[279.50px] absolute border border-black"></div>
//     <img className="w-[388px] h-[316px] left-[1412px] top-[170px] absolute object-cover" src="image1" />
//     <img className="w-[388px] h-[472px] left-[1412px] top-[486px] absolute object-cover" src="image2" />
//     <img className="w-[536px] h-[788px] left-[876px] top-[170px] absolute object-cover" src="image3" />
//     <div className="w-[938px] left-[474px] top-[25px] absolute text-center text-black text-8xl font-normal font-['Island Moments']">Company Name</div>
//     <div className="left-[474px] top-[414px] absolute text-black text-8xl font-normal font-['Island Moments']">NEW</div>
//     <div className="left-[391px] top-[524px] absolute text-black text-9xl font-normal font-['Italiana']">ARRIVAL</div>
//     <div className="w-[681px] left-[151px] top-[205px] absolute text-black text-[32px] font-normal font-['Italiana']">Product Name</div>
//     <div className="w-[681px] left-[38px] top-[865px] absolute origin-top-left -rotate-90 text-center text-black text-[32px] font-normal font-['Italiana']">Product Info</div>
//     <div className="w-[1577px] left-[172px] top-[992px] absolute text-center text-black text-[32px] font-normal font-['Italiana']">Website</div>
// </div>`,

//     },
//   ],
//   [
//     "5d6e9a2f3c8b0a0f1d4c5b25",
//     {
//       _id: "5d6e9a2f3c8b0a0f1d4c5b25",
//       numberofimages: 2,
//       numberoftextfields: 3,
//       category: "landscape",
//       type: "Facebook",
//       code: `<div className="w-[1920px] h-[1080px] relative bg-[#473926]">
//     <img className="w-[800px] h-[658.20px] left-[960px] top-[90px] absolute object-cover" src="image1" />
//     <img className="w-[500px] h-[411.37px] left-[710px] top-[542px] absolute object-cover" src="image2" />
//     <div className="w-[636px] left-[194px] top-[252px] absolute text-white text-[64px] font-normal font-['Italiana']">Product Name</div>
//     <div className="w-[431px] h-32 left-[194px] top-[553px] absolute text-white text-4xl font-normal font-['Italiana']">Product Info</div>
//     <div className="w-[995px] left-[194px] top-[342px] absolute text-white text-8xl font-normal font-['Italiana']">Company Name</div>
// </div>`,

//     },
//   ],
//   [
//     "5d6e9aaf3c8b0a0f1d4c5b26",
//     {
//       _id: "5d6e9aaf3c8b0a0f1d4c5b26",
//       numberofimages: 2,
//       numberoftextfields: 3,
//       category: "portrait",
//       type: "Instagram",
//       code: `<div className="w-[1080px] h-[1920px] relative bg-white">
//     <img className="w-[1098px] h-[1920px] left-[-9px] top-0 absolute object-cover" src="image1" />
//     <img className="w-[363px] h-[214px] left-[381px] top-[268px] absolute mix-blend-multiply object-cover" src="image2" />
//     <div className="w-[368px] h-[131px] left-[376px] top-[554px] absolute text-center text-white text-8xl font-normal font-['Yellowtail']">Yes !</div>
//     <div className="w-[893px] left-[101px] top-[718px] absolute text-center text-white text-8xl font-normal font-['Trade Winds']">We Are<br/> Open </div>
//     <div className="w-[568px] h-[0px] left-[244px] top-[1025px] absolute border-2 border-white"></div>
//     <div className="w-[577px] h-[252px] left-[235px] top-[1049px] absolute bg-[#d9d9d9]/0" />
//     <div className="w-[550px] h-[252px] left-[262px] top-[1049px] absolute text-center text-white text-8xl font-normal font-['Trade Winds']">Company Name</div>
//     <div className="w-[400px] h-[122px] left-[28px] top-[1763px] absolute text-center text-white text-2xl font-normal font-['Trade Winds']">Product Name</div>
//     <div className="w-[383px] h-[147px] left-[673px] top-[1756px] absolute text-center text-white text-2xl font-normal font-['Trade Winds']">Product Info</div>
// </div>`,

//     },
//   ],
//   [
//     "5d6e9aaf3c8b0l0f1d4c5b27",
//     {
//       _id: "5d6e9aaf3c8b0l0f1d4c5b27",
//       numberofimages: 1,
//       numberoftextfields: 1,
//       category: "portrait",
//       type: "Instagram",
//       code: `<div className="w-[1080px] h-[1920px] relative bg-white">
//     <img className="w-[1080px] h-[1920px] left-0 top-0 absolute object-cover" src="image1" />
//     <div className="left-[391px] top-[212px] absolute text-white text-9xl font-normal font-['Inspiration']">Delicious</div>
//     <div className="left-[262px] top-[423px] absolute text-[#ff8938] text-9xl font-normal font-['Instrument Serif']">FOOD MENU</div>
//     <div className="w-[651px] h-[265px] left-[215px] top-[807px] absolute text-center text-white text-[64px] font-normal font-['Instrument Serif']">Company Name</div>
// </div>`,

//     },
//   ],
//   [
//     "5d6e9aaf3c8b0l0f1d495b28",
//     {
//       _id: "5d6e9aaf3c8b0l0f1d495b28",
//       numberofimages: 2,
//       numberoftextfields: 3,
//       category: "portrait",
//       type: "Instagram",
//       code: `<div className="w-[1080px] h-[1920px] relative bg-gradient-to-b from-[#1c110d] to-[#8a4c35] shadow">
//     <div className="w-[907px] h-[114px] left-[79px] top-[1707px] absolute text-center text-white text-xl font-black font-['Inter']">Product Info</div>
//     <div className="w-[736px] h-80 left-[195px] top-[141px] absolute text-center text-white text-9xl font-black font-['Inter']">Company Name</div>
//     <div className="w-[754px] h-80 left-[195px] top-[441px] absolute text-center text-white text-5xl font-black font-['Inter']">Product Name</div>
//     <img className="w-[820px] h-[700px] left-[166px] top-[761px] absolute object-cover" src="image1" />
//     <img className="w-[522px] h-[423px] left-[140px] top-[1153px] absolute object-cover" src="image2" />
// </div>`,

//     },
//   ],
// ]);

// export const id = ["650ff9a1f443f9056dbd45b3", "650ff9a1f443f9056dbd45b1", "f7a3c1d9b56e8a4073fc9b5d", "b5d67c8e1a3b9d2f50f4c7a6", "d9a84f3c27e6b5084dbf61a9", "4e7d8a9f3b5c1d20a6f94c5b", "2f3d6a7b8c9e1f0a45b7d8c6", "a1b7d3f9056c4e8a9f3c72d9", "f056a8c7b94e5d3f2a6b1c8d", "3c2a8e9d4b5f06a7f9d5b10c", "e7d4c6a3f9b5d8c2a1f504b9", "8f4d7c2a5b9e3f6a0d1c7b50", "f7a6b9c0d8e5f3c4b7a1d906", "c9f4a1b5e3d6c7f2a8b10d5e", "2a9f5b7d6e4c8f0a3d1b6079", "d3b7f4e2a1c9f8a06d5b40e7", "4f1a9e3b7c5d8a0f6b20c8e3", "b8d7f5e0c2a1f9d3a4b5c69e", "d9f3a5b1e7c8d2f0b6a4c70d", "7a8b4f2e5d1c0f9a6b3d8c51", "5d6e9a2f3c8b0a7f1d4c5b6e", "5d6e9a2f3c8b0a7f1d4c5b23", "5d6e9a2f3c8b0a7f1d4c5b24", "5d6e9a2f3c8b0a0f1d4c5b25", "5d6e9aaf3c8b0a0f1d4c5b26", "5d6e9aaf3c8b0l0f1d4c5b27", "5d6e9aaf3c8b0l0f1d495b28"];


export const temp = new Map([
  [
    "650ff9a1f443f9056dbd45b3",
    {
      _id: "650ff9a1f443f9056dbd45b3",
      numberofimages: 1,
      numberoftextfields: 2,
      category: "portrait",
      type: "Facebook",
      code: `<div className="w-[1080px] h-[1920px] relative bg-[#4a90e2]">
        <div className="w-[900px] h-[1798px] left-[90px] top-[61px] absolute bg-[#d9d9d9] rounded-[100px]" />
        <div className="w-[261.20px] h-[0px] left-[110px] top-[104px] absolute origin-top-left rotate-[58.62deg] border-4 border-[#d4af37]"></div>
        <div className="w-[572.32px] h-[0px] left-[110px] top-[104px] absolute origin-top-left rotate-[58.62deg] border-4 border-[#d4af37]"></div>
        <div className="w-[1240.47px] h-[0px] left-[396px] top-[572px] absolute origin-top-left rotate-[61.39deg] border-4 border-[#d4af37]"></div>
        <img className="w-[572.53px] object-cover h-[500px] left-[246px] top-[348px] absolute rounded-[100px]" src="image1" />
        <div className="w-[418px] h-[976px] left-[114px] top-[851px] absolute text-black text-8xl font-normal font-['Inter']">Company Name</div>
        <div className="w-[483px] h-[255px] left-[276px] top-[93px] absolute text-center text-black text-8xl font-normal font-['Inter']">Product Name</div>
    </div>`,
    },
  ],
  [
    "650ff9a1f443f9056dbd45b1",
    {
      _id: "650ff9a1f443f9056dbd45b1",
      numberofimages: 1,
      numberoftextfields: 2,
      category: "landscape",
      type: "Instagram",
      code: `<div className="w-[1920px] h-[1080px] relative bg-[#1f1d1d]">
    <div className="w-[1920px] h-36 left-0 top-[606px] absolute text-center text-[#dddddd]/90 text-[64px] font-normal font-['Inter']">Company Name</div>
    <div className="w-[1920px] h-[267px] left-0 top-[823px] absolute text-center text-[#b58d48]/90 text-[35px] font-normal font-['Inter']">Product Name</div>
    <div className="w-[1277px] h-1 left-[308px] top-[560px] absolute justify-center items-center inline-flex">
    <div className="w-[1277.01px] h-[0px] border-2 border-[#b58d48]"></div>
    </div>
    <img className="w-[497px] h-[484px] object-cover left-[698px] top-[23px] absolute rounded-[76px]" src="image1" />
</div>`,

    },
  ],
  [
    "f7a3c1d9b56e8a4073fc9b5d",
    {
      _id: "f7a3c1d9b56e8a4073fc9b5d",
      numberofimages: 1,
      numberoftextfields: 2,
      category: "landscape",
      type: "Instagram",
      code: `<div className="w-[1920px] h-[1080px] relative bg-[#38374d] shadow">
    <img className="w-[443.05px] h-[450px] object-cover left-[4px] top-[11px] absolute rounded-[174.50px]" src="image1" />
    <div className="w-[1357px] h-[625px] left-[563px] top-[55px] absolute text-[#f0be72] text-8xl font-normal font-['Inter']">Company Name</div>
    <div className="w-[1875px] h-[133px] left-[26px] top-[925px] absolute text-[#f0be72] text-2xl font-normal font-['Inter']">Product Name</div>
</div>`,

    },
  ],
  [
    "b5d67c8e1a3b9d2f50f4c7a6",
    {
      _id: "b5d67c8e1a3b9d2f50f4c7a6",
      numberofimages: 2,
      numberoftextfields: 2,
      category: "landscape",
      type: "Instagram",
      code: `<div className="w-[1920px] h-[1080px] relative bg-[#80848b]">
    <div className="w-[86px] h-[93px] left-[108px] top-0 absolute text-white text-xl font-normal font-['Kalam']">YOUR<br/>LOGO</div>
    <div className="w-[1127px] h-[94px] left-0 top-[126px] absolute text-[#ffeda0] text-[40px] font-normal font-['Kalam']">NEW ARRIVAL</div>
    <div className="w-[1127px] h-[142px] left-0 top-[243px] absolute text-white text-6xl font-normal font-['Kalam']">Company Name</div>
    <div className="w-[364px] h-[110px] p-3 left-0 top-[621px] absolute bg-gradient-to-b from-[#ffeda0] to-[#998e60] rounded-tl-[10px] rounded-tr-[15px] rounded-bl-[17px] rounded-br-[10px] justify-center items-center gap-2 inline-flex">
        <div className="text-[#1e1e1e] text-base font-normal font-['Inter'] leading-none">Button</div>
    </div>
    <div className="w-[65px] h-[11px] left-0 top-[400px] absolute bg-[#ffeda0]" />
    <div className="w-[1111px] h-40 left-0 top-[450px] absolute text-white text-[35px] font-normal font-['Kalam']">Product Name</div>
    <div className="w-[1111px] h-[273px] left-0 top-[796px] absolute text-white text-xl font-normal font-['Kalam']">Website</div>
    <img className="w-[88px] h-[93px] object-cover left-0 top-0 absolute" src="image1" />
    <img className="w-[729px] h-[700px] object-cover left-[1191px] top-[380px] absolute shadow-inner" src="image2" />
</div>`,

    },
  ],
  [
    "d9a84f3c27e6b5084dbf61a9",
    {
      _id: "d9a84f3c27e6b5084dbf61a9",
      numberofimages: 1,
      numberoftextfields: 2,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-[#e9e5d6]">
    <div className="w-[792px] h-[1527px] left-[144px] top-[159px] absolute bg-[#464e2e] rounded-tr-[200px] rounded-bl-[200px]" />
    <div className="w-[696px] h-[167px] left-[168px] top-[345px] absolute text-[#e9e5d6] text-[64px] font-normal font-['Inter']">Company Name</div>
    <div className="w-[372px] h-[332px] left-[168px] top-[540px] absolute text-[#e9e5d6] text-[40px] font-normal font-['Inter']">Product Name</div>
    <div className="w-[500px] h-[500px] left-[62px] top-[900px] absolute bg-[#d9d9d9]" />
    <img className="w-[500px] h-[551.22px] object-cover left-[62px] top-[900px] absolute" src="image1" />
    <div className="w-[713px] h-[69px] left-[168px] top-[1479px] absolute text-white text-5xl font-normal font-['Inter']">Website</div>
</div>`,

    },
  ],
  [
    "4e7d8a9f3b5c1d20a6f94c5b",
    {
      _id: "4e7d8a9f3b5c1d20a6f94c5b",
      numberofimages: 2,
      numberoftextfields: 1,
      category: "landscape",
      type: "Facebook",
      code: `<div className="w-[1920px] h-[1080px] relative bg-[#7e765b]">
    <div className="w-[933px] h-[1080px] left-0 top-0 absolute bg-[#d9d9d9]" />
    <img className="w-[933px] h-[1080px] object-cover left-0 top-0 absolute" src="image1" />
    <div className="w-[987px] h-[202px] left-[933px] top-[192px] absolute text-center text-white text-[40px] font-normal font-['Inter']">Company Name</div>
    <div className="w-[987px] h-[564px] left-[933px] top-[476px] absolute text-center text-white text-2xl font-normal font-['Inter']">Product Name</div>
</div>`,

    },
  ],
  [
    "2f3d6a7b8c9e1f0a45b7d8c6",
    {
      _id: "2f3d6a7b8c9e1f0a45b7d8c6",
      numberofimages: 2,
      numberoftextfields: 3,
      category: "square",
      type: "Facebook",
      code: `<div className="w-[1080px] h-[1080px] relative bg-[#a6a6a6]">
    <div className="w-20 h-20 left-[108px] top-[108px] absolute bg-white" />
    <div className="w-[188px] h-[122px] left-[108px] top-[235px] absolute text-[#2d2d2d] text-[40px] font-normal font-['Inter']">Company Name</div>
    <div className="w-[510px] left-[472px] top-[865px] absolute text-[#2d2d2d] text-[40px] font-normal font-['Inter']">Product Name</div>
    <div className="w-[274px] h-6 left-[590px] top-[951px] absolute bg-white" />
    <div className="w-[510px] h-[200px] left-[472px] top-[603px] absolute text-center text-[#2d2d2d] text-[40px] font-normal font-['Inter']">Product Details</div>
    <img className="w-[302px] h-[568px] object-cover left-[108px] top-[407px] absolute" src="image1" />
    <img className="w-[524px] h-[403px] object-cover left-[465px] top-[94px] absolute" src="image2" />
</div>`,

    },
  ],
  [
    "a1b7d3f9056c4e8a9f3c72d9",
    {
      _id: "a1b7d3f9056c4e8a9f3c72d9",
      numberofimages: 1,
      numberoftextfields: 4,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-[#004aad] shadow">
    <div className="w-[777.01px] h-[0px] left-[302.99px] top-[263px] absolute border-2 border-[#e9f3f7]"></div>
    <div className="w-[167px] h-[46px] left-[303px] top-[310px] absolute text-white text-[40px] font-black font-['Inter']">Company Name</div>
    <div className="w-[167px] h-[46px] left-[540px] top-[310px] absolute text-white text-[40px] font-black font-['Inter']">Product Name</div>
    <div className="w-[167px] h-[46px] left-[818px] top-[310px] absolute text-white text-[40px] font-black font-['Inter']">Product Details</div>
    <div className="w-[790px] h-[380px] left-[112px] top-[538px] absolute text-white text-9xl font-normal font-['Alfa Slab One']">Hey <br/>Everyone!</div>
    <img className="w-[873px] h-[551px] object-cover left-[103px] top-[918px] absolute rounded-[78px]" src="image1" />
    <div className="left-[161px] top-[1623px] absolute text-white text-9xl font-normal font-['Alfa Slab One']">Website</div>
</div>`,

    },
  ],
  [
    "f056a8c7b94e5d3f2a6b1c8d",
    {
      _id: "f056a8c7b94e5d3f2a6b1c8d",
      numberofimages: 1,
      numberoftextfields: 2,
      category: "landscape",
      type: "Facebook",
      code: `<div className="w-[1920px] h-[1080px] relative bg-black/5">
    <img className="w-[1270.55px] h-[524.69px] object-cover left-[326.96px] top-[109px] absolute rounded-[18px]" src="image1" />
    <div className="w-[908.99px] h-[98.49px] left-[499.85px] top-[673.03px] absolute mix-blend-exclusion text-neutral-100 text-5xl font-bold font-['Inter']">Company Name</div>
    <div className="w-[846px] h-[165px] left-[531.51px] top-[805.18px] absolute mix-blend-exclusion text-neutral-100 text-5xl font-bold font-['Inter']">Product Name</div>
</div>`,

    },
  ],
  [
    "3c2a8e9d4b5f06a7f9d5b10c",
    {
      _id: "3c2a8e9d4b5f06a7f9d5b10c",
      numberofimages: 1,
      numberoftextfields: 4,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-gradient-to-l from-black to-black">
    <img className="w-[1080px] h-[1920px] object-cover left-0 top-0 absolute rounded-[143px]" src="image1" />
    <div className="w-[637px] h-[95px] left-[235px] top-[505px] absolute text-center text-white text-5xl font-normal font-['Inter']">Company Name</div>
    <div className="w-[420px] h-[863px] left-[71px] top-[715px] absolute mix-blend-color-burn bg-[#d9d9d9] rounded-[52px]" />
    <div className="w-[308px] h-[589px] left-[130px] top-[876px] absolute text-center text-white text-5xl font-normal font-['Inter']">Product Name</div>
    <div className="w-[420px] h-[863px] left-[600px] top-[715px] absolute mix-blend-color-burn bg-[#d9d9d9] rounded-[52px]" />
    <div className="w-[308px] h-[589px] left-[656px] top-[876px] absolute text-center text-white text-5xl font-normal font-['Inter']">Product Details</div>
    <div className="w-[949px] h-[137px] left-[71px] top-[1742px] absolute text-center text-white text-[64px] font-normal font-['Inter']">Website</div>
</div>`,

    },
  ],
  [
    "e7d4c6a3f9b5d8c2a1f504b9",
    {
      _id: "e7d4c6a3f9b5d8c2a1f504b9",
      numberofimages: 1,
      numberoftextfields: 2,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-white">
    <div className="w-[730px] h-[407px] left-[154px] top-[1020px] absolute text-center text-black text-5xl font-normal font-['Inter']">Company Name</div>
    <img className="w-[888px] h-[702px] object-cover left-[96px] top-[204px] absolute rounded-[86px]" src="image1" />
    <div className="w-[730px] h-[313px] left-[154px] top-[1467px] absolute text-center text-black text-5xl font-normal font-['Inter']">Product Name</div>
</div>`,

    },
  ],
  [
    "8f4d7c2a5b9e3f6a0d1c7b50",
    {
      _id: "8f4d7c2a5b9e3f6a0d1c7b50",
      numberofimages: 1,
      numberoftextfields: 2,
      category: "landscape",
      type: "Facebook",
      code: `<div className="w-[1920px] h-[1080px] relative bg-[#7e765b]">
    <div className="w-[933px] h-[1080px] left-0 top-0 absolute bg-[#d9d9d9]" />
    <img className="w-[933px] h-[1080px] object-cover left-0 top-0 absolute" src="image1" />
    <div className="w-[987px] h-[202px] left-[933px] top-[192px] absolute text-center text-white text-[40px] font-normal font-['Inter']">Company Name</div>
    <div className="w-[987px] h-[564px] left-[933px] top-[476px] absolute text-center text-white text-2xl font-normal font-['Inter']">Product Name</div>
</div>`,

    },
  ],
  [
    "f7a6b9c0d8e5f3c4b7a1d906",
    {
      _id: "f7a6b9c0d8e5f3c4b7a1d906",
      numberofimages: 1,
      numberoftextfields: 1,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] pl-[133px] pr-[101px] pt-[236px] pb-[351px] bg-[#4d3f3f] shadow flex-col justify-start items-start gap-44 inline-flex">
    <img className="w-[814px] h-[823px] object-cover" src="image1" />
    <div className="w-[846px] h-[334px] text-center text-white text-[64px] font-black font-['Inter']">Company Name</div>
</div>`,

    },
  ],
  [
    "c9f4a1b5e3d6c7f2a8b10d5e",
    {
      _id: "c9f4a1b5e3d6c7f2a8b10d5e",
      numberofimages: 2,
      numberoftextfields: 3,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-gradient-to-b from-[#9400ad] to-[#00470f] shadow">
    <div className="w-[990px] h-[765px] left-[30px] top-[547px] absolute text-[#e4e2dd] text-[320px] font-normal font-['Inter'] leading-[200px]">about<br/>me.</div>
    <img className="w-[562px] h-[369px] object-cover left-[518px] top-[963px] absolute rounded-[119px]" src="image1" />
    <img className="w-[441px] h-[296px] object-cover left-[30px] top-[14px] absolute rounded-[119px]" src="image2" />
    <div className="w-[890px] h-[107px] left-[34px] top-[1419px] absolute text-[#e4e2dd] text-[40px] font-normal font-['Inter']">Company Name</div>
    <div className="w-[875px] h-[347px] left-[34px] top-[1526px] absolute text-[#e4e2dd] text-[40px] font-normal font-['Inter']">Product Name</div>
    <div className="w-[419px] h-[184px] left-[628px] top-[162px] absolute text-[#e4e2dd] text-[40px] font-normal font-['Inter']">Product Details</div>
</div>`,

    },
  ],
  [
    "2a9f5b7d6e4c8f0a3d1b6079",
    {
      _id: "2a9f5b7d6e4c8f0a3d1b6079",
      numberofimages: 1,
      numberoftextfields: 3,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-gradient-to-b from-[#732f24] to-[#a85b42]">
    <div className="w-[1000px] h-[1000px] left-[40px] top-[880px] absolute bg-[#cbc6c2]" />
    <div className="w-[888px] h-[834px] left-[96px] top-[929px] absolute bg-[#d9d9d9]" />
    <img className="w-[888px] h-[834px] object-cover left-[96px] top-[929px] absolute" src="image1" />
    <div className="w-[888px] left-[96px] top-[232px] absolute text-center text-[#cbc6c2] text-[64px] font-normal font-['Inter']">Company Name</div>
    <div className="w-[888px] left-[96px] top-[1783px] absolute text-center text-[#150905] text-4xl font-normal font-['Inter']">Product Name</div>
    <div className="w-[888px] h-[331px] left-[96px] top-[429px] absolute text-center text-white text-4xl font-normal font-['Inter']">Product Details</div>
</div>`,

    },
  ],
  [
    "d3b7f4e2a1c9f8a06d5b40e7",
    {
      _id: "d3b7f4e2a1c9f8a06d5b40e7",
      numberofimages: 1,
      numberoftextfields: 4,
      category: "square",
      type: "Facebook",
      code: `<div className="w-[1080px] h-[1080px] relative bg-white shadow">
    <img className="w-[1080px] h-[1080px] object-cover left-0 top-0 absolute" src="image1" />
    <div className="w-[534px] h-[270px] left-[273px] top-[405px] absolute text-center text-[#e4c053] text-[64px] font-normal font-['Inter']">Company Name</div>
    <div className="w-[534px] left-[273px] top-[77px] absolute text-center text-[#e4c053] text-[32px] font-normal font-['Inter']">Product Name</div>
    <div className="w-[66px] h-[151px] left-[61px] top-[464px] absolute text-center text-[#e4c053] text-[32px] font-normal font-['Inter']">Product Details</div>
    <div className="w-[66px] h-[151px] left-[972px] top-[464px] absolute text-center text-[#e4c053] text-[32px] font-normal font-['Inter']">Website</div>
    <div className="w-[534px] h-[195px] left-[273px] top-[753px] absolute text-center text-[#e4c053] text-[32px] font-normal font-['Inter']">uefw</div>
</div>`,

    },
  ],
  [
    "4f1a9e3b7c5d8a0f6b20c8e3",
    {
      _id: "4f1a9e3b7c5d8a0f6b20c8e3",
      numberofimages: 1,
      numberoftextfields: 4,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1024px] h-[1366px] relative bg-[#faefe0]">
    <img className="w-[1024px] h-[799px] object-cover left-0 top-0 absolute" src="image1" />
    <div className="w-[465px] left-[38px] top-[148px] absolute text-white text-4xl font-normal font-['Inter']">Website</div>
    <div className="w-[1024px] h-[129px] left-0 top-[799px] absolute bg-[#c49963] border-2 border-black" />
    <div className="w-[438.01px] h-[0px] left-[589.50px] top-[927.99px] absolute origin-top-left rotate-90 border-2 border-black"></div>
    <div className="w-[943px] left-[38px] top-[835px] absolute text-white text-5xl font-normal font-['Inter']">Product Name</div>
    <div className="w-[508px] h-[359px] left-[38px] top-[964px] absolute text-black text-8xl font-normal font-['Inter']">Company Name </div>
    <div className="w-[345px] left-[636px] top-[1057px] absolute text-center text-black text-4xl font-normal font-['Inter']">Product Details</div>
    <div className="w-[345px] left-[636px] top-[1206px] absolute text-center text-black text-4xl font-normal font-['Inter']">Website</div>
</div>`,

    },
  ],
  [
    "b8d7f5e0c2a1f9d3a4b5c69e",
    {
      _id: "b8d7f5e0c2a1f9d3a4b5c69e",
      numberofimages: 1,
      numberoftextfields: 3,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1024px] h-[1366px] relative bg-[#292929]">
    <div className="w-[823px] h-[478px] left-[101px] top-[444px] absolute text-[#e4e2dd] text-[320px] font-normal font-['Inter'] leading-[200px]">about<br/>me.</div>
    <img className="w-[359px] h-[214px] object-cover left-[631px] top-[683px] absolute rounded-[75px]" src="image1" />
    <div className="w-[744px] left-[101px] top-[1011px] absolute text-[#e4e2dd] text-[40px] font-normal font-['Inter']">Product Details</div>
    <div className="w-[744px] h-[250px] left-[101px] top-[1088px] absolute text-[#e4e2dd] text-[40px] font-normal font-['Inter']">Company Name</div>
    <div className="w-[372px] h-[49px] left-[101px] top-[28px] absolute text-[#e4e2dd] text-[40px] font-normal font-['Inter']">Product Name</div>
</div>`,

    },
  ],
  [
    "d9f3a5b1e7c8d2f0b6a4c70d",
    {
      _id: "d9f3a5b1e7c8d2f0b6a4c70d",
      numberofimages: 1,
      numberoftextfields: 3,
      category: "square",
      type: "Facebook",
      code: `<div className="w-[1080px] h-[1080px] relative bg-[#384d3b]">
    <img className="w-[540px] h-[1080px] object-cover left-0 top-0 absolute" src="image1" />
    <div className="w-[810px] h-[630px] left-[270px] top-[334px] absolute bg-[#f6f3ec]" />
    <div className="w-[536px] h-[50px] left-[367px] top-[874px] absolute text-black text-[32px] font-normal font-['Inter']">Company Name</div>
    <div className="w-[50px] h-[0px] left-[341.88px] top-[820.51px] absolute border-2 border-[#373636]"></div>
    <div className="w-[100px] h-[0px] left-[367px] top-[425px] absolute border-2 border-[#373636]"></div>
    <div className="left-[525px] top-[352px] absolute text-[#2b2a2a] text-9xl font-normal font-['Island Moments']">NEW</div>
    <div className="left-[367px] top-[466px] absolute text-[#2b2a2a] text-9xl font-normal font-['Island Moments']">ARRIVAL</div>
    <div className="w-[629px] h-[169px] left-[367px] top-[629px] absolute text-black text-[32px] font-normal font-['Inter']">Product Name</div>
    <div className="w-[333px] h-[135px] left-[717px] top-[87px] absolute text-right text-white text-[32px] font-normal font-['Inter']">Product Details</div>
</div>`,

    },
  ],
  [
    "7a8b4f2e5d1c0f9a6b3d8c51",
    {
      _id: "7a8b4f2e5d1c0f9a6b3d8c51",
      numberofimages: 1,
      numberoftextfields: 3,
      category: "square",
      type: "Facebook",
      code: `<div className="w-[1080px] h-[1080px] relative bg-[#dbddda]">
    <img className="w-[972px] h-[514px] object-cover left-[108px] top-[180px] absolute" src="image1" />
    <div className="w-[944px] h-[326px] left-[108px] top-[720px] absolute text-[#062424] text-8xl font-normal font-['Inter']">Company Name</div>
    <div className="w-[302px] h-[108px] left-[108px] top-[60px] absolute text-[#062424] text-[32px] font-normal font-['Inter']">Product Name</div>
    <div className="w-[548px] h-[108px] left-[504px] top-[60px] absolute text-[#062424] text-[32px] font-normal font-['Inter']">Product Details</div>
</div>`,

    },
  ],
  [
    "5d6e9a2f3c8b0a7f1d4c5b6e",
    {
      _id: "5d6e9a2f3c8b0a7f1d4c5b6e",
      numberofimages: 1,
      numberoftextfields: 2,
      category: "square",
      type: "Facebook",
      code: `<div className="w-[1080px] h-[1080px] relative bg-[#f9f9f9]">
    <img className="w-[632px] object-cover h-[870px] left-[448px] top-0 absolute" src="image1" />
    <div className="w-[894px] h-[0px] left-[422px] top-0 absolute origin-top-left rotate-90 border-2 border-[#9c9181]"></div>
    <div className="w-[658px] h-[0px] left-[422px] top-[894px] absolute border-2 border-[#9c9181]"></div>
    <div className="left-[66px] top-[268px] absolute text-black text-8xl font-normal font-['Instrument Serif']">NEW</div>
    <div className="left-[100px] top-[393px] absolute text-black text-8xl font-normal font-['Mrs Saint Delafield']">RELEASE</div>
    <div className="w-[318px] h-[146px] left-[65px] top-[652px] absolute bg-black" />
    <div className="w-[232px] h-[94px] left-[104px] top-[680px] absolute text-white text-[32px] font-normal font-['Inter']">Company Name</div>
    <div className="w-[232px] h-[63px] left-[108px] top-[817px] absolute text-black text-[32px] font-normal font-['Inter']">Product Name</div>
</div>`,

    },
  ],
  [
    "5d6e9a2f3c8b0a7f1d4c5b23",
    {
      _id: "5d6e9a2f3c8b0a7f1d4c5b23",
      numberofimages: 1,
      numberoftextfields: 3,
      category: "landscape",
      type: "Facebook",
      code: `<div className="w-[1920px] h-[1080px] relative bg-[#2a2e32]">
    <img className="w-[856px] h-[1080px] left-[1064px] top-0 absolute object-cover" src="image1" />
    <div className="w-[230px] h-[0px] left-[520px] top-0 absolute origin-top-left rotate-90 border border-white"></div>
    <div className="w-[230px] h-[0px] left-[520px] top-[850px] absolute origin-top-left rotate-90 border border-white"></div>
    <div className="w-[752px] h-[180px] left-[142px] top-[290px] absolute text-center text-white text-[64px] font-normal font-['Inter']">Company Name</div>
    <div className="w-[752px] h-[76px] left-[142px] top-[512px] absolute text-center text-[#f46956] text-[64px] font-normal font-['Inspiration']">Product Name</div>
    <div className="w-[752px] h-[70px] left-[142px] top-[730px] absolute bg-[#f46956]" />
    <div className="w-[752px] left-[142px] top-[741px] absolute text-center text-white text-[40px] font-normal font-['Inter']">Product Info</div>
</div>`,

    },
  ],
  [
    "5d6e9a2f3c8b0a7f1d4c5b24",
    {
      _id: "5d6e9a2f3c8b0a7f1d4c5b24",
      numberofimages: 3,
      numberoftextfields: 5,
      category: "landscape",
      type: "Facebook",
      code: `<div className="w-[1920px] h-[1080px] relative bg-[#eeeee2]">
    <div className="w-[1080px] h-[0px] left-[1800px] top-0 absolute origin-top-left rotate-90 border border-black"></div>
    <div className="w-[1680px] h-[0px] left-[120px] top-[169px] absolute border border-black"></div>
    <div className="w-[1680px] h-[0px] left-[120px] top-[958px] absolute border border-black"></div>
    <div className="w-[1080px] h-[0px] left-[120px] top-0 absolute origin-top-left rotate-90 border border-black"></div>
    <div className="w-[788px] h-[0px] left-[876px] top-[170px] absolute origin-top-left rotate-90 border border-black"></div>
    <div className="w-[756px] h-[0px] left-[119.99px] top-[279.50px] absolute border border-black"></div>
    <img className="w-[388px] h-[316px] left-[1412px] top-[170px] absolute object-cover" src="image1" />
    <img className="w-[388px] h-[472px] left-[1412px] top-[486px] absolute object-cover" src="image2" />
    <img className="w-[536px] h-[788px] left-[876px] top-[170px] absolute object-cover" src="image3" />
    <div className="w-[938px] left-[474px] top-[25px] absolute text-center text-black text-8xl font-normal font-['Island Moments']">Company Name</div>
    <div className="left-[474px] top-[414px] absolute text-black text-8xl font-normal font-['Island Moments']">NEW</div>
    <div className="left-[391px] top-[524px] absolute text-black text-9xl font-normal font-['Italiana']">ARRIVAL</div>
    <div className="w-[681px] left-[151px] top-[205px] absolute text-black text-[32px] font-normal font-['Italiana']">Product Name</div>
    <div className="w-[681px] left-[38px] top-[865px] absolute origin-top-left -rotate-90 text-center text-black text-[32px] font-normal font-['Italiana']">Product Info</div>
    <div className="w-[1577px] left-[172px] top-[992px] absolute text-center text-black text-[32px] font-normal font-['Italiana']">Website</div>
</div>`,

    },
  ],
  [
    "5d6e9a2f3c8b0a0f1d4c5b25",
    {
      _id: "5d6e9a2f3c8b0a0f1d4c5b25",
      numberofimages: 2,
      numberoftextfields: 3,
      category: "landscape",
      type: "Facebook",
      code: `<div className="w-[1920px] h-[1080px] relative bg-[#473926]">
    <img className="w-[800px] h-[658.20px] left-[960px] top-[90px] absolute object-cover" src="image1" />
    <img className="w-[500px] h-[411.37px] left-[710px] top-[542px] absolute object-cover" src="image2" />
    <div className="w-[636px] left-[194px] top-[252px] absolute text-white text-[64px] font-normal font-['Italiana']">Product Name</div>
    <div className="w-[431px] h-32 left-[194px] top-[553px] absolute text-white text-4xl font-normal font-['Italiana']">Product Info</div>
    <div className="w-[995px] left-[194px] top-[342px] absolute text-white text-8xl font-normal font-['Italiana']">Company Name</div>
</div>`,

    },
  ],
  [
    "5d6e9aaf3c8b0a0f1d4c5b26",
    {
      _id: "5d6e9aaf3c8b0a0f1d4c5b26",
      numberofimages: 2,
      numberoftextfields: 3,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-white">
    <img className="w-[1098px] h-[1920px] left-[-9px] top-0 absolute object-cover" src="image1" />
    <img className="w-[363px] h-[214px] left-[381px] top-[268px] absolute mix-blend-multiply object-cover" src="image2" />
    <div className="w-[368px] h-[131px] left-[376px] top-[554px] absolute text-center text-white text-8xl font-normal font-['Yellowtail']">Yes !</div>
    <div className="w-[893px] left-[101px] top-[718px] absolute text-center text-white text-8xl font-normal font-['Trade Winds']">We Are<br/> Open </div>
    <div className="w-[568px] h-[0px] left-[244px] top-[1025px] absolute border-2 border-white"></div>
    <div className="w-[577px] h-[252px] left-[235px] top-[1049px] absolute bg-[#d9d9d9]/0" />
    <div className="w-[550px] h-[252px] left-[262px] top-[1049px] absolute text-center text-white text-8xl font-normal font-['Trade Winds']">Company Name</div>
    <div className="w-[400px] h-[122px] left-[28px] top-[1763px] absolute text-center text-white text-2xl font-normal font-['Trade Winds']">Product Name</div>
    <div className="w-[383px] h-[147px] left-[673px] top-[1756px] absolute text-center text-white text-2xl font-normal font-['Trade Winds']">Product Info</div>
</div>`,

    },
  ],
  [
    "5d6e9aaf3c8b0l0f1d4c5b27",
    {
      _id: "5d6e9aaf3c8b0l0f1d4c5b27",
      numberofimages: 1,
      numberoftextfields: 1,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-white">
    <img className="w-[1080px] h-[1920px] left-0 top-0 absolute object-cover" src="image1" />
    <div className="left-[391px] top-[212px] absolute text-white text-9xl font-normal font-['Inspiration']">Delicious</div>
    <div className="left-[262px] top-[423px] absolute text-[#ff8938] text-9xl font-normal font-['Instrument Serif']">FOOD MENU</div>
    <div className="w-[651px] h-[265px] left-[215px] top-[807px] absolute text-center text-white text-[64px] font-normal font-['Instrument Serif']">Company Name</div>
</div>`,

    },
  ],
  [
    "5d6e9aaf3c8b0l0f1d495b28",
    {
      _id: "5d6e9aaf3c8b0l0f1d495b28",
      numberofimages: 2,
      numberoftextfields: 3,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-gradient-to-b from-[#1c110d] to-[#8a4c35] shadow">
    <div className="w-[907px] h-[114px] left-[79px] top-[1707px] absolute text-center text-white text-xl font-black font-['Inter']">Product Info</div>
    <div className="w-[736px] h-80 left-[195px] top-[141px] absolute text-center text-white text-9xl font-black font-['Inter']">Company Name</div>
    <div className="w-[754px] h-80 left-[195px] top-[441px] absolute text-center text-white text-5xl font-black font-['Inter']">Product Name</div>
    <img className="w-[820px] h-[700px] left-[166px] top-[761px] absolute object-cover" src="image1" />
    <img className="w-[522px] h-[423px] left-[140px] top-[1153px] absolute object-cover" src="image2" />
</div>`,
    },
  ],
  [
    "5d6e9aaf3c8b0l0f1d490b28",
    {
      _id: "5d6e9aaf3c8b0l0f1d490b28",
      numberofimages: 1,
      numberoftextfields: 2,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-[#f1f1ea]">
    <div className="w-[858px] left-[115px] top-[241px] absolute text-center text-black text-8xl font-normal font-['Inter']">NOW</div>
    <div className="w-[858px] h-[353px] left-[115px] top-[1304px] absolute text-center text-black text-[40px] font-normal font-['Inter']">Product Name</div>
    <div className="w-[371px] h-[73px] left-[20px] top-[1827px] absolute text-center text-black text-[32px] font-normal font-['Inter']">Company Name</div>
    <div className="w-[858px] left-[115px] top-[357px] absolute text-center text-black text-8xl font-normal font-['Inter']">BOOKING</div>
    <div className="w-[858px] left-[115px] top-[473px] absolute text-center text-black text-8xl font-normal font-['Inter']">2025</div>
    <img className="w-[728px] h-[526px] object-cover left-[178px] top-[697px] absolute" src="image1" />
    <div className="w-[1080px] h-[0px] left-[-0.01px] top-[1778.44px] absolute border-2 border-black"></div>
</div>`,
    },
  ],
  [
    "5d6e9aaf4c8b0l0f1d490b28",
    {
      _id: "5d6e9aaf4c8b0l0f1d490b28",
      numberofimages: 4,
      numberoftextfields: 1,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-gradient-to-b from-[#2b2b2b] to-[#919191]">
    <img className="w-[500px] h-[500px] object-cover left-0 top-[213px] absolute" src="image1" />
    <div className="w-[694px] h-[122px] left-[61px] top-[1030px] absolute text-white text-4xl font-normal font-['Inter']">Company Name</div>
    <img className="w-[345px] h-[641px] object-cover left-[735px] top-[1221px] absolute" src="image2" />
    <img className="w-[345px] h-[641px] object-cover left-[367px] top-[1221px] absolute" src="image3" />
    <img className="w-[500px] h-[500px] object-cover left-[255px] top-[460px] absolute" src="image4" />
</div>`,
    },
  ],
  [
    "5d6e9aaf4c8b0l0f7d490b28",
    {
      _id: "5d6e9aaf4c8b0l0f7d490b28",
      numberofimages: 3,
      numberoftextfields: 3,
      category: "landscape",
      type: "Facebook",
      code: `<div className="w-[1920px] h-[1080px] relative bg-gradient-to-r from-[#633564] via-[#729192] to-[#888881] border border-black">
    <div className="w-[1080px] h-[0px] left-[1800px] top-0 absolute origin-top-left rotate-90 border border-black"></div>
    <div className="w-[1680px] h-[0px] left-[120px] top-[169px] absolute border border-black"></div>
    <div className="w-[1680px] h-[0px] left-[120px] top-[958px] absolute bg-[#d9d9d9] shadow border border-black"></div>
    <div className="w-[1080px] h-[0px] left-[120px] top-0 absolute origin-top-left rotate-90 border border-black"></div>
    <div className="w-[788px] h-[0px] left-[876px] top-[170px] absolute origin-top-left rotate-90 border border-black"></div>
    <div className="w-[756px] h-[0px] left-[119.99px] top-[279.50px] absolute border border-black"></div>
    <img className="w-[388px] h-[316px] left-[1412px] object-cover top-[170px] absolute" src="image1" />
    <img className="w-[388px] h-[472px] left-[1412px] object-cover top-[486px] absolute" src="image2" />
    <img className="w-[536px] h-[788px] left-[876px] object-cover top-[170px] absolute" src="image3" />
    <div className="left-[734px] top-[23px] absolute text-black text-8xl font-normal font-['Island Moments']">Company Name</div>
    <div className="left-[474px] top-[414px] absolute text-black text-8xl font-normal font-['Island Moments']">NEW</div>
    <div className="left-[391px] top-[524px] absolute text-black text-9xl font-normal font-['Italiana']">ARRIVAL</div>
    <div className="w-[681px] left-[151px] top-[205px] absolute text-black text-[32px] font-normal font-['Italiana']">Product Name</div>
    <div className="w-[1577px] left-[172px] top-[992px] absolute text-center text-black text-[32px] font-normal font-['Italiana']">Product Info</div>
</div>`,
    },
  ],
  [
    "5d6e9aaf4c3b0l0f7d490b28",
    {
      _id: "5d6e9aaf4c3b0l0f7d490b28",
      numberofimages: 1,
      numberoftextfields: 3,
      category: "landscape",
      type: "Facebook",
      code: `<div className="w-[1920px] h-[1080px] relative bg-gradient-to-l from-[#893e3e] to-[#14a256]">
    <img className="w-[960px] h-[764px] object-cover left-[1px] top-[171px] absolute" src="image1" />
    <div className="w-[1920px] h-[0px] left-0 top-[935px] absolute border-2 border-black"></div>
    <div className="w-[1920px] h-[0px] left-0 top-[171px] absolute border-2 border-black"></div>
    <div className="w-[1241px] left-[339px] top-[36px] absolute text-center text-black text-[64px] font-normal font-['Inter']">Company Name</div>
    <div className="w-[960px] h-[204px] left-[960px] top-[445px] absolute text-center text-black text-8xl font-normal font-['Inter']">Product Name</div>
    <div className="w-[960px] h-[204px] left-[428px] top-[945px] absolute text-center text-black text-[64px] font-normal font-['Inter']">Product Info</div>
</div>`,
    },
  ],
  [
    "5d6e9aaf4c3b0l0f7d4u0b28",
    {
      _id: "5d6e9aaf4c3b0l0f7d4u0b28",
      numberofimages: 3,
      numberoftextfields: 3,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-[#f1f1f1]">
    <img className="w-[382px] object-cover h-[500px] left-[678px] top-[506px] absolute" src="image1" />
    <img className="w-[382px] object-cover h-[500px] left-[24px] top-[506px] absolute" src="image2" />
    <div className="w-[1080px] h-[117px] left-0 top-[207px] absolute text-center text-black text-[64px] font-normal font-['Inter']">Company Name</div>
    <img className="w-[382px] object-cover h-[500px] left-[365px] top-[406px] absolute mix-blend-multiply" src="image3" />
    <div className="w-[1036px] h-[175px] left-[24px] top-[1126px] absolute text-center text-black text-[64px] font-normal font-['Inter']">Product Name</div>
    <div className="w-[1080px] h-[271px] left-0 top-[1365px] absolute text-center text-black text-[64px] font-normal font-['Inter']">Product Info</div>
    <div className="w-[1080.02px] h-[0px] left-0 top-[1747px] absolute border-2 border-black"></div>
    <div className="w-[1080.02px] h-[0px] left-0 top-[147px] absolute border-2 border-black"></div>
</div>`,
    },
  ],
  [
    "5d6e9aaf4c3b0ltf7d4u0b28",
    {
      _id: "5d6e9aaf4c3b0ltf7d4u0b28",
      numberofimages: 1,
      numberoftextfields: 1,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-[#f1f1f1]">
    <div className="w-[1080px] h-[143px] left-0 top-[1627px] absolute text-center text-black text-[64px] font-normal font-['Jacques Francois Shadow']">@Company Name</div>
    <div className="w-[777px] left-[152px] top-[918px] absolute mix-blend-darken text-center text-black text-[64px] font-normal font-['Jacques Francois Shadow']">10.00 AM -- 7.00 PM</div>
    <div className="w-[998px] h-[261px] left-[27px] top-[584px] absolute mix-blend-darken text-center text-black text-[64px] font-normal font-['Jacques Francois']">we are now</div>
    <div className="w-[998px] h-[261px] left-[27px] top-[715px] absolute mix-blend-darken text-center text-black text-[64px] font-normal font-['Jacques Francois Shadow']">open</div>
    <img className="w-[960px] object-cover h-[908px] left-[46px] top-[261px] absolute opacity-50 rounded-[322px]" src="image1" />
</div>`,
    },
  ],
  [
    "5d6e9aaf4c3b0ltf7dlu0b28",
    {
      _id: "5d6e9aaf4c3b0ltf7dlu0b28",
      numberofimages: 1,
      numberoftextfields: 1,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-[#ccd6df]/40">
    <div className="w-[777px] left-[152px] top-[918px] absolute mix-blend-darken text-center text-black text-[64px] font-normal font-['Jacques Francois Shadow']">10.00 AM -- 7.00 PM</div>
    <div className="w-[998px] h-[261px] left-[27px] top-[584px] absolute mix-blend-darken text-center text-black text-[64px] font-normal font-['Jacques Francois']">we are now</div>
    <div className="w-[998px] h-[261px] left-[27px] top-[715px] absolute mix-blend-darken text-center text-black text-[64px] font-normal font-['Jacques Francois Shadow']">open</div>
    <img className="w-[1080px] h-[1920px] left-0 top-0 absolute opacity-50 rounded-[454px] object-cover" src="image1" />
    <div className="w-[1080px] h-[178px] left-[1px] top-[1704px] absolute text-center text-black text-[64px] font-normal font-['Jacques Francois Shadow']">@Company Name</div>
</div>`,
    },
  ],
  [
    "5d6e9aaf4c3b0ltfudlu0b28",
    {
      _id: "5d6e9aaf4c3b0ltfudlu0b28",
      numberofimages: 2,
      numberoftextfields: 4,
      category: "landscape",
      type: "Facebook",
      code: `<div className="w-[1200px] h-[628px] relative bg-gradient-to-b from-[#ebe8e5] via-[#ebe8e5] to-[#858382]">
    <img className="w-[392px] h-[397px] left-[248px] top-[18px] absolute object-cover" src="image1" />
    <img className="w-[392px] h-[397px] left-[500px] top-[216px] absolute object-cover" src="image2" />
    <div className="w-[297.01px] h-[0px] left-0 top-[87px] absolute border-2 border-black"></div>
    <div className="w-[462px] h-[178px] left-[721px] top-[21px] absolute text-center text-black text-[32px] font-normal font-['Inter']">Product Name</div>
    <div className="w-[305px] h-[247px] left-[892px] top-[284px] absolute text-center text-black text-xl font-normal font-['Inter']">Product Info</div>
    <div className="w-[351px] h-20 left-[845px] top-[546px] absolute text-black text-sm font-normal font-['Inter']">Website </div>
    <div className="w-[462px] h-[178px] left-[17px] top-[437px] absolute text-center text-black text-[32px] font-normal font-['Inter']">Company Name</div>
</div>`,
    },
  ],
  [
    "5d6e9aaf4c3i0ltfudlu0b28",
    {
      _id: "5d6e9aaf4c3i0ltfudlu0b28",
      numberofimages: 1,
      numberoftextfields: 2,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-gradient-to-b from-[#4b9960] to-[#193320]">
    <div className="w-[982px] h-[1728px] left-[46px] top-[103px] absolute mix-blend-darken bg-[#d9d9d9] rounded-[94px] shadow-inner border-8 border-[#673c3c]" />
    <div className="w-[864px] h-[207px] left-[105px] top-[221px] absolute text-center text-white text-8xl font-normal font-['Inter']">Company Name</div>
    <div className="w-[864px] h-[402px] left-[105px] top-[1369px] absolute text-center text-white text-8xl font-normal font-['Inter']">Product Name</div>
    <img className="w-[766px] h-[651px] left-[166px] top-[575px] absolute object-cover" src="image1" />
</div>`,
    },
  ],
  [
    "5d6e9aaf4c3i0l9fudlu0b28",
    {
      _id: "5d6e9aaf4c3i0l9fudlu0b28",
      numberofimages: 1,
      numberoftextfields: 3,
      category: "landscape",
      type: "Facebook",
      code: `<div className="w-[1920px] h-[1080px] relative bg-[#faf7f5]">
    <img className="w-[960px] h-[763px] left-0 top-[172px] absolute object-cover" src="image1" />
    <div className="w-[1920px] h-[0px] left-0 top-[935px] absolute border-2 border-black"></div>
    <div className="w-[1920px] h-[0px] left-0 top-[171px] absolute border-2 border-black"></div>
    <div className="w-[1241px] left-[339px] top-[36px] absolute text-center text-black text-[64px] font-normal font-['Inter']">Company Name</div>
    <div className="w-[926px] h-[159px] left-[993px] top-[300px] absolute text-black text-8xl font-normal font-['Inter']">Product Name</div>
    <div className="w-[926px] h-[327px] left-[994px] top-[576px] absolute text-black text-[40px] font-normal font-['Inter']">Product Info</div>
</div>`,
    },
  ],
  [
    "5d6e9aaf4c3i0l9fuelu0b28",
    {
      _id: "5d6e9aaf4c3i0l9fuelu0b28",
      numberofimages: 1,
      numberoftextfields: 2,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-white">
    <img className="w-[1080px] h-[1920px] left-0 top-0 absolute mix-blend-luminosity object-cover" src="image1" />
    <div className="w-[1080px] left-0 top-[344px] absolute text-center text-white text-8xl font-normal font-['Inter']">Company Name</div>
    <div className="w-[1080px] h-[207px] left-0 top-[579px] absolute text-center text-white text-[64px] font-normal font-['Inter']">Product Name</div>
</div>`,
    },
  ],
  [
    "5d6e9aaf4c3i0l9luelu0b28",
    {
      _id: "5d6e9aaf4c3i0l9luelu0b28",
      numberofimages: 8,
      numberoftextfields: 2,
      category: "landscape",
      type: "Facebook",
      code: `<div className="w-[1920px] h-[1080px] relative bg-black">
    <img className="w-[470px] h-[644px] left-[-0.36px] top-0 absolute rounded-br-[49.84px] object-cover" src="image1" />
    <img className="w-[470px] h-[410px] left-0 top-[670px] absolute rounded-tr-[50px] object-cover" src="image2" />
    <img className="w-[470px] h-[644px] left-[501px] top-[436px] absolute rounded-tl-[50px] rounded-tr-[50px] object-cover" src="image3" />
    <img className="w-[470px] h-[644px] left-[1002px] top-0 absolute rounded-bl-[50px] rounded-br-[50px] object-cover" src="image4" />
    <img className="w-[417px] h-[644px] left-[1503px] top-[436px] absolute rounded-tl-[50px] object-cover" src="image5" />
    <img className="w-[470px] h-[410px] left-[501px] top-0 absolute rounded-bl-[50px] rounded-br-[50px] object-cover" src="image6" />
    <img className="w-[470px] h-[410px] left-[1002px] top-[670px] absolute rounded-tl-[50px] rounded-tr-[50px] object-cover" src="image7" />
    <img className="w-[417px] h-[410px] left-[1503px] top-0 absolute rounded-bl-[50px] object-cover" src="image8" />
    <div className="w-[600px] h-[600px] left-[671px] top-[240px] absolute mix-blend-difference bg-[#eae8e4] rounded-full" />
    <div className="w-[362px] h-[191px] left-[779px] top-[559px] absolute text-center text-[#9d9384] text-4xl font-normal font-['Inter']">Product Name</div>
    <div className="w-[531px] h-[0px] left-[705px] top-[538px] absolute border-2 border-[#9d9384]"></div>
    <div className="w-[362px] h-[191px] left-[790px] top-[328px] absolute text-center text-[#9d9384] text-5xl font-normal font-['Inter']">Company Name</div>
</div>`,
    },
  ],
  [
    "5d6e9aaf4c3i0l9quelu0b28",
    {
      _id: "5d6e9aaf4c3i0l9quelu0b28",
      numberofimages: 2,
      numberoftextfields: 2,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-[#f8f3ea]">
    <div className="w-[900px] h-[0px] left-[970px] top-[-0px] absolute origin-top-left rotate-90 border-2 border-[#3b3332]"></div>
    <div className="w-[900px] h-[0px] left-[110px] top-[1020px] absolute origin-top-left rotate-90 border-2 border-[#3b3332]"></div>
    <div className="w-[860px] h-[860px] left-0 top-[160px] absolute bg-white" />
    <img className="w-[850px] h-[850px] left-0 top-[170px] absolute object-cover" src="image1" />
    <div className="w-[860px] h-[860px] left-[220px] top-[900px] absolute bg-white" />
    <img className="w-[850px] h-[850px] left-[230px] top-[910px] absolute object-cover" src="image2" />
    <div className="w-5 h-5 left-[100px] top-[60px] absolute bg-[#3b3332] rounded-full" />
    <div className="w-5 h-5 left-[138px] top-[60px] absolute bg-[#3b3332] rounded-full" />
    <div className="w-5 h-5 left-[176px] top-[60px] absolute bg-[#3b3332] rounded-full" />
    <div className="w-[671px] h-[118px] left-[299px] top-[42px] absolute text-black text-[64px] font-normal font-['Inter']">Company Name</div>
    <div className="w-[671px] h-[118px] left-[138px] top-[1770px] absolute text-black text-[64px] font-normal font-['Inter']">Product Name</div>
</div>`,
    },
  ],
  [
    "5d6e9aaf4c3i0l9qtelu0b28",
    {
      _id: "5d6e9aaf4c3i0l9qtelu0b28",
      numberofimages: 4,
      numberoftextfields: 2,
      category: "portrait",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1920px] relative bg-white">
    <div className="w-[520px] h-[730px] left-[560px] top-[1190px] absolute bg-[#d9d9d9]" />
    <div className="w-[520px] h-[730px] left-0 top-0 absolute bg-[#d9d9d9]" />
    <div className="w-[520px] h-[730px] left-[560px] top-[230px] absolute bg-[#d9d9d9]" />
    <div className="w-[520px] h-[730px] left-0 top-[960px] absolute bg-[#d9d9d9]" />
    <img className="w-[520px] h-[730px] left-0 top-[960px] absolute object-cover" src="image1" />
    <img className="w-[520px] h-[730px] left-[560px] top-[230px] absolute object-cover" src="image2" />
    <div className="w-[520px] h-[730px] left-[280px] top-[595px] absolute bg-white" />
    <div className="w-[438px] h-[138px] left-[301px] top-[1012px] absolute text-center text-black text-5xl font-normal font-['Instrument Serif'] tracking-widest">Product Name</div>
    <div className="w-[438px] h-[190px] left-[301px] top-[782px] absolute text-center text-black text-[64px] font-normal font-['Instrument Serif'] tracking-[3.20px]">Company Name</div>
    <img className="w-[520px] h-[730px] left-0 top-0 absolute object-cover" src="image3" />
    <img className="w-[520px] h-[730px] left-[560px] top-[1190px] absolute object-cover" src="image4" />
    <div className="w-[520px] h-[190px] left-[560px] top-0 absolute bg-[#e9e9ea]" />
    <div className="w-[520px] h-[190px] left-0 top-[1730px] absolute bg-[#6f5a4b]" />
    <div className="w-[280px] h-[150px] left-[800px] top-[1000px] absolute bg-[#aa8f6d]" />
    <div className="w-[280px] h-[150px] left-0 top-[770px] absolute bg-[#c8baa6]" />
</div>`,
    },
  ],
  [
    "576e9aaf4c3i0l9qtelu0b28",
    {
      _id: "576e9aaf4c3i0l9qtelu0b28",
      numberofimages: 1,
      numberoftextfields: 4,
      category: "square",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1080px] relative bg-[#393735]">
    <div className="w-[920px] h-[446px] left-[80px] top-[94px] absolute bg-[#393735] border border-white" />
    <img className="w-[891px] h-[398px] left-[94px] top-[118px] absolute object-cover" src="image1" />
    <div className="w-[920px] left-[79px] top-[653px] absolute text-center text-white text-8xl font-normal font-['Island Moments']">Sale Sale Sale</div>
    <div className="w-[920px] left-[79px] top-[854px] absolute text-center text-white text-[32px] font-normal font-['Inter']">Product Name</div>
    <div className="w-[920px] left-[80px] top-[1011px] absolute text-center text-white text-[32px] font-normal font-['Inter']">Website</div>
    <div className="w-[920px] h-[73px] left-[80px] top-[938px] absolute text-center text-white text-[32px] font-normal font-['Inter']">Product Info</div>
    <div className="w-[920px] h-[55px] left-[65px] top-[774px] absolute text-center text-white text-[32px] font-normal font-['Inter']">Company Name</div>
</div>`,
    },
  ],
  [
    "576e9aaf4c3i0n9qtelu0b28",
    {
      _id: "576e9aaf4c3i0n9qtelu0b28",
      numberofimages: 3,
      numberoftextfields: 2,
      category: "square",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1080px] relative bg-[#232622]">
    <img className="w-[580px] h-[968px] left-[78px] top-[112px] absolute rounded-tl-[400px] rounded-tr-[400px] object-cover" src="image1" />
    <img className="w-[216px] h-[360px] left-[550px] top-[666px] absolute border-4 border-[#232622] object-cover" src="image2" />
    <img className="w-[216px] h-[360px] left-[785px] top-[666px] absolute border-4 border-[#232622] object-cover" src="image3" />
    <div className="w-[387px] h-[0px] left-[346px] top-[51px] absolute border-4 border-[#bf9d73]"></div>
    <div className="w-[387px] h-[0px] left-[464px] top-[1048px] absolute border-4 border-[#bf9d73]"></div>
    <div className="w-[349px] h-[262px] left-[672px] top-[383px] absolute text-[#bf9d73] text-[32px] font-normal font-['Inter']">Product Name</div>
    <div className="w-[426px] h-[142px] left-[631px] top-[198px] absolute text-white text-[40px] font-normal font-['Inter']">Company Name</div>
</div>`,
    },
  ],
  [
    "576e9paf4c3i0n9qtelu0b28",
    {
      _id: "576e9paf4c3i0n9qtelu0b28",
      numberofimages: 3,
      numberoftextfields: 3,
      category: "square",
      type: "Instagram",
      code: `<div className="w-[1080px] h-[1080px] relative bg-white">
    <div className="w-[540px] h-[1080px] left-[540px] top-0 absolute bg-[#97643c]" />
    <img className="w-[400px] h-[400px] left-[515.16px] top-[38px] absolute origin-top-left rotate-12 border-8 object-cover border-[#97643c]" src="image1" />
    <img className="w-[400px] h-[400px] left-[629.85px] top-[322.66px] absolute origin-top-left rotate-[-6.72deg] object-cover border-8 border-white" src="image2" />
    <img className="w-[400px] object-cover h-[400px] left-[432px] top-[597.93px] absolute origin-top-left -rotate-3 border-8 border-[#97643c]" src="image3" />
    <div className="w-[376px] left-[65px] top-[158px] absolute text-black text-[40px] font-normal font-['Inter']">Company Name</div>
    <div className="left-[65px] top-[275px] absolute text-[#97643c] text-[64px] font-normal font-['Inter']">ALL YOU <br/>NEED IS HERE</div>
    <div className="w-[340px] h-24 left-[65px] top-[577px] absolute text-black text-[32px] font-normal font-['Inter']">Product Name</div>
    <div className="w-[340px] h-[255px] left-[65px] top-[708px] absolute text-black text-[32px] font-normal font-['Inter']">Product Info</div>
</div>`,
    },
  ],
  [
    "650ff9a1f443f9056dbd4573",
    {
      _id: "650ff9a1f443f9056dbd4573",
      numberofimages: 4,
      numberoftextfields: 2,
      category: "landscape",
      type: "Facebook",
      code: `<div className="Frame w-[1920px] h-[1080px] relative bg-[#2c351b]">
    <div className="Image w-[1224px] h-[1015px] left-[1890px] top-[41px] absolute origin-top-left rotate-180 bg-gradient-to-b from-white to-white rounded-[60px]" />
    <div className="CompanyName w-[666px] h-[90px] left-0 top-[56px] absolute text-center text-[#e2c9c9] text-[64px] font-medium font-['Syne'] uppercase leading-[30px]">Company Name</div>
    <div className="StandOutMakeYourMarkInTheMarket w-[588px] left-[28px] top-[517px] absolute text-center text-[#f7f3e2] text-[32px] font-normal font-['DM Sans']">Stand Out – Make Your Mark in the Market!</div>
    <img className="Image w-[587px] h-[479px] left-[1253px] top-[41px] absolute origin-top-left rotate-180 rounded-[60px] object-cover" src="image1" />
    <img className="Image w-[587px] h-[479px] left-[1880px] top-[40px] absolute origin-top-left rotate-180 rounded-[60px] object-cover" src="image2" />
    <img className="Image w-[587px] h-[479px] left-[1890px] top-[560px] absolute origin-top-left rotate-180 rounded-[60px] object-cover" src="image3" />
    <img className="Image w-[587px] h-[479px] left-[1253px] top-[560px] absolute origin-top-left rotate-180 rounded-[60px] object-cover" src="image4" />
    <div className="WebsiteLink w-[666px] h-[59px] left-0 top-[1021px] absolute text-[#a5e1e7] text-[28px] font-medium font-['Syne'] uppercase leading-[30px]">Websit</div>
    <div className="TextBlock w-[588px] h-[98px] left-[40px] top-[317px] absolute flex-col justify-start items-start gap-[53px] inline-flex">
    <div className="ProductName self-stretch text-[#f1c5c5] text-[80px] font-light font-['Cormorant Garamond'] leading-[80px]">Product Name</div>
    </div>
</div>`,
    },
  ],
  [
    "650ff9a1f443f9056dbd4574",
    {
      _id: "650ff9a1f443f9056dbd4574",
      numberofimages: 2,
      numberoftextfields: 2,
      category: "landscape",
      type: "Facebook",
      code: `<div className="Frame w-[1920px] h-[1080px] relative bg-[#c49855]">
        <div className="MacbookAirM2SilverFlatten w-[1374.62px] h-[833px] left-[545px] top-[87px] absolute">
        <img className="MacbookAirM2Silver1 object-cover w-[1374.62px] h-[833px] left-0 top-0 absolute" src="image1" />
        </div>
        <div className="JoinUsToday left-[44px] top-[211px] absolute text-center text-white text-8xl font-normal font-['Instrument Serif']">Join Us Today!</div>
        <div className="CompanyName w-[562px] h-[108px] left-[44px] top-[40px] absolute text-white text-5xl font-normal font-['DM Sans']">Company Name</div>
        <img className="Github1 object-cover w-[264px] h-[228px] left-[31px] top-[828px] absolute" src="image2" />
        <div className="ProductName w-[849px] left-[1241px] top-[968px] absolute text-center text-[#484685] text-5xl font-normal font-['Instrument Serif']">Product Name</div>
        </div>`,
    },
  ],
  [
    "650ff9a1f443f9056dbd4575",
    {
      _id: "650ff9a1f443f9056dbd4575",
      numberofimages: 2,
      numberoftextfields: 3,
      category: "landscape",
      type: "Facebook",
      code: `<div className="Frame w-[1920px] h-[1080px] relative bg-[#05c298]">
        <div className="Frame148 w-[960px] h-[1080px] left-0 top-[1px] absolute bg-[#f7f3e2]">
        <img className="ImagePlaceholder object-cover w-[972px] h-[446px] left-[-12px] top-[634px] absolute" src="image1" />
        <div className="CompanyName w-[651px] h-[127px] left-[129px] top-[57px] absolute text-center text-[#613737] text-[80px] font-light font-['Cormorant Garamond'] leading-[80px]">Company Name</div>
        <div className="CraftingConnectionsBuildingYourBrand left-[89px] top-[285px] absolute text-center text-black text-[40px] font-normal font-['DM Sans']">Crafting Connections – Building Your Brand!</div>
        </div>
        <div className="SalesDeckName w-[273px] h-[22px] left-[1293px] top-[1020px] absolute text-center text-[#2c351b] text-lg font-normal font-['Sorts Mill Goudy']">Sales Deck Name</div>
        <img className="ImagePlaceholder object-cover w-[607px] h-[872px] left-[960px] top-[209px] absolute" src="image2" />
        <div className="ProductInfo w-[304px] h-[724px] left-[1587px] top-[227px] absolute text-center text-white text-4xl font-normal font-['DM Serif Text']">Product Info</div>
        <div className="ProductName w-[960px] h-[147px] left-[960px] top-[38px] absolute text-center text-white text-[64px] font-normal font-['DM Serif Text']">Product Name</div>
    </div>`,
    },
  ],
  [
    "650ff9a1f443f9056dbd4576",
    {
      _id: "650ff9a1f443f9056dbd4576",
      numberofimages: 1,
      numberoftextfields: 2,
      category: "landscape",
      type: "Facebook",
      code: `<div className="Slide1691 w-[1920px] h-[1080px] relative bg-[#e7e7e7]">
            <div className="Line1 w-[127px] h-[0px] left-0 top-[74px] absolute border border-black"></div>
            <div className="Line2 w-[510px] h-[0px] left-[544px] top-[74px] absolute border border-black"></div>
            <div className="Line3 w-[510px] h-[0px] left-[146px] top-[529px] absolute border border-black"></div>
            <div className="CompanyName w-[417px] left-[146px] top-[51px] absolute text-black text-4xl font-normal font-['Instrument Serif']">Company Name</div>
            <div className="ProductName w-[417px] h-[118px] left-[146px] top-[585px] absolute text-black text-4xl font-normal font-['Instrument Serif']">Product Name</div>
            <div className="ProductLaunch w-[559px] h-[233px] left-[146px] top-[240px] absolute text-black text-8xl font-normal font-['Instrument Serif'] tracking-[9.60px]">PRODUCT<br/>LAUNCH</div>
            <img className="MartinMartzJfwq36wg7uoUnsplash1 object-cover w-[863px] h-[1080px] left-[1057px] top-0 absolute" src="image1" />
        </div>`,
    },
  ],
  [
    "650ff9a1f443f9056dbd4577",
    {
      _id: "650ff9a1f443f9056dbd4577",
      numberofimages: 1,
      numberoftextfields: 2,
      category: "landscape",
      type: "Facebook",
      code: `<div className="Slide1691 w-[1920px] h-[1080px] relative bg-[#5f899e]">
            <img className="MartinMartzJfwq36wg7uoUnsplash1 object-cover w-[960px] h-[1080px] left-0 top-0 absolute" src="image1" />
            <div className="NewCollection left-[554px] top-[389px] absolute text-center text-white text-9xl font-normal font-['Italiana'] tracking-[12.80px]">NEW<br/>COLLECTION</div>
            <div className="ProductName left-[621px] top-[691px] absolute text-center text-white text-8xl font-normal font-['Italiana'] tracking-[9.60px]">Product Name</div>
            <div className="CompanyName w-[542px] left-[1326px] top-[60px] absolute text-right text-white text-5xl font-normal font-['Italiana'] tracking-[4.80px]">Company Name</div>
        </div>`,
    },
  ],
  [
    "650ff9a1f443f9056dbd4578",
    {
      _id: "650ff9a1f443f9056dbd4578",
      numberofimages: 1,
      numberoftextfields: 1,
      category: "landscape",
      type: "Facebook",
      code: `<div className="Slide1691 w-[1920px] h-[1080px] relative bg-[#383020]">
            <img className="MartinMartzJfwq36wg7uoUnsplash1 object-cover w-[885px] h-[808px] left-[517px] top-[136px] absolute rounded-tl-[500px] object-cover rounded-tr-[500px]" src="image1" />
            <div className="Fashion left-[273px] top-[420px] absolute text-[#ca8770] text-[200px] font-normal font-['Dancing Script'] tracking-[100px]">FASHION</div>
            <div className="CompanyName w-[420px] left-[749px] top-[14px] absolute text-[#ca8770] text-[64px] font-normal font-['Dancing Script']">Company Name</div>
            <div className="DiscoverNow w-[514px] left-[703px] top-[944px] absolute text-[#ca8770] text-8xl font-normal font-['Dancing Script']">Discover Now!</div>
        </div>`,
    },
  ],
  [
    "650ff9a1f443f9056dbd4579",
    {
      _id: "650ff9a1f443f9056dbd4579",
      numberofimages: 1,
      numberoftextfields: 3,
      category: "portrait",
      type: "Instagram",
      code: `<div className="InstagramStory1 w-[1080px] h-[1920px] relative bg-black">
    <img className="MartinMartzJfwq36wg7uoUnsplash2 object-cover w-[365px] h-[750px] left-[165px] top-[860px] absolute" src="image1" />
    <img className="MartinMartzJfwq36wg7uoUnsplash1 object-cover w-[750px] h-[750px] left-[165px] top-[91px] absolute" src="image2" />
    <img className="MartinMartzJfwq36wg7uoUnsplash3 object-cover w-[365px] h-[750px] left-[550px] top-[860px] absolute" src="image3" />
    <div className="Steal left-[289px] top-[134px] absolute text-white text-9xl font-normal font-['Inter'] tracking-[25.60px]">STEAL</div>
    <div className="The left-[233px] top-[1177px] absolute text-white text-8xl font-normal font-['Inter'] tracking-[19.20px]">THE</div>
    <div className="Look left-[574px] top-[1457px] absolute text-white text-8xl font-normal font-['Inter'] tracking-[19.20px]">LOOK</div>
    <div className="Rectangle1 w-[750px] h-[105px] left-[165px] top-[1629px] absolute bg-[#af8763]" />
    <div className="ShopNow left-[319px] top-[1643px] absolute text-center text-white text-[64px] font-normal font-['Inter'] tracking-[12.80px]">SHOP NOW</div>
    <div className="CompanyName w-[750px] left-[165px] top-[1767px] absolute text-white text-5xl font-normal font-['Itim'] tracking-[9.60px]">Company Name</div>
</div>`,
    },
  ],
  [
    "650ff9a1f443f9056dbd4580",
    {
      _id: "650ff9a1f443f9056dbd4580",
      numberofimages: 3,
      numberoftextfields: 2,
      category: "portrait",
      type: "Instagram",
      code: `<div className="InstagramStory1 w-[1080px] h-[1920px] relative bg-[#f3f3ec]">
    <div className="Line1 w-[1246px] h-[0px] left-[540px] top-[104px] absolute origin-top-left rotate-90 border-4 border-[#4d4f5b]"></div>
    <div className="Line2 w-[452.11px] h-[0px] left-[87.94px] top-[383px] absolute border-4 border-[#4d4f5b]"></div>
    <div className="Line3 w-[452.11px] h-[0px] left-[540px] top-[639px] absolute border-4 border-[#4d4f5b]"></div>
    <img className="MartinMartzJfwq36wg7uoUnsplash1 w-[430px] object-cover h-[948px] left-[88px] top-[402px] absolute" src="image1" />
    <img className="MartinMartzJfwq36wg7uoUnsplash2 w-[430px] object-cover h-[692px] left-[562px] top-[658px] absolute" src="image2" />
    <img className="MartinMartzJfwq36wg7uoUnsplash3 w-[430px] object-cover h-[516px] left-[562px] top-[104px] absolute" src="image3" />
    <div className="CompanyName w-[430px] h-[258px] left-[88px] top-[104px] absolute text-center text-[#cf703b] text-[64px] font-normal font-['Italianno']">Company Name</div>
    <div className="EnjoyASpecialDiscount w-[904px] h-[79px] left-[88px] top-[1390px] absolute text-center text-[#4d4f5b] text-[64px] font-normal font-['Instrument Serif'] tracking-[3.20px]">Enjoy a special discount</div>
    <div className="Rectangle1 w-[1080px] h-[411px] left-0 top-[1509px] absolute bg-[#4d4f5b]" />
    <div className="Rectangle2 w-[485px] h-[105px] left-[297px] top-[1566px] absolute bg-[#4d4f5b] rounded-[50px] border-2 border-white" />
    <div className="ShopNow w-[275px] h-[62px] left-[402px] top-[1588px] absolute text-center text-white text-5xl font-normal font-['Instrument Serif'] tracking-widest">SHOP NOW</div>
    <div className="ProductName w-[904px] h-[62px] left-[88px] top-[1690px] absolute text-center text-white text-5xl font-normal font-['Instrument Serif'] tracking-widest">Product Name</div>
</div>`,
    },
  ],
  [
    "650ff9a1f443f9056dbd4581",
    {
      _id: "650ff9a1f443f9056dbd4581",
      numberofimages: 1,
      numberoftextfields: 2,
      category: "portrait",
      type: "Instagram",
      code: `<div className="InstagramPost1 w-[1080px] h-[1080px] relative bg-white">
            <div className="Rectangle2 w-[232px] h-[422px] left-0 top-[658px] absolute bg-[#ddd5d0]" />
            <img className="MartinMartzJfwq36wg7uoUnsplash1 object-cover w-[725px] h-[897px] left-[66px] top-[71px] absolute" src="image1" />
            <div className="Rectangle1 w-[386px] h-[422px]  left-[694px] top-0 absolute opacity-75 bg-[#ddd5d0] rounded-bl-[50px]" />
            <div className="BigOffer left-[817px] top-[71px] absolute text-[#77604e] text-[32px] font-normal font-['Inter']">BIG OFFER</div>
            <div className="CompanyName w-[313px] h-[194px] left-[730px] top-[178px] absolute text-center text-[#352c24] text-5xl font-normal font-['Inter']">Company Name</div>
            <div className="Rectangle3 w-[206px] h-[51px] left-[837px] top-[917px] absolute bg-[#352c24]" />
            <div className="ShopNow left-[873px] top-[928px] absolute text-white text-2xl font-normal font-['Inter']">SHOP NOW</div>
            <div className="WebsiteLink w-[574px] h-[47px] left-0 top-[1033px] absolute text-[#605050] text-4xl font-normal font-['Tac One'] uppercase leading-[30px]"Product Name</div>
        </div>`,
    },
  ],
]);

export const id = ["650ff9a1f443f9056dbd45b3", "650ff9a1f443f9056dbd45b1", "f7a3c1d9b56e8a4073fc9b5d", "b5d67c8e1a3b9d2f50f4c7a6", "d9a84f3c27e6b5084dbf61a9", "4e7d8a9f3b5c1d20a6f94c5b", "2f3d6a7b8c9e1f0a45b7d8c6", "a1b7d3f9056c4e8a9f3c72d9", "f056a8c7b94e5d3f2a6b1c8d", "3c2a8e9d4b5f06a7f9d5b10c", "e7d4c6a3f9b5d8c2a1f504b9", "8f4d7c2a5b9e3f6a0d1c7b50", "f7a6b9c0d8e5f3c4b7a1d906", "c9f4a1b5e3d6c7f2a8b10d5e", "2a9f5b7d6e4c8f0a3d1b6079", "d3b7f4e2a1c9f8a06d5b40e7", "4f1a9e3b7c5d8a0f6b20c8e3", "b8d7f5e0c2a1f9d3a4b5c69e", "d9f3a5b1e7c8d2f0b6a4c70d", "7a8b4f2e5d1c0f9a6b3d8c51", "5d6e9a2f3c8b0a7f1d4c5b6e", "5d6e9a2f3c8b0a7f1d4c5b23", "5d6e9a2f3c8b0a7f1d4c5b24", "5d6e9a2f3c8b0a0f1d4c5b25", "5d6e9aaf3c8b0a0f1d4c5b26", "5d6e9aaf3c8b0l0f1d4c5b27", "5d6e9aaf3c8b0l0f1d495b28", "5d6e9aaf3c8b0l0f1d490b28", "5d6e9aaf4c8b0l0f1d490b28", "5d6e9aaf4c8b0l0f7d490b28", "5d6e9aaf4c3b0l0f7d490b28", "5d6e9aaf4c3b0l0f7d4u0b28", "5d6e9aaf4c3b0ltf7d4u0b28", "5d6e9aaf4c3b0ltf7dlu0b28", "5d6e9aaf4c3b0ltfudlu0b28", "5d6e9aaf4c3i0ltfudlu0b28", "5d6e9aaf4c3i0l9fudlu0b28", "5d6e9aaf4c3i0l9fuelu0b28", "5d6e9aaf4c3i0l9luelu0b28", "5d6e9aaf4c3i0l9quelu0b28", "5d6e9aaf4c3i0l9qtelu0b28", "576e9aaf4c3i0l9qtelu0b28", "576e9aaf4c3i0n9qtelu0b28", "576e9paf4c3i0n9qtelu0b28", "650ff9a1f443f9056dbd4573", "650ff9a1f443f9056dbd4581", "650ff9a1f443f9056dbd4580", "650ff9a1f443f9056dbd4579", "650ff9a1f443f9056dbd4578", "650ff9a1f443f9056dbd4577", "650ff9a1f443f9056dbd4576", "650ff9a1f443f9056dbd4575", "650ff9a1f443f9056dbd4574"];
