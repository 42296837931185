import React, { useEffect, useState } from "react";
import JsxParser from "react-jsx-parser";
const JSXRenderForShow = ({ jsx }) => {
    return (
        <div className="flex justify-center scaledLib object-center">
            <JsxParser jsx={jsx} />
        </div>
    );
};
export default JSXRenderForShow;
