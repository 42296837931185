// theme.js

const theme = {
    colors: {
      background: "#1D223A", // Dark background color for main container
      primary: "#6C63FF", // Highlight color (like the line chart and toggle switch color)
      secondary: "#FF4081", // Accent color used in buttons and other highlights
      textPrimary: "#FFFFFF", // Primary text color
      textSecondary: "#B0B3C1", // Secondary text color
      cardBackground: "#2C3149", // Card background color
      borderColor: "#444B6E", // Border color for input fields and cards
      iconColor: "#6C63FF", // Icon color matching the primary
      chartLineColors: ["#6C63FF", "#FF4081", "#21D4FD", "#A8A9AD"], // Colors for chart lines
      pieChartColors: ["#6C63FF", "#FF4081", "#21D4FD", "#0EAD69", "#FDD835"], // Colors for pie chart segments
    },
    typography: {
      fontFamily:  "'Quicksand', sans-serif",
      fontSize: {
        small: "12px",
        regular: "14px",
        medium: "16px",
        large: "20px",
      },
      fontWeight: {
        light: 300,
        regular: 400,
        medium: 500,
        bold: 700,
      },
    },
    spacing: (factor) => `${8 * factor}px`, // Spacing utility function
    borders: {
      borderRadius: "8px", // Rounded corners
      inputBorder: "1px solid #444B6E", // Input field border style
    },
    shadows: {
      card: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Shadow for card components
    },
    components: {
      button: {
        primary: {
          background: "#FF4081",
          color: "#FFFFFF",
          hover: "#FF5370",
        },
        secondary: {
          background: "#6C63FF",
          color: "#FFFFFF",
          hover: "#7A6FFF",
        },
      },
      toggleSwitch: {
        background: "#6C63FF",
        color: "#FFFFFF",
      },
      input: {
        background: "#2C3149",
        color: "#FFFFFF",
        placeholderColor: "#B0B3C1",
        borderColor: "#444B6E",
      },
    },
    layout: {
      chartHeight: 200, // Default chart height
      sidebarWidth: 300, // Sidebar width
      cardPadding: "16px", // Padding for cards
    },
  };
  
  export default theme;
  