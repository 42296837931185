import { useState, useCallback, useEffect } from "react";
import PreviewModelForMyTemplates from "./media/PreviewModelForMyTemplates";
import { temp, id } from "../util"; // Import your temp and id arrays
import theme from "../theme"; // Import theme
import DynamicJSXRenderer from "./JSXRender";
import JSXRenderForMyTemplates from "./JSXRenderForMyTemplates";

export default function LibraryDivForMyTemplate({
  heading,
  templateDataMap
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [previewItem, setPreviewItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");

  // Get categories from templateDataMap
  const getCategories = () => {
    const categories = new Set();
    templateDataMap.forEach((template) => {
      if (template.category) {
        categories.add(template.category);
      }
    });
    return Array.from(categories);
  };

  const categories = getCategories();
  if (!selectedCategory && categories.length > 0) {
    setSelectedCategory(categories[0]); // Default to the first category if none is selected
  }

  // Reset current page when category changes
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCategory]);

  // Filter items by selected category
  const filteredItems = [...templateDataMap.entries()].filter(
    ([, template]) => template.category === selectedCategory
  );

  const itemsPerPage = selectedCategory === "portrait" ? 1 : 2;
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  // Get the current page items
  const currentItems = filteredItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Handle preview action
  const handlePreview = useCallback((templateId) => {
    const itemData = templateDataMap.get(templateId);
    setPreviewItem(itemData);
  }, [templateDataMap]);

  return (
    <div className="w-1/3">
      <section
        className="p-6 rounded-md mt-8"
        style={{
          backgroundColor: theme.colors.cardBackground,
        }}
      >
        {/* Heading */}
        <div className="flex justify-between items-center mb-6 space-x-2">
          <h3
            className="text-xl font-semibold"
            style={{ color: theme.colors.textPrimary }}
          >
            {heading}
          </h3>

          {/* Category selection */}
          <div>
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="p-2 rounded shadow"
              style={{
                backgroundColor: theme.colors.background,
                color: theme.colors.textPrimary,
                borderColor: theme.colors.borderColor,
              }}
            >
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Grid of items */}
        <div className="grid grid-cols-1 gap-4">
          {currentItems.map(([uniqueId, object]) => (
            <div
              key={uniqueId}
              className="p-4 rounded-md shadow-sm transition-transform transform hover:scale-105"
              style={{
                backgroundColor: theme.colors.background,
                color: theme.colors.textPrimary,
              }}
            >
              <JSXRenderForMyTemplates
                textFields={object.textFields}
                mediaFiles={object.mediaFiles}
                jsx={temp.get(object.templateId)?.code}  // Assume temp has a 'code' field for JSX content
              />
              <p className="font-bold mt-2 text-sm">{temp.get(object.templateId)?.title}</p>
              <p className="text-sm" style={{ color: theme.colors.textSecondary }}>
                {temp.get(object.templateId)?.credits} Credits
              </p>
              <div className="flex justify-center mt-2">
                <button
                  className="w-1/2 m-1 rounded transition-colors"
                  style={{
                    backgroundColor: theme.colors.primary,
                    color: theme.colors.textPrimary,
                  }}
                  onClick={() => handlePreview(uniqueId)}
                >
                  Preview
                </button>
              </div>
            </div>
          ))}
        </div>

        {/* Pagination controls */}
        <div className="mt-8 flex justify-center items-center space-x-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-3 py-1 rounded disabled:opacity-50 transition-colors"
            style={{
              backgroundColor: theme.colors.primary,
              color: theme.colors.textPrimary,
            }}
          >
            Previous
          </button>
          <span style={{ color: theme.colors.textPrimary }}>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-3 py-1 rounded disabled:opacity-50 transition-colors"
            style={{
              backgroundColor: theme.colors.primary,
              color: theme.colors.textPrimary,
            }}
          >
            Next
          </button>
        </div>
      </section>

      {/* Preview Modal */}
      {previewItem && (
        <PreviewModelForMyTemplates
          item={previewItem}
          onClose={() => setPreviewItem(null)}
        />
      )}
    </div>
  );
}
