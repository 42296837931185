import React from "react";
import theme from "../../theme"; // Import theme

export default function StepNavigation({ activeStep, setActiveStep }) {
  const steps = [
    "Profile",
    "Subscription",
    "Brand",
    "Security",
    "Integrations",
    "Notifications",
  ];

  return (
    <div className="flex justify-between items-center mb-8">
      {steps.map((step, index) => (
        <div
          key={index}
          className="flex-1 m-1 p-2 text-center py-2 transition-all px-4 rounded-md cursor-pointer"
          style={{
            backgroundColor:
              activeStep === index + 1
                ? theme.colors.primary
                : theme.colors.cardBackground,
            color:
              activeStep === index + 1
                ? theme.colors.textPrimary
                : theme.colors.textSecondary,
            boxShadow: activeStep === index + 1 ? theme.shadows.card : "none",
          }}
          onClick={() => setActiveStep(index + 1)}
        >
          {step}
        </div>
      ))}
    </div>
  );
}
