import React, { useState, useEffect } from "react";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import DynamicJSXRenderer from "../JSXRender";
import { temp, id } from "../../util";
import introJs from "intro.js";
import "intro.js/introjs.css";
import theme from "../../theme";

// Function to get unique categories from the temp map
const getCategories = () => {
  const categories = new Set();
  id.forEach((i) => {
    categories.add(temp.get(i).category);
  });
  return Array.from(categories); // Convert Set to Array
};

const ChooseTemplate = ({
  adPlatform,
  selectedTemplate,
  setSelectedTemplate,
  handleNext,
  handlePrev,
  textFields,
  mediaFiles,
  rememberMe
}) => {
  const categories = getCategories();
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  useEffect(() => {
    const intro = introJs();
    intro.setOptions({
      steps: [
        {
          intro: "Welcome to Step 3: Choose Template.",
        },
        {
          element: "#category-select",
          intro: "Select a category for templates.",
        },
        {
          element: "#template-grid",
          intro: "Browse and select a template for your ad.",
        },
        {
          element: "#next-button",
          intro: "Click here to proceed to the next step.",
        },
      ],
      showSkipButton: true,
    });
    intro.start();
  }, [rememberMe]);

  return (
    <div style={{ color: theme.colors.textPrimary, fontFamily: theme.typography.fontFamily }}>
      {/* Flexbox for heading and category selection */}
      <h2 className="text-2xl mb-6" style={{ color: theme.colors.textPrimary }}>
        Step 3: Choose Template
      </h2>

      <div className="flex justify-end items-center mb-6">
        {/* Category selection placed on the right */}
        <div className="flex items-center">
          <select
            id="category-select"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="p-2 rounded shadow"
            style={{
              backgroundColor: theme.colors.cardBackground,
              color: theme.colors.textPrimary,
              border: `1px solid ${theme.colors.secondary}`,
            }}
          >
            {categories.map((category) => (
              <option key={category} value={category} style={{ color: theme.colors.textSecondary }}>
                {category}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Render templates based on selected category */}
      <div
        id="template-grid"
        className="grid h-96 overflow-y-scroll gap-2"
        style={{
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          color: theme.colors.textPrimary,
        }}
      >
        {id
          .filter(
            (i) =>
              temp.get(i).category === selectedCategory &&
              temp.get(i).numberoftextfields === textFields.length &&
              temp.get(i).numberofimages === mediaFiles.length &&
              temp.get(i).type === adPlatform
          ) // Filter by selected category
          .map((i) => (
            <div
              key={i}
              className="w-max p-1 h-76 rounded shadow transition cursor-pointer"
              onClick={() => setSelectedTemplate(i)}
              style={{
                backgroundColor: selectedTemplate === i ? theme.colors.secondary : theme.colors.cardBackground,
                color: selectedTemplate === i ? theme.colors.background : theme.colors.textPrimary,
                boxShadow: selectedTemplate === i ? "0px 4px 12px rgba(0, 0, 0, 0.3)" : "none",
              }}
            >
              <DynamicJSXRenderer
                textFields={textFields}
                mediaFiles={mediaFiles}
                jsx={temp.get(i)?.code}
              />
            </div>
          ))}
      </div>

      {/* Navigation buttons */}
      <div className="flex justify-between mt-6">
        <button
          className="px-6 py-3 rounded shadow transition"
          onClick={handlePrev}
          style={{
            backgroundColor: theme.colors.primary,
            color: theme.colors.textPrimary,
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
          onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
        >
          <FaChevronLeft /> Previous
        </button>
        <button
          id="next-button"
          className="px-6 py-3 rounded shadow transition"
          onClick={handleNext}
          style={{
            backgroundColor: theme.colors.primary,
            color: theme.colors.textPrimary,
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
          onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
        >
          Next <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default ChooseTemplate;
