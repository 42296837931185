import LibraryContainer from "./media/LibraryContainer";
import theme from "../theme"; // Import the theme

export default function MediaLibrary() {
  return (
    <div
      className="min-h-screen relative"
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
      }}
    >
      <div className="absolute w-4/5 right-0 p-10">
        <div className="flex justify-between">
          <div className="w-1/2">
            <input
              placeholder="Search"
              className="rounded-lg w-1/2 h-8"
              style={{
                backgroundColor: theme.colors.textPrimary,
                color: theme.colors.textSecondary,
                borderColor: theme.colors.borderColor,
              }}
            />
            <button
              className="ml-4 px-4 py-2 rounded-md transition-colors"
              style={{
                backgroundColor: theme.colors.cardBackground,
                color: theme.colors.textPrimary,
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
              onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.cardBackground)}
            >
              Submit
            </button>
          </div>
          <div>
            <button
              className="px-4 py-2 rounded-md transition-colors"
              style={{
                backgroundColor: theme.colors.cardBackground,
                color: theme.colors.textPrimary,
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
              onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.cardBackground)}
            >
              Filter
            </button>
          </div>
        </div>
        <LibraryContainer
          heading="Recent Projects"
          array={[1, 2, 3, 4, 5, 6, 7]}
          countPerRow={2}
          itemsPerPage={8}
        />
      </div>
    </div>
  );
}
