import React from 'react';
import theme from "../../theme"; // Import theme

export default function NotFound() {
  return (
    <div
      className="flex justify-center items-center min-h-screen relative"
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
      }}
    >
      <p className="text-3xl">About To Launch Under Beta Build</p>
    </div>
  );
}
