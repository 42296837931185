import React, { useState } from "react";
import theme from "../../theme"; // Import theme

export default function Integrations() {
  const [isPlatformConnected, setIsPlatformConnected] = useState({
    facebook: false,
    google: true,
    instagram: false,
  });
  const [apiKey, setApiKey] = useState("");

  const togglePlatformConnection = (platform) => {
    setIsPlatformConnected((prevState) => ({
      ...prevState,
      [platform]: !prevState[platform],
    }));
  };

  const handleApiKeyChange = (e) => {
    setApiKey(e.target.value);
  };

  const saveApiKey = () => {
    alert(`API Key "${apiKey}" saved successfully!`);
  };

  return (
    <div
      className="flex-1 p-4 rounded-md"
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
      }}
    >
      <h2 className="text-4xl font-semibold mb-6">Integrations</h2>

      {/* Platform Connection */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-4" style={{ color: theme.colors.textPrimary }}>
          Ad Platform Connections
        </h3>
        <div className="space-y-4">
          {Object.keys(isPlatformConnected).map((platform) => (
            <div key={platform} className="flex justify-between items-center">
              <span className="text-lg capitalize">{platform}</span>
              <button
                onClick={() => togglePlatformConnection(platform)}
                className="p-3 rounded-lg transition"
                style={{
                  backgroundColor: isPlatformConnected[platform]
                    ? theme.colors.secondary
                    : theme.colors.primary,
                  color: theme.colors.textPrimary,
                }}
              >
                {isPlatformConnected[platform] ? "Disconnect" : "Connect"}
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* API Key Management */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-4" style={{ color: theme.colors.textPrimary }}>
          API Key Management
        </h3>
        <div className="flex items-center space-x-4">
          <input
            type="text"
            value={apiKey}
            onChange={handleApiKeyChange}
            className="w-1/2 p-3 border rounded-md"
            style={{
              backgroundColor: theme.colors.textPrimary,
              color: theme.colors.textSecondary,
              borderColor: theme.colors.borderColor,
            }}
            placeholder="Enter API Key"
          />
          <button
            onClick={saveApiKey}
            className="px-6 py-3 rounded shadow transition"
            style={{
              backgroundColor: theme.colors.primary,
              color: theme.colors.textPrimary,
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
            onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
          >
            Save API Key
          </button>
        </div>
      </div>
    </div>
  );
}
