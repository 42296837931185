import React, { useState, useEffect } from "react";
import ProjectDetails from "./steps/ProjectDetails";
import ChooseTemplate from "./steps/ChooseTemplate";
import UploadMedia from "./steps/UploadMedia";
import ReviewPublish from "./steps/ReviewPublish";
import StepNavigation from "./steps/StepNavigation";
import theme from "../theme";
import introJs from "intro.js";
import "intro.js/introjs.css";

export default function CreateNewAd({ rememberMe }) {
  const [activeStep, setActiveStep] = useState(1);
  const [projectName, setProjectName] = useState("");
  const [adPlatform, setAdPlatform] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [mediaFiles, setMediaFiles] = useState([]);
  const [textFields, setTextFields] = useState([""]);

  const handleNext = () => {
    if (activeStep === 1 && (!projectName || !adPlatform)) {
      alert("Please fill all required fields in Step 1.");
      return;
    } else if (activeStep === 2 && mediaFiles.length === 0) {
      alert("Please upload at least one media file in Step 2.");
      return;
    } else if (activeStep === 3 && selectedTemplate === null) {
      alert("Please select a template in Step 3.");
      return;
    }
    if (activeStep < 4) {
      setActiveStep(activeStep + 1);
    }
  };

  const handlePrev = () => {
    if (activeStep > 1) setActiveStep(activeStep - 1);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setMediaFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const removeFile = (index) => {
    setMediaFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleTextChange = (index, value) => {
    const newTextFields = [...textFields];
    newTextFields[index] = value;
    setTextFields(newTextFields);
  };

  const addTextField = () => {
    setTextFields([...textFields, ""]);
  };

  const removeTextField = (index) => {
    setTextFields(textFields.filter((_, i) => i !== index));
  };

  return (
    <div
      className="min-h-screen relative flex"
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
        fontFamily: theme.typography.fontFamily,
      }}
    >
      <main
        id="main-content"
        className="flex-1 absolute w-4/5 right-0 p-10 shadow-md"
        style={{
          fontFamily: theme.typography.fontFamily,
        }}
      >
        <h1
          className="text-4xl font-bold mb-4"
          style={{
            color: theme.colors.textPrimary,
          }}
        >
          Create Ad
        </h1>

        {/* Step Navigation */}
        <StepNavigation
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          style={{
            color: theme.colors.textSecondary,
          }}
        />

        {/* Step Content */}
        <div
          className="p-8 rounded-md shadow-lg transition-all duration-500"
          style={{
            backgroundColor: theme.colors.cardBackground,
          }}
        >
          {activeStep === 1 && (
            <ProjectDetails
              projectName={projectName}
              setProjectName={setProjectName}
              adPlatform={adPlatform}
              setAdPlatform={setAdPlatform}
              handleNext={handleNext}
              rememberMe={rememberMe}
              style={{
                color: theme.colors.textSecondary,
              }}
            />
          )}

          {activeStep === 2 && (
            <UploadMedia
              mediaFiles={mediaFiles}
              handleFileChange={handleFileChange}
              removeFile={removeFile}
              handleNext={handleNext}
              handlePrev={handlePrev}
              textFields={textFields}
              handleTextChange={handleTextChange}
              addTextField={addTextField}
              removeTextField={removeTextField}
              rememberMe={rememberMe}
              style={{
                color: theme.colors.textSecondary,
              }}
            />
          )}

          {activeStep === 3 && (
            <ChooseTemplate
              adPlatform={adPlatform}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              handleNext={handleNext}
              handlePrev={handlePrev}
              textFields={textFields}
              mediaFiles={mediaFiles}
              rememberMe={rememberMe}
              style={{
                color: theme.colors.textSecondary,
              }}
            />
          )}

          {activeStep === 4 && (
            <ReviewPublish
              projectName={projectName}
              adPlatform={adPlatform}
              selectedTemplate={selectedTemplate}
              mediaFiles={mediaFiles}
              textFields={textFields}
              handlePrev={handlePrev}
              rememberMe={rememberMe}
              style={{
                color: theme.colors.textSecondary,
              }}
            />
          )}
        </div>
      </main>
    </div>
  );
}
