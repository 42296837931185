import theme from "../theme"; // Import theme

export default function AnalyticsDashboard() {
  return (
    <div
      className="min-h-screen relative"
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
      }}
    >
      <div className="absolute w-4/5 right-0 p-10">
        <div className="flex justify-between">
          <div className="w-1/2 m-3">
            <div
              className="p-6 rounded-md mt-8"
              style={{ backgroundColor: theme.colors.cardBackground }}
            >
              <h3
                className="text-xl font-semibold mb-4"
                style={{ color: theme.colors.textPrimary }}
              >
                Summary Metrics
              </h3>
              <div className="flex justify-between">
                <div
                  className="w-1/2 p-4 m-1 rounded-md shadow-sm transition-transform transform hover:scale-105"
                  style={{
                    backgroundColor: theme.colors.background,
                    color: theme.colors.textPrimary,
                  }}
                >
                  Click-through rate
                  <p className="mt-5">14%</p>
                  <p>Since Last Month</p>
                </div>
                <div
                  className="w-1/2 p-4 m-1 rounded-md shadow-sm transition-transform transform hover:scale-105"
                  style={{
                    backgroundColor: theme.colors.background,
                    color: theme.colors.textPrimary,
                  }}
                >
                  Total Views
                  <p className="mt-5">14%</p>
                  <p>Since Last Month</p>
                </div>
              </div>
              <div className="flex justify-between">
                <div
                  className="w-1/2 p-4 m-1 rounded-md shadow-sm transition-transform transform hover:scale-105"
                  style={{
                    backgroundColor: theme.colors.background,
                    color: theme.colors.textPrimary,
                  }}
                >
                  Conversion Rate
                  <p className="mt-5">14%</p>
                  <p>Since Last Month</p>
                </div>
                <div
                  className="w-1/2 p-4 m-1 rounded-md shadow-sm transition-transform transform hover:scale-105"
                  style={{
                    backgroundColor: theme.colors.background,
                    color: theme.colors.textPrimary,
                  }}
                >
                  Engagement Metrics
                  <p className="mt-5">14%</p>
                  <p>Since Last Month</p>
                </div>
              </div>
            </div>
            <div
              className="p-6 rounded-md mt-8"
              style={{ backgroundColor: theme.colors.cardBackground }}
            >
              <h3
                className="text-xl font-semibold mb-4"
                style={{ color: theme.colors.textPrimary }}
              >
                Insights Section
              </h3>
            </div>
          </div>
          <div className="w-1/2 m-3">
            <div
              className="p-6 rounded-md mt-8"
              style={{ backgroundColor: theme.colors.cardBackground }}
            >
              <h3
                className="text-xl font-semibold mb-4"
                style={{ color: theme.colors.textPrimary }}
              >
                Graphs and Charts
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
