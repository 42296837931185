import React, { useState } from "react";
import axios from "axios";
import theme from "../theme"; // Adjust the path to your theme file
import introJs from "intro.js";
import "intro.js/introjs.css";

export default function MarketingContentGenerator({ rememberMe }) {
  const [webContent, setWebContent] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [tone, setTone] = useState("artisanal");
  const [adCopy, setAdCopy] = useState("");
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Get API base URL from environment variables
  const API_BASE_URL ="http://localhost:5000" ||  process.env.REACT_APP_API_BASE_URL ;

  const handleGenerateContent = async () => {
    // Input validation (optional)
    if (!webContent || !companyName || !productDescription) {
      setError("Please fill in all required fields.");
      return;
    }

    setLoading(true);
    setError(null);
    setAdCopy("");
    setTags([]);

    try {
      const response = await axios.post(`${API_BASE_URL}/chatgpt/generate-marketing`, {
        web_content: webContent,
        company_name: companyName,
        product_description: productDescription,
        tone,
      });

      setAdCopy(response.data.ad_copy);
      setTags(response.data.tags);
    } catch (err) {
      console.error("Error generating content:", err.response ? err.response.data : err.message);
      setError(err.response?.data?.error || "An error occurred while generating content.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="min-h-screen relative flex"
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
        fontFamily: theme.typography.fontFamily,
      }}
    >
      <main
        id="main-content"
        className="flex-1 absolute w-4/5 right-0 p-10 shadow-md"
        style={{
          fontFamily: theme.typography.fontFamily,
        }}
      >
        <h1
          className="text-4xl font-bold mb-4"
          style={{
            color: theme.colors.textPrimary,
          }}
        >
          Marketing Content Generator
        </h1>

        {/* Content Wrapper */}
        <div
          className="p-8 rounded-md shadow-lg transition-all duration-500"
          style={{
            backgroundColor: theme.colors.cardBackground,
          }}
        >
          {/* Form Fields */}
          <div className="mb-6">
            <label
              className="block mb-2 font-medium"
              style={{ color: theme.colors.textSecondary }}
            >
              Web Content
            </label>
            <textarea
              value={webContent}
              onChange={(e) => setWebContent(e.target.value)}
              rows={4}
              className="w-full p-2 rounded shadow-sm focus:outline-none"
              style={{
                backgroundColor: theme.colors.inputBackground || theme.colors.cardBackground,
                color: theme.colors.textPrimary,
                border: `1px solid ${theme.colors.borderColor || theme.colors.secondary}`,
              }}
              placeholder="Enter web content..."
            />
          </div>

          <div className="mb-6">
            <label
              className="block mb-2 font-medium"
              style={{ color: theme.colors.textSecondary }}
            >
              Company Name
            </label>
            <input
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className="w-full p-2 rounded shadow-sm focus:outline-none"
              style={{
                backgroundColor: theme.colors.inputBackground || theme.colors.cardBackground,
                color: theme.colors.textPrimary,
                border: `1px solid ${theme.colors.borderColor || theme.colors.secondary}`,
              }}
              placeholder="Enter company name..."
            />
          </div>

          <div className="mb-6">
            <label
              className="block mb-2 font-medium"
              style={{ color: theme.colors.textSecondary }}
            >
              Product Description
            </label>
            <textarea
              value={productDescription}
              onChange={(e) => setProductDescription(e.target.value)}
              rows={3}
              className="w-full p-2 rounded shadow-sm focus:outline-none"
              style={{
                backgroundColor: theme.colors.inputBackground || theme.colors.cardBackground,
                color: theme.colors.textPrimary,
                border: `1px solid ${theme.colors.borderColor || theme.colors.secondary}`,
              }}
              placeholder="Enter product description..."
            />
          </div>

          <div className="mb-6">
            <label
              className="block mb-2 font-medium"
              style={{ color: theme.colors.textSecondary }}
            >
              Tone
            </label>
            <select
              value={tone}
              onChange={(e) => setTone(e.target.value)}
              className="w-full p-2 rounded shadow-sm focus:outline-none"
              style={{
                backgroundColor: theme.colors.inputBackground || theme.colors.cardBackground,
                color: theme.colors.textPrimary,
                border: `1px solid ${theme.colors.borderColor || theme.colors.secondary}`,
              }}
            >
              <option value="artisanal">Artisanal</option>
              <option value="enthusiastic">Enthusiastic</option>
              <option value="luxurious">Luxurious</option>
              {/* Add more tones as needed */}
            </select>
          </div>

          {/* Generate Button */}
          <button
            onClick={handleGenerateContent}
            disabled={loading}
            className="px-6 py-3 rounded shadow transition"
            style={{
              backgroundColor: theme.colors.primary,
              color: theme.colors.textPrimary,
              cursor: loading ? 'not-allowed' : 'pointer',
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
            onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
          >
            {loading ? "Generating..." : "Generate Content"}
          </button>

          {/* Error Message */}
          {error && (
            <p style={{ color: theme.colors.error, marginTop: '1rem' }}>{error}</p>
          )}

          {/* Generated Content */}
          {adCopy && (
            <div className="mt-6">
              <h3
                className="text-2xl font-bold mb-4"
                style={{
                  color: theme.colors.textPrimary,
                }}
              >
                Generated Ad Copy:
              </h3>
              <p
                className="p-4 rounded-md"
                style={{
                  backgroundColor: theme.colors.background,
                  color: theme.colors.textSecondary,
                }}
              >
                {adCopy}
              </p>
            </div>
          )}

          {tags.length > 0 && (
            <div className="mt-6">
              <h3
                className="text-2xl font-bold mb-4"
                style={{
                  color: theme.colors.textPrimary,
                }}
              >
                Generated Tags:
              </h3>
              <ul className="list-disc pl-5">
                {tags.map((tag, index) => (
                  <li key={index} style={{ color: theme.colors.textSecondary }}>
                    {tag}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
