import React from "react";
import theme from "../../theme"; // Import the theme

const StepNavigation = ({ activeStep, setActiveStep }) => {
  const steps = [
    "Project Details",
    "Upload Media",
    "Choose Template",
    "Review & Publish",
  ];

  return (
    <div className="flex justify-between items-center mb-3">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`flex-1 m-1 p-2 text-center py-2 transition-all px-4 rounded-md`}
          style={{
            backgroundColor: activeStep === index + 1 ? theme.colors.primary : theme.colors.backgroundSecondary,
            color: activeStep === index + 1 ? theme.colors.textPrimary : theme.colors.textSecondary,
            boxShadow: activeStep === index + 1 ? "0px 4px 12px rgba(0, 0, 0, 0.2)" : "none",
          }}
        // onClick={() => setActiveStep(index + 1)}
        >
          {step}
        </div>
      ))}
    </div>
  );
};

export default StepNavigation;
