import React, { useState } from "react";
import Profile from "./settings/Profile";
import Subscription from "./settings/Subscription";
import Brand from "./settings/Brand";
import Security from "./settings/Security";
import StepNavigation from "./settings/StepNavigation";
import Notifications from "./settings/Notifications";
import Integrations from "./settings/Integrations";
import theme from "../theme"; // Import theme

export default function SettingsPanel({ rememberMe, setProfileUrl }) {
  const [activeStep, setActiveStep] = useState(1);
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
  });
  const [subscriptionPlan, setSubscriptionPlan] = useState("Basic");
  const [brandData, setBrandData] = useState({
    logo: null,
    primaryColor: "#000000",
    secondaryColor: "#FFFFFF",
  });
  const [securitySettings, setSecuritySettings] = useState({
    oldPassword: "",
    newPassword: "",
  });
  const [integrations, setIntegrations] = useState({
    platforms: [],
    apiKey: "",
  });

  const [notificationSettings, setNotificationSettings] = useState({
    emailNotifications: true,
    inAppNotifications: true,
    alertTypes: [],
  });

  return (
    <div
      className="min-h-screen relative flex"
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
      }}
    >
      <main
        className="flex-1 absolute w-4/5 right-0 p-10 shadow-md"
        style={{ backgroundColor: theme.colors.background }}
      >
        <h1
          className="text-4xl font-bold mb-8"
          style={{ color: theme.colors.textPrimary }}
        >
          Settings
        </h1>

        <StepNavigation activeStep={activeStep} setActiveStep={setActiveStep} />

        <div
          className="rounded-md p-8 shadow-lg transition-all duration-500"
          style={{ backgroundColor: theme.colors.cardBackground }}
        >
          {activeStep === 1 && (
            <Profile
              profileData={profileData}
              setProfileData={setProfileData}
              rememberMe={rememberMe}
              setProfileUrl={setProfileUrl}
            />
          )}
          {activeStep === 2 && (
            <Subscription
              subscriptionPlan={subscriptionPlan}
              setSubscriptionPlan={setSubscriptionPlan}
            />
          )}
          {activeStep === 3 && (
            <Brand
              brandData={brandData}
              setBrandData={setBrandData}
              rememberMe={rememberMe}
            />
          )}
          {activeStep === 4 && (
            <Security
              securitySettings={securitySettings}
              setSecuritySettings={setSecuritySettings}
              rememberMe={rememberMe}
            />
          )}
          {activeStep === 5 && (
            <Integrations
              integrations={integrations}
              setIntegrations={setIntegrations}
            />
          )}
          {activeStep === 6 && (
            <Notifications
              notificationSettings={notificationSettings}
              setNotificationSettings={setNotificationSettings}
            />
          )}
        </div>
      </main>
    </div>
  );
}
