import React, { useState } from "react";
import theme from "../../theme"; // Import theme

export default function Notifications() {
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [inAppNotifications, setInAppNotifications] = useState(true);
  const [selectedAlerts, setSelectedAlerts] = useState({
    adsStatus: true,
    newFeatures: false,
    reminders: true,
  });

  const toggleNotification = (type) => {
    if (type === "email") setEmailNotifications(!emailNotifications);
    if (type === "inApp") setInAppNotifications(!inAppNotifications);
  };

  const toggleAlertType = (alertType) => {
    setSelectedAlerts((prevState) => ({
      ...prevState,
      [alertType]: !prevState[alertType],
    }));
  };

  return (
    <div
      className="flex-1 p-4 rounded-md"
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
      }}
    >
      <h2 className="text-4xl font-semibold mb-6">Notification Preferences</h2>

      {/* Email & In-App Notifications */}
      <div className="mb-6">
        <h3 className="text-lg font-semibold mb-2" style={{ color: theme.colors.textPrimary }}>
          Notification Channels
        </h3>
        <div className="flex justify-between items-center mb-2">
          <span className="text-lg">Email Notifications</span>
          <button
            onClick={() => toggleNotification("email")}
            className="p-3 rounded-lg transition"
            style={{
              backgroundColor: emailNotifications
                ? theme.colors.primary
                : theme.colors.secondary,
              color: theme.colors.textPrimary,
            }}
          >
            {emailNotifications ? "Enabled" : "Disabled"}
          </button>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-lg">In-App Notifications</span>
          <button
            onClick={() => toggleNotification("inApp")}
            className="p-3 rounded-lg transition"
            style={{
              backgroundColor: inAppNotifications
                ? theme.colors.primary
                : theme.colors.secondary,
              color: theme.colors.textPrimary,
            }}
          >
            {inAppNotifications ? "Enabled" : "Disabled"}
          </button>
        </div>
      </div>

      {/* Customize Alert Types */}
      <div>
        <h3 className="text-lg font-semibold mb-2" style={{ color: theme.colors.textPrimary }}>
          Customize Alert Types
        </h3>
        <div className="space-y-4">
          {Object.keys(selectedAlerts).map((alertType) => (
            <div key={alertType} className="flex justify-between items-center">
              <span className="text-lg capitalize">
                {alertType.replace(/([A-Z])/g, " $1")}
              </span>
              <button
                onClick={() => toggleAlertType(alertType)}
                className="p-3 rounded-lg transition"
                style={{
                  backgroundColor: selectedAlerts[alertType]
                    ? theme.colors.primary
                    : theme.colors.secondary,
                  color: theme.colors.textPrimary,
                }}
              >
                {selectedAlerts[alertType] ? "Enabled" : "Disabled"}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
