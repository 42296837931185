import SignIn from "./components/Login";
import SignUp from "./components/SignUp";
import SideBar from "./components/SideBar";
import UserDashboard from "./components/UserDashboard";
import MediaLibrary from "./components/MediaLibrary";
import AnalyticsDashboard from "./components/AnalyticsDashboard";
import TemplateLibrary from "./components/TemplateLibrary";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import CreateNewAd from "./components/CreateNewAd";
import SettingsPanel from "./components/SettingsPanel";
import DynamicJSXRenderer from "./components/JSXRender";
import NotFound from "./components/notFound/NotFound";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import { useState, useEffect } from "react";
import { TemplateContext } from "./context/templateContext";
import MarketingContentGenerator from "./components/MarketingContentGenerator";
function App() {
  const [rememberMe, setRememberMe] = useState(false); // State for "Remember me"
  const [isAuthenticated, setIsAuthenticated] = useState(false); // New state to track authentication status

  const [loading, setLoading] = useState(true); // New loading state
  const [profileUrl, setProfileUrl] = useState("/pro.jpeg")

  useEffect(() => {
    const token = sessionStorage.getItem("token") || localStorage.getItem("token");
    console.log("Token exists: ", !!token); // For debugging
    setIsAuthenticated(!!token);
    setLoading(false); // Stop loading after checking token
  }, []);

  // Show a loader or nothing until authentication status is resolved
  if (loading) {
    return <div>Loading...</div>; // Optional: You can make this a spinner or skeleton UI
  }

  return (
    // <TemplateContext.Provider value={templates}>
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/signin" element={<SignIn setIsAuthenticated={setIsAuthenticated} rememberMe={rememberMe}
            setRememberMe={setRememberMe} />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route element={isAuthenticated ? <SideBar rememberMe={rememberMe} profileUrl={profileUrl} setProfileUrl={setProfileUrl}  /> : <Navigate to="/signin" replace />}>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="/dashboard" element={<UserDashboard />} />
            <Route path="/templates" element={<TemplateLibrary rememberMe={rememberMe} />} />
            <Route path="/media" element={<MediaLibrary />} />
            <Route path="/analytics" element={<AnalyticsDashboard />} />
            <Route path="/projects" element={<CreateNewAd rememberMe={rememberMe} />} />
            <Route path="/settings" element={<SettingsPanel rememberMe={rememberMe} setProfileUrl={setProfileUrl} />} />
            <Route path="/integrations" element={<NotFound />} />
            <Route path="/marketing" element={<MarketingContentGenerator />} />
          </Route>
          <Route path="/publish" element={<DynamicJSXRenderer />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
    // </TemplateContext.Provider>
  );
}

export default App;
