import { useState, useCallback, useEffect } from "react";
import PreviewModal from "./media/PreviewModal";
import { temp, id } from "../util"; // Import your temp and id arrays
import JSXRenderForShow from "./JSXRenderForShow";
import theme from "../theme"; // Import theme

export default function LibraryDiv({
  heading,
  array
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [previewItem, setPreviewItem] = useState(null);

  // Function to get unique categories from the temp map
  const getCategories = () => {
    const categories = new Set();
    if (Array.isArray(array) && array.length > 0 && temp) {
      array.forEach((i) => {
        const item = temp.get(i); // temp.get(i) might return undefined
        if (item && item.category) {
          categories.add(item.category);
        }
      });
    }
    return Array.from(categories); // Convert Set to Array
  };

  // Get the unique categories from the id array
  const categories = getCategories();
  const [selectedCategory, setSelectedCategory] = useState(categories[0]); // Default to the first category

  // Effect to reset page when the category changes
  useEffect(() => {
    setCurrentPage(1); // Reset to first page when category changes
  }, [selectedCategory]);

  // Filter the id array by the selected category
  const filteredIds = array.filter(
    (i) => temp.get(i).category === selectedCategory
  );

  const countPerRow = 1;
  let itemsPerPage = 2;

  if (selectedCategory === "portrait") {
    itemsPerPage = 1;
  }
  // Calculate total pages based on filtered projects
  const totalPages = Math.ceil(filteredIds.length / itemsPerPage);

  // Get the items for the current page based on the selected category
  const currentItems = filteredIds.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Handle preview action, pass the full template data
  const handlePreview = useCallback((i) => {
    const itemData = temp.get(i);
    setPreviewItem(itemData); // Pass the full template data to the modal
  }, []);

  return (
    <>
      <div className="w-1/3">
        <section
          className="p-6 rounded-md mt-8"
          style={{
            backgroundColor: theme.colors.cardBackground,
          }}
        >
          {/* Heading */}
          <div className="flex justify-between items-center mb-6 space-x-2">
            <h3
              className="text-xl font-semibold"
              style={{ color: theme.colors.textPrimary }}
            >
              {heading}
            </h3>

            {/* Category selection */}
            <div>
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                className="p-2 rounded shadow"
                style={{
                  backgroundColor: theme.colors.background,
                  color: theme.colors.textPrimary,
                  borderColor: theme.colors.borderColor,
                }}
              >
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Grid of items, using DynamicJSXRenderer */}
          
          <div className={`grid grid-cols-${countPerRow} object-center gap-2`}>
            {currentItems.map((i, index) => (  // Use index in the key for uniqueness
              <div
                key={`${i}-${index}`} // Ensure each item has a unique key with index
                className="p-4 rounded-md shadow-sm transition-transform transform hover:scale-105"
                style={{
                  backgroundColor: theme.colors.background,
                  color: theme.colors.textPrimary,
                }}
              >
                <JSXRenderForShow
                  jsx={temp.get(i)?.code}  // Assuming each template in temp has a "code" field for JSX
                />
                <p className="font-bold mt-2 text-sm">{temp.get(i)?.title} Title</p> {/* Assuming each item has a title */}
                <p className="text-sm" style={{ color: theme.colors.textSecondary }}>
                  {temp.get(i)?.credits} Credits
                </p> {/* Assuming credits field */}
                <div className="flex justify-center mt-2">
                  <button
                    className="w-1/2 m-1 rounded transition-colors"
                    style={{
                      backgroundColor: theme.colors.primary,
                      color: theme.colors.textPrimary,
                    }}
                    onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
                    onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
                    onClick={() => handlePreview(i)} // Pass the index to handlePreview
                  >
                    Preview
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination controls */}
          <div className="mt-8 flex justify-center items-center space-x-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="px-1 py-1 rounded disabled:opacity-50 transition-colors"
              style={{
                backgroundColor: theme.colors.primary,
                color: theme.colors.textPrimary,
              }}
            >
              Previous
            </button>
            <span style={{ color: theme.colors.textPrimary }}>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="px-1 py-1 rounded disabled:opacity-50 transition-colors"
              style={{
                backgroundColor: theme.colors.primary,
                color: theme.colors.textPrimary,
              }}
            >
              Next
            </button>
          </div>
        </section>

        {/* Preview Modal */}
        {previewItem && (
          <PreviewModal
            item={previewItem} // Pass the selected item data to the modal
            onClose={() => setPreviewItem(null)}
          />
        )}
      </div>
    </>
  );
}
