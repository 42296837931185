import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { FaGoogle } from "react-icons/fa";
import theme from "../theme";
import ApiService from "./ApiService";


export default function SignUp() {
  const service = new ApiService();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailError, setEmailError] = useState(""); // State for email validation error
  const [passwordError, setPasswordError] = useState(""); // State for password validation error
  const [confirmPasswordError, setConfirmPasswordError] = useState(""); // State for confirm password error
  const navigate = useNavigate();

  // Helper functions for validation
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email format
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/; // Regex to check for special characters
    return password.length >= minLength && specialCharRegex.test(password);
  };

  // Handle traditional signup
  const handleSignUp = async (e) => {
    e.preventDefault();

    let isValid = true;

    // Email validation
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Password validation
    if (!validatePassword(password)) {
      setPasswordError(
        "Password must be at least 8 characters long and include a special character."
      );
      isValid = false;
    } else {
      setPasswordError("");
    }

    // Confirm password validation
    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      isValid = false;
    } else {
      setConfirmPasswordError("");
    }

    if (!isValid) return; // Stop the form submission if validation fails

    try {
      const response = await service.post("/auth/signup", {
        name,
        email,
        password,
      });
      alert("Signup successful");
      navigate("/signin");
    } catch (error) {
      console.error("Signup failed:", error);
      alert("Signup failed. Please try again.");
    }
  };

  // Handle Google signup
  const signUpWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const { access_token } = tokenResponse;
        const userInfoResponse = await axios.get(
          "https://www.googleapis.com/oauth2/v2/userinfo",
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );

        const { name, email, picture } = userInfoResponse.data;
        console.log(userInfoResponse.data);

        // Send user data to your backend for signup
        const signupResponse = await service.post(
          "/auth/google-signup",
          {
            name,
            email: email.toLowerCase().trim(),
            picture,
          }
        );

        alert("Signup with Google successful");
        navigate("/dashboard");
      } catch (error) {
        console.error("Google Signup Failed:", error);
        alert("Google Signup Failed. Please try again.");
      }
    },
    onError: (error) => {
      console.error("Google Login Failed:", error);
      alert(`Google Login Failed: ${error.error_description || error.message}`);
    },
  });

  return (
    <div
      style={{
        fontFamily: theme.typography.fontFamily,
        color: theme.colors.textPrimary,
        backgroundColor: theme.colors.background,
      }}
      className="min-h-screen flex items-center justify-center p-4 relative overflow-hidden"
    >
      <div
        className="max-w-lg w-full overflow-hidden transform transition duration-500 z-10 card-shimmer"
        style={{
          backgroundColor: theme.colors.cardBackground,
          borderRadius: theme.borders.borderRadius,
          boxShadow: theme.shadows.card,
          transform: "scale(1)",
          transition: "transform 0.5s",
        }}
        onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
        onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
      >
        <div style={{ padding: theme.spacing(2) }}>
          <div className="w-full flex justify-center">
            <img src="/logo1.png" alt="Company logo" className="w-40" />
          </div>
          <div style={{ marginTop: theme.spacing(2) }}>
            <form onSubmit={handleSignUp}>
              <div style={{ marginBottom: theme.spacing(1) }}>
                <label
                  className="block"
                  style={{ color: theme.colors.textSecondary, marginBottom: theme.spacing(0.5) }}
                >
                  Full Name
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={{
                    width: "100%",
                    padding: theme.spacing(1),
                    border: `1px solid ${theme.colors.borderColor}`,
                    borderRadius: theme.borders.borderRadius,
                    backgroundColor: theme.components.input.background,
                    color: theme.components.input.color,
                  }}
                  required
                />
              </div>
              <div style={{ marginBottom: theme.spacing(1) }}>
                <label
                  className="block"
                  style={{ color: theme.colors.textSecondary, marginBottom: theme.spacing(0.5) }}
                >
                  Email address
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    width: "100%",
                    padding: theme.spacing(1),
                    border: `1px solid ${theme.colors.borderColor}`,
                    borderRadius: theme.borders.borderRadius,
                    backgroundColor: theme.components.input.background,
                    color: theme.components.input.color,
                  }}
                  required
                />
                {emailError && (
                  <p style={{ color: theme.colors.secondary, fontSize: theme.typography.fontSize.small }}>
                    {emailError}
                  </p>
                )}
              </div>
              <div style={{ marginBottom: theme.spacing(1) }}>
                <label
                  className="block"
                  style={{ color: theme.colors.textSecondary, marginBottom: theme.spacing(0.5) }}
                >
                  Password
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    width: "100%",
                    padding: theme.spacing(1),
                    border: `1px solid ${theme.colors.borderColor}`,
                    borderRadius: theme.borders.borderRadius,
                    backgroundColor: theme.components.input.background,
                    color: theme.components.input.color,
                  }}
                  required
                />
                {passwordError && (
                  <p style={{ color: theme.colors.secondary, fontSize: theme.typography.fontSize.small }}>
                    {passwordError}
                  </p>
                )}
              </div>
              <div style={{ marginBottom: theme.spacing(1) }}>
                <label
                  className="block"
                  style={{ color: theme.colors.textSecondary, marginBottom: theme.spacing(0.5) }}
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={{
                    width: "100%",
                    padding: theme.spacing(1),
                    border: `1px solid ${theme.colors.borderColor}`,
                    borderRadius: theme.borders.borderRadius,
                    backgroundColor: theme.components.input.background,
                    color: theme.components.input.color,
                  }}
                  required
                />
                {confirmPasswordError && (
                  <p style={{ color: theme.colors.secondary, fontSize: theme.typography.fontSize.small }}>
                    {confirmPasswordError}
                  </p>
                )}
              </div>
              <button
                type="submit"
                style={{
                  width: "100%",
                  padding: theme.spacing(1),
                  marginTop: theme.spacing(1),
                  borderRadius: theme.borders.borderRadius,
                  border: `1px solid ${theme.colors.primary}`,
                  backgroundColor: theme.colors.secondary,
                  color: theme.colors.textPrimary,
                  transition: "background-color 0.3s",
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
                onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
              >
                Sign up
              </button>
              <div className="flex">
                <button
                  type="button"
                  onClick={() => signUpWithGoogle()}
                  className="mt-4"
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: theme.spacing(1),
                    padding: theme.spacing(1),
                    backgroundColor: theme.colors.primary,
                    color: theme.colors.textPrimary,
                    fontWeight: theme.typography.fontWeight.bold,
                    borderRadius: theme.borders.borderRadius,
                    transition: "background-color 0.3s, transform 0.3s",
                    boxShadow: theme.shadows.card,
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = theme.colors.secondary;
                    e.currentTarget.style.transform = "translateY(-2px)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = theme.colors.primary;
                    e.currentTarget.style.transform = "translateY(0)";
                  }}
                >
                  <FaGoogle style={{ marginRight: theme.spacing(0.5), fontSize: theme.typography.fontSize.large }} />
                  Sign up with Google
                </button>
              </div>
            </form>
          </div>
        </div>
        <div
          style={{
            backgroundColor: theme.colors.cardBackground,
            padding: theme.spacing(1),
            textAlign: "center",
            borderTop: `1px solid ${theme.colors.borderColor}`,
            borderBottomLeftRadius: theme.borders.borderRadius,
            borderBottomRightRadius: theme.borders.borderRadius,
          }}
        >
          <p style={{ fontSize: theme.typography.fontSize.small, color: theme.colors.textSecondary }}>
            By signing up, you agree to our{" "}
            <a href="#" style={{ color: theme.colors.primary, textDecoration: "underline" }}>
              Terms
            </a>{" "}
            and{" "}
            <a href="#" style={{ color: theme.colors.primary, textDecoration: "underline" }}>
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
