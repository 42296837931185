import React, { useState, useEffect } from "react";
import { id, temp } from "../../util/index";
import JSXRenderForMyTemplates from "../JSXRenderForMyTemplates";
import theme from "../../theme"; // Import theme

export default function PreviewModelForMyTemplates({ item, onClose }) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate content loading with a timeout (or replace this with real async logic if needed)
    if (item) {
      setIsLoading(true);
      const timer = setTimeout(() => setIsLoading(false), 300); // Simulating load delay
      return () => clearTimeout(timer); // Cleanup timeout on unmount
    }
  }, [item]);
  if (!item) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div
        className="p-6 rounded-md shadow-md w-1/3 relative"
        style={{
          backgroundColor: theme.colors.cardBackground,
          color: theme.colors.textPrimary,
        }}
      >
        {/* Conditional rendering for loading state */}
        {isLoading ? (
          <div className="flex justify-center items-center h-48">
            {/* Tailwind spinner */}
            <div className="w-10 h-10 border-4 border-t-transparent border-primary rounded-full animate-spin"></div>
          </div>
        ) : (
          <>
            {/* Display the title of the item */}
            <h3 className="text-xl font-bold mb-4">Preview Item: {item?.title}</h3>

            {/* Render the JSX template */}
            <div className="p-4 rounded-md mb-4" style={{ backgroundColor: theme.colors.background }}>
            <JSXRenderForMyTemplates
                textFields={item.textFields}
                mediaFiles={item.mediaFiles}
                jsx={temp.get(item.templateId)?.code}  // Assume temp has a 'code' field for JSX content
              />
            </div>

            {/* Display more details, such as credits */}
            <p className="mb-4" style={{ color: theme.colors.textSecondary }}>
              Credits: {item?.credits}
            </p>
          </>
        )}

        {/* Close button */}
        <button
          className="mt-4 px-4 py-2 rounded-md transition-colors"
          style={{
            backgroundColor: theme.colors.secondary,
            color: theme.colors.textPrimary,
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
          onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}
