import LibraryDiv from "./LibraryDiv";
import LibraryDivForMyTemplate from "./LibraryDivForMyTemplate";
import { id } from "../util";
import { useState, useEffect } from "react";
import theme from "../theme"; // Import the theme
import ApiService from "./ApiService";
export default function TemplateLibrary({ rememberMe }) {
  const service = new ApiService();
  const [templateData, setTemplateData] = useState([]);
  const [myTemplateIdArray, setMyTemplateIdArray] = useState([]); // Start as an empty array
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    const userId = rememberMe ? localStorage.getItem("id") : sessionStorage.getItem("id");

    const fetchTemplateData = async () => {
      try {
        setIsLoading(true); // Start loading before fetching
        // console.log("Fetching templates for user:", userId);

        const response = await service.get(`/project/${userId}`);
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }

        const data = await response.json();
        // console.log("Fetched data:", data);

        // Map the data to template IDs without removing duplicates
        const templateIdArray = data.map((object) => object.templateId);
        // console.log("Unique Template ID Array:", templateIdArray);

        // Replace the with the unique template IDs
        setMyTemplateIdArray(templateIdArray);
        setTemplateData(data);
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setIsLoading(false); // Stop loading after data is fetched
      }
    };

    if (userId) {
      fetchTemplateData();
    }
  }, [rememberMe]); // Runs only when rememberMe changes

  return (
    <div
      className="min-h-screen relative"
      style={{
        backgroundColor: theme.colors.background,
        color: theme.colors.textPrimary,
      }}
    >
      <div className="absolute w-4/5 right-0 p-6">
        <div className="flex space-x-4">
          <LibraryDiv
            heading="Featured Templates"
            countPerRow={1}
            itemsPerPage={2}
            array={id}
          />
          {isLoading ? (
            <div className="flex justify-center items-center h-32 w-1/3">
              <p style={{ color: theme.colors.textSecondary }}>Loading templates...</p>
            </div>
          ) : (
            <LibraryDivForMyTemplate
              heading="My Templates"
              templateDataMap={new Map(templateData.map((t) => [t._id, t]))}
            />
          )}
          <LibraryDiv
            heading="Community Templates"
            itemsPerPage={1}
            array={id}
            countPerRow={1}
          />
        </div>
        <div className="flex justify-center space-x-4">
          <button
            className="mt-8 px-4 py-2 rounded-md transition-colors"
            style={{
              backgroundColor: theme.colors.cardBackground,
              color: theme.colors.textPrimary,
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
            onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.cardBackground)}
          >
            Create Template
          </button>
          <button
            className="mt-8 px-4 py-2 rounded-md transition-colors"
            style={{
              backgroundColor: theme.colors.cardBackground,
              color: theme.colors.textPrimary,
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
            onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.cardBackground)}
          >
            Edit Template
          </button>
        </div>
      </div>
    </div>
  );
}
