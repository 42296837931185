import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaCheckCircle, FaChevronLeft } from "react-icons/fa";
import { toJpeg } from "html-to-image";
import { saveAs } from "file-saver";
import DynamicJSXRenderer from "../JSXRender";
import { temp } from "../../util";
import introJs from "intro.js";
import "intro.js/introjs.css";
import theme from "../../theme";
import ApiService from "../ApiService";
const ReviewPublish = ({
  projectName,
  adPlatform,
  selectedTemplate,
  mediaFiles,
  handlePrev,
  textFields,
  rememberMe,
}) => {
  const service = new ApiService();
  const [isPublishing, setIsPublishing] = useState(false);
  const [publishMessage, setPublishMessage] = useState(null);
  const [user, setUserId] = useState(null); // Initialize as null
  const navigate = useNavigate();

  useEffect(() => {
    const sessionUserId = sessionStorage.getItem("id");
    const localUserId = localStorage.getItem("id");
    if (sessionUserId) {
      setUserId(sessionUserId);
    } else if (localUserId) {
      setUserId(localUserId);
    }

    const intro = introJs();
    intro.setOptions({
      steps: [
        {
          intro: "Welcome to Step 4: Review and Publish.",
        },
        {
          element: "#final-template",
          intro: "This is your final ad template. Review it carefully.",
        },
        {
          element: "#download-button",
          intro: "Click here to download your ad.",
        },
        {
          element: "#publish-button",
          intro: "When you're ready, click here to publish your ad.",
        },
      ],
      showSkipButton: true,
    });
    intro.start();
  }, [rememberMe]);

  const chosenTemplate = selectedTemplate ? temp.get(selectedTemplate) : null;
  const chosenTemplateJSX = chosenTemplate ? chosenTemplate.code : null;

  // Ref to the element to capture
  const templateRef = useRef(null);

  // Download the template as a JPEG image with matching resolution
  const downloadTemplateAsJPG = () => {
    if (!templateRef.current) {
      console.error("Template ref is null, unable to capture.");
      return;
    }
    const element = templateRef.current;
    const { width, height } = element.getBoundingClientRect();
    const scale = 10; // High resolution
    const adjustedWidth = width * scale;
    const adjustedHeight = height * scale;

    toJpeg(element, {
      quality: 1,
      backgroundColor: null,
      width: adjustedWidth,
      height: adjustedHeight,
      style: {
        transform: `scale(${scale})`,
        transformOrigin: "top left",
        width: `${width}px`,
        height: `${height}px`,
      },
    })
      .then((dataUrl) => {
        saveAs(dataUrl, `${projectName}.jpg`);
      })
      .catch((error) => {
        console.error("Error generating JPEG:", error);
      });
  };

  // Convert media file to Base64 string
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePublish = async () => {
    setIsPublishing(true);
    setPublishMessage(null);

    try {
      const authToken = rememberMe ? localStorage.getItem("token") : sessionStorage.getItem("token");
      if (!authToken) {
        setPublishMessage("Authentication token not found. Please log in.");
        setIsPublishing(false);
        return;
      }

      const base64MediaFiles = await Promise.all(mediaFiles.map((file) => convertToBase64(file)));
      const data = {
        projectName,
        adPlatform,
        templateId: selectedTemplate,
        mediaFiles: base64MediaFiles,
        textFields,
        user,
        category: temp.get(selectedTemplate).category,
      };

      const response = await service.post(
        "/project/publish",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setPublishMessage("Project published successfully!");
      navigate("/dashboard");
    } catch (error) {
      console.error("Publish failed:", error.response || error.message);
      setPublishMessage("Publish failed. Please try again.");
    } finally {
      setIsPublishing(false);
    }
  };

  return (
    <div style={{ fontFamily: theme.typography.fontFamily, color: theme.colors.textPrimary }}>
      <h2 className="text-2xl mb-6" style={{ color: theme.colors.textPrimary }}>
        Step 4: Review and Publish
      </h2>

      <div
        id="final-template"
        className="border-2 h-full m-1 p-4 rounded-md mb-5"
        style={{ borderColor: theme.colors.textSecondary }}
      >
        <div className="flex justify-between">
          <h3 className="text-lg font-semibold" style={{ color: theme.colors.textSecondary }}>
            Final Template
          </h3>
          <button
            id="download-button"
            className="px-6 py-2 rounded-md shadow transition"
            style={{
              backgroundColor: theme.colors.primary,
              color: theme.colors.textPrimary,
            }}
            onClick={downloadTemplateAsJPG}
            onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
            onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
          >
            Download
          </button>
        </div>

        <div className="flex min-w-full flex-col items-center">
          <div
            ref={templateRef}
            style={{
              marginTop: "1.5rem",
              padding: 0,
              backgroundColor: "transparent",
            }}
          >
            {chosenTemplateJSX ? (
              <DynamicJSXRenderer textFields={textFields} mediaFiles={mediaFiles} jsx={chosenTemplateJSX} />
            ) : (
              <p style={{ color: theme.colors.textSecondary }}>No template selected.</p>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-6">
        <button
          className="px-6 py-3 rounded shadow transition"
          onClick={handlePrev}
          style={{
            backgroundColor: theme.colors.primary,
            color: theme.colors.textPrimary,
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
          onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
        >
          <FaChevronLeft /> Previous
        </button>

        <div className="flex flex-col items-end space-y-2">
          {isPublishing && <p style={{ color: theme.colors.primary }}>Publishing...</p>}
          {publishMessage && (
            <p
              style={{
                color: publishMessage.includes("successfully")
                  ? theme.colors.primary
                  : theme.colors.secondary,
              }}
            >
              {publishMessage}
            </p>
          )}
          <button
            id="publish-button"
            className="px-6 py-3 rounded shadow transition"
            onClick={handlePublish}
            disabled={isPublishing}
            style={{
              backgroundColor: theme.colors.secondary,
              color: theme.colors.textPrimary,
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
            onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
          >
            <FaCheckCircle /> {isPublishing ? "Publishing..." : "Publish"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewPublish;
