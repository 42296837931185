import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import theme from "../theme";
import ApiService from "./ApiService";
export default function ResetPassword() {
  const service = new ApiService();
  const navigate = useNavigate();
  const location = useLocation(); // Initialize useLocation

  // Retrieve prefilled email from navigation state
  const prefilledEmail = location.state?.email || "";

  const [email, setEmail] = useState(prefilledEmail);
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Helper functions for validation
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email format
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/; // Regex to check for special characters
    return password.length >= minLength && specialCharRegex.test(password);
  };

  // Handle form submission
  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    let isValid = true;
    let errorMessages = [];

    // Email validation
    if (!validateEmail(email)) {
      errorMessages.push("Please enter a valid email address.");
      isValid = false;
    }

    // OTP validation
    if (!otp) {
      errorMessages.push("Please enter the OTP sent to your email.");
      isValid = false;
    }

    // Password validation
    if (!validatePassword(newPassword)) {
      errorMessages.push("Password must be at least 8 characters long and include a special character.");
      isValid = false;
    }

    if (!isValid) {
      setError(errorMessages.join(" "));
      return; // Stop the form submission if validation fails
    }

    try {
      const response = await service.put("/authentication/reset-password", {
        email,
        otp,
        newPassword,
      });
      setMessage(response.data.message);

      // Optionally, redirect to sign-in page after successful reset
      setTimeout(() => {
        navigate("/signin");
      }, 3000);
    } catch (err) {
      console.error("Reset Password Error:", err);
      setError(err.response?.data?.message || "Something went wrong. Please try again.");
    }
  };

  return (
    <div
      style={{
        fontFamily: theme.typography.fontFamily,
        color: theme.colors.textPrimary,
        backgroundColor: theme.colors.background,
      }}
      className="min-h-screen flex items-center justify-center p-4 relative overflow-hidden"
    >
      <div
        className="max-w-lg w-full overflow-hidden transform transition duration-500 z-10 card-shimmer"
        style={{
          backgroundColor: theme.colors.cardBackground,
          borderRadius: theme.borders.borderRadius,
          boxShadow: theme.shadows.card,
          transform: "scale(1)",
          transition: "transform 0.5s",
        }}
        onMouseOver={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
        onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
      >
        <div style={{ padding: theme.spacing(2) }}>
          <div className="w-full flex justify-center">
            <img src="/logo1.png" alt="Company logo" className="w-40" />
          </div>
          <div style={{ marginTop: theme.spacing(2) }}>
            <h2 style={{ textAlign: "center", marginBottom: theme.spacing(2), color: theme.colors.textPrimary }}>
              Reset Password
            </h2>
            <form onSubmit={handleResetPassword}>
              <div style={{ marginBottom: theme.spacing(1) }}>
                <label
                  className="block"
                  style={{ color: theme.colors.textSecondary, marginBottom: theme.spacing(0.5) }}
                >
                  Email Address
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  readOnly={!!prefilledEmail} // Make read-only if prefilled
                  style={{
                    width: "100%",
                    padding: theme.spacing(1),
                    border: `1px solid ${theme.colors.borderColor}`,
                    borderRadius: theme.borders.borderRadius,
                    backgroundColor: theme.components.input.background,
                    color: theme.components.input.color,
                  }}
                  required
                  placeholder="Enter your registered email"
                />
              </div>
              <div style={{ marginBottom: theme.spacing(1) }}>
                <label
                  className="block"
                  style={{ color: theme.colors.textSecondary, marginBottom: theme.spacing(0.5) }}
                >
                  OTP
                </label>
                <input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  style={{
                    width: "100%",
                    padding: theme.spacing(1),
                    border: `1px solid ${theme.colors.borderColor}`,
                    borderRadius: theme.borders.borderRadius,
                    backgroundColor: theme.components.input.background,
                    color: theme.components.input.color,
                  }}
                  required
                  placeholder="Enter the OTP sent to your email"
                />
              </div>
              <div style={{ marginBottom: theme.spacing(1) }}>
                <label
                  className="block"
                  style={{ color: theme.colors.textSecondary, marginBottom: theme.spacing(0.5) }}
                >
                  New Password
                </label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  style={{
                    width: "100%",
                    padding: theme.spacing(1),
                    border: `1px solid ${theme.colors.borderColor}`,
                    borderRadius: theme.borders.borderRadius,
                    backgroundColor: theme.components.input.background,
                    color: theme.components.input.color,
                  }}
                  required
                  placeholder="Enter your new password"
                />
              </div>
              {error && (
                <p
                  style={{
                    color: theme.colors.secondary,
                    fontSize: theme.typography.fontSize.small,
                    marginBottom: theme.spacing(1),
                  }}
                >
                  {error}
                </p>
              )}
              {message && (
                <p
                  style={{
                    color: theme.colors.success,
                    fontSize: theme.typography.fontSize.small,
                    marginBottom: theme.spacing(1),
                  }}
                >
                  {message}
                </p>
              )}
              <button
                type="submit"
                style={{
                  width: "100%",
                  padding: theme.spacing(1),
                  marginTop: theme.spacing(1),
                  borderRadius: theme.borders.borderRadius,
                  border: `1px solid ${theme.colors.primary}`,
                  backgroundColor: theme.colors.secondary,
                  color: theme.colors.textPrimary,
                  transition: "background-color 0.3s",
                }}
                onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
                onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
              >
                Reset Password
              </button>
            </form>
            <p style={{ marginTop: theme.spacing(2), textAlign: "center" }}>
              Remembered your password?{" "}
              <Link to="/signin" style={{ color: theme.colors.primary, textDecoration: "underline" }}>
                Sign In
              </Link>
            </p>
          </div>
        </div>
        <div
          style={{
            backgroundColor: theme.colors.cardBackground,
            padding: theme.spacing(1),
            textAlign: "center",
            borderTop: `1px solid ${theme.colors.borderColor}`,
            borderBottomLeftRadius: theme.borders.borderRadius,
            borderBottomRightRadius: theme.borders.borderRadius,
          }}
        >
          <p style={{ fontSize: theme.typography.fontSize.small, color: theme.colors.textSecondary }}>
            By resetting your password, you agree to our{" "}
            <a href="#" style={{ color: theme.colors.primary, textDecoration: "underline" }}>
              Terms
            </a>{" "}
            and{" "}
            <a href="#" style={{ color: theme.colors.primary, textDecoration: "underline" }}>
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
