import React, { useState } from "react";
import axios from "axios";
import theme from "../../theme"; // Import theme
import ApiService from "../ApiService";
export default function Profile({ profileData, setProfileData, rememberMe, setProfileUrl }) {

  const service = new ApiService();

  const [fullName, setFullName] = useState(profileData.name);
  const [email, setEmail] = useState(profileData.email);
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState(""); // State for email validation error
  const [selectedFile, setSelectedFile] = useState(null);
  const [profileImage, setProfileImage] = useState(profileData.logo || ""); // State to store Base64 image

  // Regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Function to validate email
  const validateEmail = (email) => {
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
      return false;
    }
    setEmailError("");
    return true;
  };

  // Function to handle profile update
  const handleUpdate = async () => {
    if (!validateEmail(email)) {
      return;
    }

    setIsLoading(true);
    try {
      const id = rememberMe ? localStorage.getItem("id") : sessionStorage.getItem("id");

      const response = await service.put(`/auth/update/${id}`, {
        name: fullName,
        email: email,
      });

      const updatedUser = {
        name: response.data.user.name,
        email: response.data.user.email,
      };

      setProfileData(updatedUser);

      if (rememberMe) {
        localStorage.setItem("userName", updatedUser.name);
        localStorage.setItem("userEmail", updatedUser.email);
      } else {
        sessionStorage.setItem("userName", updatedUser.name);
        sessionStorage.setItem("userEmail", updatedUser.email);
      }

      alert("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Error updating profile. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("Uploaded file: ", file);
    setSelectedFile(file);

    if (!file) {
      alert("No file selected");
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = async () => {
      const base64String = reader.result.split(",")[1]; // Remove the data:image/*;base64, part
      console.log("Base64 String:", base64String);
      setProfileImage(reader.result); // Store the full Base64 string for display
      setProfileUrl(reader.result);

      try {
        const id = rememberMe
          ? localStorage.getItem("id")
          : sessionStorage.getItem("id");

        const response = await axios.put(
          `http://localhost:5000/auth/addlogo/${id}`,
          { logo: base64String }, // Send the Base64 string in the request body
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        alert("Logo uploaded successfully!");
      } catch (error) {
        console.error(
          "Error uploading logo:",
          error.response?.data || error.message
        );
        alert("Error uploading logo. Please try again.");
      }
    };

    reader.onerror = () => {
      console.error("Error reading file");
      alert("Error reading file. Please try again.");
    };
  };

  return (
    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-6 rounded-lg shadow-lg">
      {/* Left Side: Personal Info */}
      <div
        className="flex-1 p-6 rounded-md"
        style={{
          backgroundColor: theme.colors.background,
          color: theme.colors.textPrimary,
        }}
      >
        <h2 className="text-2xl font-bold mb-6">Change Personal Information</h2>

        <div className="mb-4">
          <label className="block text-sm mb-2">Full Name</label>
          <input
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            className="w-full p-2 rounded"
            style={{
              backgroundColor: theme.colors.textPrimary,
              color: theme.colors.textSecondary,
            }}
            placeholder="Enter your full name"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm mb-2">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              validateEmail(e.target.value);
            }}
            className="w-full p-2 rounded"
            style={{
              backgroundColor: theme.colors.textPrimary,
              color: theme.colors.textSecondary,
            }}
            placeholder="Enter your email"
          />
          {emailError && <p style={{ color: theme.colors.secondary }} className="text-xs">{emailError}</p>}
        </div>

        <button
          onClick={handleUpdate}
          className="px-6 py-3 rounded shadow transition"
          style={{
            backgroundColor: theme.colors.primary,
            color: theme.colors.textPrimary,
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
          onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
          disabled={isLoading || emailError}
        >
          {isLoading ? "Updating..." : "Update"}
        </button>
      </div>

      {/* Right Side: Profile Photo Upload */}
      <div
        className="flex-1 flex flex-col items-center justify-center p-6 rounded-md"
        style={{
          backgroundColor: theme.colors.background,
          color: theme.colors.textPrimary,
        }}
      >
        <label className="mb-4">Upload profile photo</label>
        <input
          type="file"
          onChange={handleFileChange}
          className="hidden"
          id="profile-photo"
        />
        <label
          htmlFor="profile-photo"
          className="px-6 py-3 rounded shadow transition"
          style={{
            backgroundColor: theme.colors.primary,
            color: theme.colors.textPrimary,
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = theme.colors.secondary)}
          onMouseOut={(e) => (e.target.style.backgroundColor = theme.colors.primary)}
        >
          Upload
        </label>
      </div>
    </div>
  );
}
